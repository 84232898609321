// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Components
import styles from './wizardStepper.module.scss'
import WizardStep from './wizardStep'
import WizardLabel from './wizardLabel'

class WizardStepper extends Component {
  render () {
    const { steps, currentStep, className } = this.props
    return (
      <div
        className={classNames(styles.wizard_stepper, {
          [className]: !!className
        })}
      >
        <div className={styles.stepper_steps}>
          {steps.map((step, index) => (
            <WizardStep
              key={`wizard-step-${step.id}`}
              {...step}
              isActive={currentStep === index}
              isCompleted={currentStep > index}
              sequence={index + 1}
            />
          ))}
        </div>
        <div className={styles.stepper_labels}>
          {steps.map((step, index) => (
            <WizardLabel
              key={`wizard-step-${step.id}`}
              {...step}
              isActive={currentStep === index}
              isCompleted={currentStep > index}
            />
          ))}
        </div>
      </div>
    )
  }
}

WizardStepper.defaultProps = {
  className: ''
}

WizardStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  currentStep: PropTypes.number.isRequired,
  className: PropTypes.string
}

export default WizardStepper
