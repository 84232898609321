// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'

// Components
import Button from 'shared/button'
import Input from 'shared/input'
import Select from 'shared/select'
import Icon from 'shared/icon'
import {
  MONTHLY_AVERAGE_AMOUNT_OPTIONS,
  INVESTMENT_SOURCE_OPTIONS,
  BANK_OPTIONS,
  BANK_ACCOUNT_TYPE_OPTIONS,
  BANK_ACCOUNT_TYPE_CLABE,
  BANK_ACCOUNT_TYPE_DEBIT_CARD
} from 'shared/catalogs'
import {
  validateInput,
  validateNullField,
  validateNullSelect,
  genericValidationMessage,
  minLength,
  numbersOnly,
  validateCreditCard,
  validateMinValue
} from 'utils/formValidations'
import { cleanObject, formatMoney, unformatMoney } from 'utils'
import { getInvestmentPlanFormRequest, getUpdateProgressRequest } from './utils'
import API from 'api'
import { notifyError } from 'utils/notifications'
import { hasOnlyNumbers } from 'utils/stringValidations'

import { cancelEvent } from 'utils/events'

import styles from './investmentPlanForm.module.scss'
import OptionSwitch from 'shared/optionSwitch'

class InvestmentPlan extends Component {
  state = {
    selectedBankAccountType: ''
  }

  clabeRef = React.createRef()
  // Sets the value in the formik form
  handleChange = (value, id) => {
    const { setFieldTouched, setFieldValue } = this.props
    setFieldTouched(id)
    setFieldValue(id, value)
  }

  handleBankChange = (value, id) => {
    const {
      defaultValues: { clabe, bankName }
    } = this.props
    // Reset the clabe only when it is saved and the bank has changed
    const shouldResetClabe = clabe && clabe.includes('*') && value !== bankName

    if (shouldResetClabe) {
      this.clabeRef.current.handleReset()
    }
    this.handleChange(value, id)
  }

  handleBankAccountTypeChange = (value, id) => {
    this.setState({ selectedBankAccountType: value })
    this.handleChange(value, id)
  }

  getError = field => {
    const { errors, touched, submitCount } = this.props
    // If submitted or field touched, return field error
    return submitCount > 0 || touched[field] ? errors[field] : ''
  }

  getFullName = () => {
    const { defaultValues } = this.props
    const { firstName, secondName, lastName, secondLastName } = defaultValues
    return `${firstName} ${
      secondName ? `${secondName}` : ''
    } ${lastName} ${secondLastName}`
  }

  render () {
    const { handleSubmit, defaultValues, isSubmitting } = this.props
    const { selectedBankAccountType } = this.state
    const isClabe = selectedBankAccountType === BANK_ACCOUNT_TYPE_CLABE
    return (
      <form onSubmit={isSubmitting ? cancelEvent : handleSubmit}>
        <p className='start-xs margin-bottom-small'>
          Para comenzar tu plan como inversionista, es necesario que ingreses el
          monto promedio mensual que mantendrás en tu cuenta. No te preocupes,
          este dato es informativo y no te obliga a mantener la opción que
          selecciones.
        </p>
        <Select
          className='margin-bottom-small'
          label='Saldo mensual *'
          onChange={this.handleChange}
          id='monthlyAverageAmount'
          invalidMessage={this.getError('monthlyAverageAmount')}
          defaultValue={defaultValues.monthlyAverageAmount}
          placeholder='Selecciona'
          options={MONTHLY_AVERAGE_AMOUNT_OPTIONS}
        />
        <Select
          className='margin-bottom-small'
          label='Origen del dinero *'
          onChange={this.handleChange}
          id='investmentSource'
          invalidMessage={this.getError('investmentSource')}
          defaultValue={defaultValues.investmentSource}
          placeholder='Selecciona'
          options={INVESTMENT_SOURCE_OPTIONS}
          tooltip='Selecciona cuál es la procedencia de los recursos que deseas invertir.'
        />
        <p className='start-xs margin-bottom-small'>
          Los siguientes datos fueron extraídos de tu cálculo de inversión
          inicial. Si éstos no son los montos correctos, puedes editarlos a
          continuación.
        </p>

        <Input
          className='margin-bottom-small'
          label='Tu aportación inicial *'
          onChange={this.handleChange}
          id='initialInvestment'
          invalidMessage={this.getError('initialInvestment')}
          defaultValue={defaultValues.initialInvestment}
          validate={hasOnlyNumbers}
          formatter={{
            blur: formatMoney,
            focus: unformatMoney
          }}
          tooltip='Ingresa aquí la cantidad con la que quieras comenzar tu inversión. Tu Inversión Inicial no puede ser menor a $10,000.'
        />
        <Input
          className='margin-bottom-small'
          label='Tu aportación mensual *'
          onChange={this.handleChange}
          id='monthlyInvestment'
          invalidMessage={this.getError('monthlyInvestment')}
          defaultValue={defaultValues.monthlyInvestment}
          validate={hasOnlyNumbers}
          formatter={{
            blur: formatMoney,
            focus: unformatMoney
          }}
        />

        <h4 className='start-xs text-uppercase color-sky-blue margin-bottom-small'>
          Cuenta bancaria
        </h4>
        <p className='start-xs margin-bottom-small'>
          Para poder realizar el fondeo inicial de tu cuenta necesitaremos que
          nos proporciones tu CLABE o los 16 dígitos de tu tarjeta de débito, no
          haremos ningún cargo hasta que nos des autorización al firmar tus
          contratos.
        </p>

        <OptionSwitch
          className='margin-bottom-small'
          label={
            <p className='color-dark-blue start-xs text-uppercase text-smallest'>
              Tipo de cuenta *
            </p>
          }
          options={BANK_ACCOUNT_TYPE_OPTIONS}
          onChange={this.handleBankAccountTypeChange}
          id='bankAccountType'
          optionClassName='color-dark-blue full-width'
          wrap={false}
          invalidMessage={this.getError('bankAccountType')}
          defaultValue={BANK_ACCOUNT_TYPE_CLABE}
        />
        {isClabe ? (
          <Input
            ref={this.clabeRef}
            className='margin-bottom-small'
            label='CLABE *'
            onChange={this.handleChange}
            id='clabe'
            invalidMessage={this.getError('clabe')}
            defaultValue={defaultValues.clabe}
            maxLength='18'
            placeholder='18 dígitos'
            canReset
            key='clabe'
          />
        ) : (
          <Input
            ref={this.clabeRef}
            className='margin-bottom-small'
            label='Tarjeta de debito *'
            onChange={this.handleChange}
            id='debitCard'
            invalidMessage={this.getError('debitCard')}
            defaultValue={defaultValues.debitCard}
            maxLength='16'
            placeholder='16 dígitos'
            canReset
            key='debitCard'
          />
        )}

        <Select
          className='margin-bottom-small'
          label='Banco *'
          onChange={this.handleBankChange}
          id='bankName'
          invalidMessage={this.getError('bankName')}
          defaultValue={defaultValues.bankName}
          placeholder='Selecciona'
          options={BANK_OPTIONS}
        />

        <Input
          className='margin-bottom-small'
          label='Titular de la cuenta *'
          id='account_holder'
          disabled
          defaultValue={this.getFullName()}
          tooltip='La cuenta debe estar a nombre del titular de la inversión.'
        />

        <div className={`text-small margin-bottom-small ${styles.disclaimer}`}>
          <Icon className={styles.icon} size='small' icon='lock' />
          <p className='start-xs'>
            Tus datos están protegidos por la Ley Federal de Protección de Datos
            Personales en Posesión de Particulares
          </p>
        </div>
        <Button isLoading={isSubmitting} theme='primary' type='submit'>
          Continuar
        </Button>
      </form>
    )
  }
}

InvestmentPlan.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  goNext: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValues: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

// validate function called before handle submit
const validate = (values, { defaultValues }) => {
  const shouldNotValidateClabe =
    values.bankAccountType !== BANK_ACCOUNT_TYPE_CLABE ||
    (values.clabe &&
      values.clabe.includes('*') &&
      values.clabe === defaultValues.clabe)

  const shouldNotValidateDeditCard =
    values.bankAccountType !== BANK_ACCOUNT_TYPE_DEBIT_CARD

  // Create an errors object with built with the form form validations
  const errors = {
    bankAccountType: validateNullSelect(values.bankAccountType),
    monthlyInvestment: validateInput(values.monthlyInvestment, [
      validateNullField(
        genericValidationMessage('tu aportación mensual mensual')
      )
    ]),
    initialInvestment: validateInput(values.initialInvestment, [
      validateNullField(genericValidationMessage('tu inversión inicial')),
      validateMinValue(
        10000,
        'Tu Inversión Inicial no puede ser menor a $10,000.'
      )
    ]),
    investmentSource: validateNullSelect(values.investmentSource),
    monthlyAverageAmount: validateNullSelect(values.monthlyAverageAmount),
    bankName: validateNullSelect(values.bankName),
    clabe: shouldNotValidateClabe
      ? ''
      : validateInput(values.clabe, [
        validateNullField(genericValidationMessage('tu CLABE interbancaria')),
        numbersOnly,
        minLength(18, 'Tu CLABE debe estar formada por 18 dígitos.')
      ]),
    debitCard: shouldNotValidateDeditCard
      ? ''
      : validateInput(values.debitCard, [
        validateNullField(
          genericValidationMessage('los números de tu tarjeta de debito')
        ),
        numbersOnly,
        minLength(
          16,
          'Tu tarjeta de debito debe estar formada por 16 dígitos.'
        ),
        validateCreditCard('Ingresa un número de tarjeta válido.')
      ])
  }

  // Remove the empty keys and return the errors object
  return cleanObject(errors)
}

const handleSubmit = async (values, { props, setSubmitting }) => {
  setSubmitting(true)
  try {
    await API.Opportunities.Update(getInvestmentPlanFormRequest(values, props))

    // update form progress
    await API.Opportunities.Update(getUpdateProgressRequest())

    props.onComplete(values)
  } catch (e) {
    console.error(e)
    notifyError('Ocurrió un error, intenta de nuevo más tarde.')
    setSubmitting(false)
  }
}

export default withFormik({
  validate,
  handleSubmit,
  // Avoid taking props as values
  mapPropsToValues: () => {},
  validateOnBlur: false
})(InvestmentPlan)
