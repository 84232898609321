// Libraries
import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

// Components
import Input from 'shared/input'
import Select from 'shared/select'
import OptionSwitch from 'shared/optionSwitch'
import DateSelector from 'shared/dateSelector'
import { RELATIONSHIP_OPTIONS, GENDER_OPTIONS } from 'shared/catalogs'
class BeneficiaryDataForm extends PureComponent {
  constructor (props) {
    super(props)
    this.handleChange(props.id, 'id')
  }
  componentDidMount () {
    const { id } = this.props
    // Write the beneficiary id in formiik
    this.handleChange(id, 'id')
  }
  // Sets the value in the formik form
  handleChange = (value, id) => {
    const { onChange, beneficiaryNumber } = this.props
    onChange(beneficiaryNumber, value, id)
  }

  getError = field => {
    const { errors, touched, submitCount } = this.props
    // If submitted or field touched, return field error
    return submitCount > 0 || touched[field] ? errors[field] : ''
  }

  render () {
    const { beneficiaryNumber, defaultValues } = this.props
    return (
      <Fragment>
        <h4 className='start-xs text-uppercase color-sky-blue margin-bottom-small'>
          Beneficiario
          {' '}
          {beneficiaryNumber}
        </h4>
        <Input
          className='margin-bottom-small'
          label='Nombre *'
          onChange={this.handleChange}
          id={`firstName-${beneficiaryNumber}`}
          name='firstName'
          invalidMessage={this.getError('firstName')}
          defaultValue={defaultValues.firstName}
        />
        <Input
          className='margin-bottom-small'
          label='Segundo nombre'
          onChange={this.handleChange}
          id={`secondName-${beneficiaryNumber}`}
          name='secondName'
          invalidMessage={this.getError('secondName')}
          defaultValue={defaultValues.secondName}
        />
        <Input
          className='margin-bottom-small'
          label='Apellido paterno *'
          onChange={this.handleChange}
          id={`lastName-${beneficiaryNumber}`}
          name='lastName'
          invalidMessage={this.getError('lastName')}
          defaultValue={defaultValues.lastName}
        />
        <Input
          className='margin-bottom-small'
          label='Apellido materno *'
          onChange={this.handleChange}
          id={`secondLastName-${beneficiaryNumber}`}
          name='secondLastName'
          invalidMessage={this.getError('secondLastName')}
          defaultValue={defaultValues.secondLastName}
        />
        <OptionSwitch
          className='margin-bottom-small'
          label={
            <p className='color-dark-blue start-xs text-uppercase text-smallest'>
              Género *
            </p>
          }
          onChange={this.handleChange}
          name='gender'
          id={`gender-${beneficiaryNumber}`}
          options={GENDER_OPTIONS}
          optionClassName='color-dark-blue full-width'
          wrap={false}
          invalidMessage={this.getError('gender')}
          defaultValue={defaultValues.gender}
        />
        <DateSelector
          className='margin-bottom-small'
          label='Fecha de nacimiento *'
          onChange={this.handleChange}
          id={`birthdate-${beneficiaryNumber}`}
          name='birthdate'
          invalidMessage={this.getError('birthdate')}
          defaultValue={defaultValues.birthdate}
        />
        <Select
          className='margin-bottom'
          label='Parentesco *'
          onChange={this.handleChange}
          options={RELATIONSHIP_OPTIONS}
          id={`relationship-${beneficiaryNumber}`}
          name='relationship'
          invalidMessage={this.getError('relationship')}
          defaultValue={defaultValues.relationship}
          placeholder='selecciona'
        />
      </Fragment>
    )
  }
}

BeneficiaryDataForm.propTypes = {
  id: PropTypes.string.isRequired,
  beneficiaryNumber: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  defaultValues: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  errors: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
}

export default BeneficiaryDataForm
