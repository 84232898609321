// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, Switch } from 'react-router-dom'

const RoutesList = params => {
  let { routes, defaultRoute, id } = params
  return (
    <Switch>
      {routes.map(route => (
        <Route key={`${id}-${route.id}`} {...route} />
      ))}
      <Route path='/correccion-documentacion' />
      {defaultRoute && <Redirect from='/*' to={defaultRoute} />}
    </Switch>
  )
}

RoutesList.defaultProps = {
  defaultRoute: ''
}

RoutesList.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
      exact: PropTypes.bool,
      render: PropTypes.func
    })
  ).isRequired,
  defaultRoute: PropTypes.string,
  id: PropTypes.string.isRequired
}

export default RoutesList
