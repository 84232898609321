import _uniqBy from 'lodash.uniqby'

const SEPOMEX_KEYS = {
  aguascalientes: 'AGUASCALIENTES',
  'baja california': 'BAJA CALIFORNIA',
  'baja california sur': 'BAJA CALIFORNIA SUR',
  campeche: 'CAMPECHE',
  'coahuila de zaragoza': 'COAHUILA',
  colima: 'COLIMA',
  chiapas: 'CHIAPAS',
  chihuahua: 'CHIHUAHUA',
  'ciudad de méxico': 'CIUDAD DE MÉXICO',
  durango: 'DURANGO',
  guanajuato: 'GUANAJUATO',
  guerrero: 'GUERRERO',
  hidalgo: 'HIDALGO',
  jalisco: 'JALISCO',
  méxico: 'ESTADO DE MEXICO',
  'michoacán de ocampo': 'MICHOACAN',
  morelos: 'MORELOS',
  nayarit: 'NAYARIT',
  'nuevo león': 'NUEVO LEON',
  oaxaca: 'OAXACA',
  puebla: 'PUEBLA',
  querétaro: 'QUERETARO',
  'quintana roo': 'QUINTANA ROO',
  'san luis potosí': 'SAN LUIS POTOSI',
  sinaloa: 'SINALOA',
  sonora: 'SONORA',
  tabasco: 'TABASCO',
  tamaulipas: 'TAMAULIPAS',
  tlaxcala: 'TLAXCALA',
  'veracruz de ignacio de la llave': 'VERACRUZ',
  yucatán: 'YUCATAN',
  zacatecas: 'ZACATECAS'
}

/**
 * Return the state value according to a sepomex key
 * @param {string} key the sepomex key
 */

const getStateBySepomexKey = key => {
  const search = key.toLowerCase()
  const stateKey = SEPOMEX_KEYS[search]
  if (!stateKey) {
    throw new Error(`No state key found for state ${key}`)
  }
  return stateKey
}

/**
 * Build collection for sepomex response
 * @param {array} options collection returned by the sepomex request
 * @param {string} label label key where the label will be extracted
 * @param {function} [formatter] formatter function
 */
const buildSepomexCollection = (options, label, formatter) => {
  const collection = options.map(option => ({
    value: formatter
      ? formatter(option[label]).toUpperCase()
      : option[label].toUpperCase(),
    label: option[label]
  }))
  return _uniqBy(collection, 'value')
}

/** Create an object of options from postal code response
 * If current postal code doesn't have city param so city value will have
 * municipality value to send to the API no empty strings.
 * @param {array} options collection returned by the sepomex request
 */
export const parsePostalCodeResponse = options => {
  const hasCity = options
    // map every option whose city key is not empty
    .map(option => option.city_key)
    .every(option => option !== '')

  // If there no city, use the municipality
  const cityLabel = hasCity ? 'city' : 'municipality'

  return {
    cities: buildSepomexCollection(options, cityLabel),
    municipalities: buildSepomexCollection(options, 'municipality'),
    neighborhoods: buildSepomexCollection(options, 'settlement'),
    states: buildSepomexCollection(options, 'state', getStateBySepomexKey)
  }
}
