import { toast } from 'react-toastify'

export const NOTIFICATION_ERROR = 'error'
export const NOTIFICATION_WARN = 'warn'
export const NOTIFICATION_INFO = 'info'
export const NOTIFICATION_SUCCESS = 'success'

const NOTIFICATION_METHODS = {
  [NOTIFICATION_ERROR]: message => toast.error(message),
  [NOTIFICATION_WARN]: message => toast.warn(message),
  [NOTIFICATION_INFO]: message => toast.info(message),
  [NOTIFICATION_SUCCESS]: message => toast.success(message)
}

const makeNotification = (message, type) =>
  // returns notification id
  NOTIFICATION_METHODS[type]
    ? NOTIFICATION_METHODS[type](message)
    : NOTIFICATION_METHODS[NOTIFICATION_INFO](message)

export const notifyError = message =>
  makeNotification(message, NOTIFICATION_ERROR)

export const notifyWarn = message =>
  makeNotification(message, NOTIFICATION_WARN)

export const notifyInfo = message =>
  makeNotification(message, NOTIFICATION_INFO)

export const notifySuccess = message =>
  makeNotification(message, NOTIFICATION_SUCCESS)
