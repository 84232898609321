// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import styles from './quantitySteper.module.scss'
import QuantitySteperButton from './quantitySteperButton'
import { unformatMoney, formatMoney } from 'utils'
import Icon from 'shared/icon'

/**
 * @typedef {object} State
 * @property {number | string} value
 *
 * @typedef {object} Props
 * @property {number | string} [defaultValue]
 * @property {number | string} [maxValue]
 * @property {number | string} [minValue]
 * @property {function} [onChange]
 * @property {number | string} [step]
 * @property {string} [label]
 * @property {number | string} [maxLength]
 * @property {string} [id]
 */

/**
 * Numeric input with buttons to add and reduce the input amount
 * @extends {React.Component<Props, State>}
 */
class QuantitySteper extends Component {
  constructor (props) {
    super(props)
    this.quantityInput = React.createRef()
    this.state = {
      value: formatMoney(props.defaultValue)
    }
  }

  onChange = e => {
    this.setState({ value: Number(e.target.value) })
  }

  onAddButton = () => {
    const { value } = this.state
    const { step } = this.props
    const newValue = unformatMoney(value) + Number(step)
    if (this.validateMaxValue(newValue)) {
      this.updateValue(newValue)
    }
  }

  onReduceButton = () => {
    const { value } = this.state
    const { step } = this.props
    const newValue = unformatMoney(value) - Number(step)
    if (this.validateMinValue(newValue)) {
      this.updateValue(newValue)
    }
  }

  updateValue = value => {
    this.setState({ value: formatMoney(value) }, this.notifyChange)
  }

  notifyChange = () => {
    const { value } = this.state
    const { onChange } = this.props
    onChange(unformatMoney(value))
  }

  onKeyPress = ({ key }) => {
    if (key === 'Enter') {
      this.quantityInput.current.blur()
    }
  }

  onBlur = () => {
    const { value } = this.state
    const finalValue = this.getFinalValue(value)

    // Set the new value and call the callback
    this.setState({ value: formatMoney(finalValue) }, this.notifyChange)
  }

  onFocus = () => {
    this.setState(({ value }) => ({ value: unformatMoney(value) }))
  }

  /**
   * Determines whe new value according with the min and the step props
   * @returns {number} the new value
   */
  getFinalValue = value => {
    const { minValue, step } = this.props
    if (!this.validateMinValue(value) && !isNaN(Number(minValue))) {
      return Number(minValue)
    }
    // Rounded value to determine the new value according to the step
    return Number(step) * Math.round(value / Number(step))
  }

  /**
   * Validates that the provided value is more or equal to the min value prop
   * @param {string | number} value
   * @returns {boolean}
   */
  validateMinValue = value => {
    const { minValue } = this.props
    return (
      (isNaN(Number(minValue)) || value >= Number(minValue)) &&
      !isNaN(Number(value))
    )
  }

  /**
   * Validates that the provided value is less or equal to the max value prop
   * @param {string | number} value
   * @returns {boolean}
   */
  validateMaxValue = value => {
    const { maxValue } = this.props
    return (
      (isNaN(Number(maxValue)) || value <= Number(maxValue)) &&
      !isNaN(Number(value))
    )
  }

  render () {
    const { value } = this.state
    const { label, maxLength, id } = this.props

    const idProps = id
      ? {
        id,
        'data-testid': id
      }
      : {}
    return (
      <div className={styles.quantity_steper}>
        <p className='color-dark-blue text-bold'>{label}</p>
        <div className={styles.group}>
          <QuantitySteperButton
            id={id ? `${id}-minus` : ''}
            onClick={this.onReduceButton}
          >
            <Icon icon='minus' />
          </QuantitySteperButton>
          <input
            {...idProps}
            ref={this.quantityInput}
            onBlur={this.onBlur}
            onFocus={this.onFocus}
            onChange={this.onChange}
            onKeyPress={this.onKeyPress}
            value={value}
            maxLength={Number(maxLength)}
          />
          <QuantitySteperButton
            id={id ? `${id}-plus` : ''}
            onClick={this.onAddButton}
          >
            <Icon icon='plus' />
          </QuantitySteperButton>
        </div>
      </div>
    )
  }
}

QuantitySteper.defaultProps = {
  defaultValue: 0,
  maxValue: undefined,
  minValue: undefined,
  onChange: () => {},
  step: 1,
  label: '',
  maxLength: 524288,
  id: ''
}

QuantitySteper.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string
}

export default QuantitySteper
