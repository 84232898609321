// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Components
import Icon from 'shared/icon'
import Button from 'shared/button'
import { AuthConsumer } from 'contexts/auth'
import { SIGN_IN_ROUTE } from 'routes/publicRoutes'
import styles from './responsiveMenu.module.scss'

const ResponsiveMenu = ({ open, toggleOpen }) => (
  <React.Fragment>
    <Button id='top-bar-toggle' className={styles.toggle} onClick={toggleOpen}>
      <Icon
        size='large'
        className={styles.toggle_icon}
        icon={open ? 'close' : 'menu'}
      />
    </Button>
    <div
      className={`bottom-xs ${styles.content} ${
        open ? styles.open : styles.closed
      }`}
      aria-expanded={open}
    >
      <ul>
        <AuthConsumer>
          {({ isSignedIn, logout }) =>
            isSignedIn ? (
              <li>
                <Button className={styles.list_button} onClick={logout}>
                  Cerrar sesión
                </Button>
              </li>
            ) : (
              <React.Fragment>
                <li>
                  <a className={styles.list_button} href='https://latasa.mx'>
                    Quiero un crédito
                  </a>
                </li>
                <li>
                  <a
                    className={styles.list_button}
                    href='https://latasa.mx/inversionista.html'
                  >
                    Inversionista
                  </a>
                </li>
                <li>
                  <Link className={styles.list_button} to={SIGN_IN_ROUTE}>
                    Continuar solicitud
                  </Link>
                </li>
              </React.Fragment>
            )
          }
        </AuthConsumer>
      </ul>
    </div>
  </React.Fragment>
)

ResponsiveMenu.defaultProps = {
  open: false,
  toggleOpen: () => {}
}

ResponsiveMenu.propTypes = {
  open: PropTypes.bool,
  toggleOpen: PropTypes.func
}

export default ResponsiveMenu
