import { DateStringToObject, unformatMoney } from 'utils'
import { INITIAL_STEP } from 'routes/privateRoutes'
import {
  DOCUMENT_TYPE_VOTER_CARD,
  DOCUMENT_TYPE_PASSPORT
} from 'shared/catalogs'

/**
 * Extracts the API progress from the api data response
 * @param {object} apiData
 */
export const getAPIProgress = ({ extras }) => {
  return extras && extras.progress ? extras.progress : INITIAL_STEP
}

/**
 * Convert All the apiData object into a readable object to the form
 * @param {object} apiData
 */
export const transforAPIToDefaultData = apiData => {
  return {
    ...getAddressData(apiData),
    ...getUserData(apiData),
    ...getDocumentationData(apiData),
    beneficiaries: getBeneficiariesData(apiData),
    curp: apiData.curp,
    workActivity: apiData.work_activity,
    job: apiData.political_position,
    monthlyInvestment: apiData.monthly_investment
      ? unformatMoney(apiData.monthly_investment).toString()
      : '',
    initialInvestment: apiData.initial_investment
      ? unformatMoney(apiData.initial_investment).toString()
      : '',
    investmentSource: apiData.investment_source,
    monthlyAverageAmount: apiData.monthly_average_amount
  }
}

/**
 * @typedef {Object} DocumentationNode
 * @property {string} documentType
 * @property {string} passport the name of the uploaded file for passport
 * @property {string} proofOfAddress the name of the uploaded file for proofOfAddress
 * @property {string} voterCardFront the name of the uploaded file for id in the front
 * @property {string} voterCardBack the name of the uploaded file for id in the back
 * @property {boolean} isAddressTheSame
 *
 * @param {Object} apiResponse
 * @returns {DocumentationNode}
 */
const getDocumentationData = ({ documents }) => {
  const {
    id_back: idBack,
    id_front: idFront,
    residency_proof: residencyProof,
    passport
  } = documents || {
    id_back: '',
    id_front: '',
    residency_proof: '',
    passport: ''
  }

  const hasADocument = idBack || idFront || residencyProof || passport
  // Determine the document type depending on what the user uploaded
  const documentType = hasADocument
    ? passport
      ? DOCUMENT_TYPE_PASSPORT
      : DOCUMENT_TYPE_VOTER_CARD
    : undefined

  return {
    documentType,
    passport: passport ? getDocumentNameFromUrl(passport) : '',
    proofOfAddress: residencyProof
      ? getDocumentNameFromUrl(residencyProof)
      : '',
    voterCardFront: idFront ? getDocumentNameFromUrl(idFront) : '',
    voterCardBack: idBack ? getDocumentNameFromUrl(idBack) : '',
    isAddressTheSame: hasADocument
      ? !(documentType === DOCUMENT_TYPE_VOTER_CARD && residencyProof)
      : undefined
  }
}

/**
 * Extract the filename from the google storage, removing the domain and the hash from the provided url
 * @param {string} documentUrl
 * @returns {string} the filename
 */
const getDocumentNameFromUrl = documentUrl => {
  const name = documentUrl.substring(documentUrl.lastIndexOf('/'))
  return name.substring(37)
}

/**
 * Convert All the beneficiaries node of apiData into a readable object to the form
 * @param {object} apiData
 */
const getBeneficiariesData = ({ beneficiaries }) => {
  if (beneficiaries && beneficiaries.length > 0) {
    return beneficiaries.map(beneficiary => ({
      firstName: beneficiary.first_name,
      lastName: beneficiary.last_name,
      secondLastName: beneficiary.second_last_name,
      secondName: beneficiary.second_last_name,
      relationship: beneficiary.relationship,
      gender: beneficiary.gender,
      birthdate: beneficiary.birth_date
        ? DateStringToObject(beneficiary.birth_date)
        : {},
      percentage: beneficiary.percentage
        ? beneficiary.percentage.toString()
        : ''
    }))
  }
  return []
}
/**
 * Convert All the address node of apiData into a readable object to the form
 * @param {object} apiData
 */
const getAddressData = ({ address }) => {
  return address
    ? {
      street: address.street,
      externalNumber: address.external_number,
      internalNumber: address.internal_number,
      postalCode: address.postal_code,
      neighborhood: address.neighborhood,
      municipality: address.municipality,
      state: address.state,
      city: address.city
    }
    : {}
}

/**
 * Convert All the user node of apiData into a readable object to the form
 * @param {object} apiData
 */
const getUserData = ({ user }) => {
  return user
    ? {
      firstName: user.first_name,
      secondName: user.second_name,
      lastName: user.last_name,
      secondLastName: user.second_last_name,
      phone: user.phone,
      gender: user.gender,
      maritalStatus: user.marital_status,
      birthdate: user.birth_date ? DateStringToObject(user.birth_date) : {},
      birthPlace: user.birth_place ? user.birth_place.country : '',
      statePlace: user.birth_place ? user.birth_place.state : '',
      rfc: user.rfc,
      educationLevel: user.education_level,
      bankName:
          user.bank_accounts && user.bank_accounts[0]
            ? user.bank_accounts[0].bank_name
            : '',
      clabe:
          user.bank_accounts && user.bank_accounts[0]
            ? user.bank_accounts[0].standardized_bank_code
            : ''
    }
    : {}
}
