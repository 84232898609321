import {
  hasOnlyLetters,
  hasMinLength,
  hasOnlyNumbers,
  hasMaxLength,
  isPassword,
  isEmail,
  hasOnlyLettersAndNumbers
} from './stringValidations'
import { createDate } from '.'
import cardValidator from 'card-validator'

/**
 * @typedef {import('moment').Moment} Moment
 */

/**
 * @typedef {Object} DateObject
 * @property {number} year
 * @property {number} month
 * @property {number} day
 */

/**
 * Returns an error string of the provided executing the funtions in validations with value
 * @param {string} value value to be evaluated
 * @param {Function[]} validations array of functions to be executed with the value as the argumet
 */
export const validateInput = (value, validations) => {
  // iterate for all the validation functions
  for (const validateFunction of validations) {
    // execute the validation
    const validationResult = validateFunction(value)
    if (validationResult) {
      // if a result is returned, return and quit evaluating the next functions
      return validationResult
    }
  }
  return ''
}

/**
 * Returns an error string of the provided executing the funtions in validations with value
 * @param {string} message return message in case of error
 * @returns {function(DateObject): string}
 */
export const validateDate = message =>
  /**
   * @param {DateObject} value value to be evaluated
   */
  value => {
    const { day, month, year } = value
    return createDate(year, month, day).isValid() ? '' : message
  }

/**
 * Returns the evaluation function for the min date, when the evaluation function fails, it return the message
 * @param {Moment} minDate min date requirement
 * @param {string} message message to be returned
 * @returns {function(DateObject): string}
 */
export const validateMinDate = (minDate, message) =>
  /**
   * Evaluate that the provided value is set after the min date
   * @param {DateObject} value value to be evaluated
   */
  value => {
    const { day, month, year } = value
    const valueDate = createDate(year, month, day)
    return minDate.isAfter(valueDate) ? message : ''
  }

/**
 * Returns the evaluation function for the max date, when the evaluation function fails, it return the message
 * @param {Moment} maxDate max date requirement
 * @param {string} message message to be returned
 * @returns {function(DateObject): string}
 */
export const validateMaxDate = (maxDate, message) =>
  /**
   * Evaluate that the provided value is set before the max date
   * @param {DateObject} value value to be evaluated
   */
  value => {
    const { day, month, year } = value
    const valueDate = createDate(year, month, day)
    return valueDate.isAfter(maxDate) ? message : ''
  }

/**
 * Return the generic message for most of the null field validations
 * @param {string} fieldName custom field name
 */
export const genericValidationMessage = fieldName =>
  `Ingresa ${fieldName}. Este campo es requerido.`

/**
 * Returns wheter a provided is null or not
 * @param {string | boolean | number} value
 * @returns {boolean}
 */
export const isNull = value =>
  value === null ||
  typeof value === 'undefined' ||
  value.toString().trim() === ''

/**
 * Executes the isNull validation returning the provided message
 * @param {string} message
 * @returns {function(string): string}
 */
export const validateNullField = message => value =>
  isNull(value) ? message : ''

/**
 * Executes the isPassword validation returning the provided message if it fails
 * @param {string} message
 * @returns {function(string): string}
 */
export const validatePassword = message => value =>
  isPassword(value) ? '' : message

/**
 * Evaluate that the provided value is lower thant the maxValue
 * @param {number} maxValue
 * @param {string} message
 * @returns {function(string): string}
 */
export const validateMaxValue = (maxValue, message) => value =>
  Number(maxValue) < Number(value) ? message : ''

/**
 * Evaluate that the provided value is more thant the maxValue
 * @param {number} minValue
 * @param {string} message
 * @returns {function(string): string}
 */
export const validateMinValue = (minValue, message) => value =>
  Number(minValue) > Number(value) ? message : ''
/**
 * Executes the isEmail validation returning the provided message if it fails
 * @param {string} message
 * @returns {function(string): string}
 */
export const validateEmail = message => value => (isEmail(value) ? '' : message)

/**
 * Returns a generic message if the value is null
 * @param {*} value
 * @returns {string}
 */
export const validateNullSelect = value =>
  isNull(value) ? 'Por favor selecciona una opción.' : ''

/**
 * Validate that the provided text has only letters, if not, returns a generic error
 * @param {string} value
 * @returns {string}
 */
export const lettersOnly = value =>
  value && !hasOnlyLetters(value)
    ? 'Este campo únicamente debe incluir letras.'
    : ''
/**
 * Validate that the provided text has numbers and letters only, if not, returns a generic error
 * @param {string} value
 * @returns {string}
 */
export const lettersAndNumbersOnly = value =>
  value && !hasOnlyLettersAndNumbers(value)
    ? 'Este campo únicamente debe incluir letras y números.'
    : ''
/**
 * Validate that the provided text has only numbers, if not, returns a generic error
 * @param {string} value
 * @returns {string}
 */
export const numbersOnly = value =>
  value && !hasOnlyNumbers(value)
    ? 'Este campo únicamente debe incluir números.'
    : ''

/**
 * Validate that the provided text has the minimun length, if not, returns the provided message
 * @param {Number} length the mix length to be validated
 * @param {string} message the returning message
 * @returns {function(string): string}
 */
export const minLength = (length, message) => value =>
  value && !hasMinLength(value, length) ? message : ''

/**
 * Validate that the provided text has the maximum length, if not, returns the provided message
 * @param {Number} length the max length to be validated
 * @param {string} message the returning message
 * @returns {function(string): string}
 */
export const maxLength = (length, message) => value =>
  value && !hasMaxLength(value, length) ? message : ''

/**
 * Evaluates a composed object properties
 * @param {object} validations object with the keys to be evaluated for the composed object
 * @returns {function(string): string}
 */
export const validateComposedObject = validations => value => {
  // iterate through the validations object keys
  for (const objectProp of Object.keys(validations)) {
    // Extract the validation function an execute the provided validations for the specified key
    const partError = validations[objectProp]
      ? validateInput(value[objectProp], validations[objectProp])
      : ''
    if (partError) {
      // If the function return an error stop iterating the keys and return it
      return partError
    }
  }
  return ''
}
/**
 * Validate that the valueToCompare is equal to the value and returns the provided message if the validation failed
 * @param {string} valueToCompare
 * @param {string} message
 * @returns {function(string): string}
 */
export const isEqual = (valueToCompare, message) => value =>
  value && value === valueToCompare ? '' : message

/**
 * Validates that the entered card number is a valid number
 * @param {string} message
 * @returns {function(string): string}
 */
export const validateCreditCard = message => value =>
  value && cardValidator.number(value).isValid ? '' : message
