// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import { FORM_STEPS } from '../constants'
import Wizard from 'shared/wizard'
import Button from 'shared/button'

class StartToInvestForm extends Component {
  render () {
    const { goNext, currentStep, onComplete, goBack, data } = this.props

    // Get the current form to show depending on the currentStep
    const { component: CurrentForm, label } = FORM_STEPS[currentStep]
    return (
      <React.Fragment>
        <Wizard.Stepper
          className='margin-bottom'
          steps={FORM_STEPS}
          currentStep={currentStep}
          goBack={goBack}
          goNext={goNext}
        />
        {currentStep !== 0 && (
          <div className='start-xs margin-bottom-medium'>
            <Button theme='back-button' className='start-xs' onClick={goBack}>
              Volver
            </Button>
          </div>
        )}
        <h2 className='color-dark-blue text-uppercase start-xs margin-bottom'>
          {`${currentStep + 1}. ${label}`}
        </h2>
        <CurrentForm
          defaultValues={data}
          goNext={goNext}
          onComplete={onComplete}
        />
      </React.Fragment>
    )
  }
}

StartToInvestForm.propTypes = {
  goNext: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  goBack: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired /* eslint-disable-line */
}

export default StartToInvestForm
