// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Components
import Input from 'shared/input'
import Select from 'shared/select'
import { MONTH_OPTIONS } from 'shared/catalogs'
import { hasOnlyNumbers } from 'utils/stringValidations'

class DateSelector extends PureComponent {
  state = {
    day: '',
    year: '',
    month: ''
  }

  // Calls the on change props function with the current value
  notifyChange = () => {
    const { day, year, month } = this.state
    const { onChange, id, name } = this.props
    onChange(
      {
        day,
        year,
        month
      },
      name || id
    )
  }

  onDayChange = day => {
    this.setState({ day }, this.notifyChange)
  }

  onYearChange = year => {
    this.setState({ year }, this.notifyChange)
  }

  onMonthChange = month => {
    this.setState({ month }, this.notifyChange)
  }

  render () {
    const { id, label, className, invalidMessage, defaultValue } = this.props
    return (
      <div
        className={classNames({
          [className]: !!className
        })}
      >
        <p className='start-xs color-dark-blue text-uppercase text-smallest'>
          {label}
        </p>
        <div className='row'>
          <Input
            id={`${id}-day`}
            placeholder='Día'
            maxLength='2'
            className='col-xs-3'
            onChange={this.onDayChange}
            validate={hasOnlyNumbers}
            isInvalid={!!invalidMessage}
            defaultValue={defaultValue.day}
          />
          <Select
            id={`${id}-month`}
            placeholder='Mes'
            className='col-xs-6'
            options={MONTH_OPTIONS}
            onChange={this.onMonthChange}
            isInvalid={!!invalidMessage}
            defaultValue={defaultValue.month}
          />
          <Input
            maxLength='4'
            id={`${id}-year`}
            placeholder='Año'
            className='col-xs-3'
            onChange={this.onYearChange}
            valida
            validate={hasOnlyNumbers}
            isInvalid={!!invalidMessage}
            defaultValue={defaultValue.year}
          />
        </div>
        {<p className='color-error text-small start-xs'>{invalidMessage}</p>}
      </div>
    )
  }
}

DateSelector.defaultProps = {
  onChange: () => {},
  id: '',
  className: '',
  label: '',
  defaultValue: {},
  invalidMessage: '',
  name: ''
}

DateSelector.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  invalidMessage: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.object // eslint-disable-line react/forbid-prop-types
}

export default DateSelector
