const NOT_A_STRING_ERROR = 'The provided value is not a string'

export const isString = str => typeof str === 'string'

export const hasNumbers = str => {
  if (!isString(str)) throw new Error(NOT_A_STRING_ERROR)
  return /\d/.test(str)
}

export const hasMinLength = (str, length) => {
  if (!isString(str)) throw new Error(NOT_A_STRING_ERROR)
  return str.length >= length
}

export const hasMaxLength = (str, length) => {
  if (!isString(str)) throw new Error(NOT_A_STRING_ERROR)
  return str.length <= length
}

export const hasLetters = str => {
  if (!isString(str)) throw new Error(NOT_A_STRING_ERROR)
  return /[a-zA-Z]/.test(str)
}

export const isEmail = str => {
  if (!isString(str)) throw new Error(NOT_A_STRING_ERROR)
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(str)
}

export const isPassword = str => {
  return hasMinLength(str, 8) && hasLetters(str) && hasNumbers(str)
}

export const hasOnlyLetters = str => {
  if (!isString(str)) throw new Error(NOT_A_STRING_ERROR)
  return /^[A-zÀ-ú\s]*$/.test(str)
}

export const hasOnlyNumbers = str => {
  if (!isString(str)) throw new Error(NOT_A_STRING_ERROR)
  return /^[\d]*$/.test(str)
}

export const hasOnlyLettersAndNumbers = str => {
  if (!isString(str)) throw new Error(NOT_A_STRING_ERROR)
  return /^[aA-zZ0-9]*$/.test(str)
}
