// Libraries
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

// Components
import Icon from 'shared/icon'
import Tooltip from 'shared/tooltip'
import styles from './select.module.scss'
import { isNull } from 'utils/formValidations'

/**
 * @typedef {import('shared/catalogs').CatalogItem} CatalogItem
 */

/**
 * @typedef {Object} Props
 * @property {string} className
 * @property {string | number | boolean} defaultValue
 * @property {boolean} disabled
 * @property {string} id
 * @property {string} invalidMessage
 * @property {string} label
 * @property {Function} onChange
 * @property {string} placeholder
 * @property {boolean} isInvalid
 * @property {string} name
 * @property {CatalogItem[]} options
 * @property {*} onBlur
 * @property {string} tooltip
 *
 * @typedef {Object} State
 * @property {string} value
 * @property {CatalogItem[]} options
 */

/**
 * Validates that the default value is contained in the options
 * @param {number | string | boolean} defaultValue
 * @param {CatalogItem[]} options
 * @returns {number | string | boolean} The default value when it is in the options list, otherwise empty string
 */
const validateDefaultValue = (defaultValue, options) =>
  !isNull(defaultValue) &&
  options.find(({ value }) => value.toString() === defaultValue.toString())
    ? // The default is contained in the option list
    defaultValue
    : ''

/**
 * @extends {React.PureComponent<Props, State>}
 */
class Select extends React.PureComponent {
  constructor (props) {
    super(props)
    const placeholder = { value: '', disabled: true, label: props.placeholder }
    // If placeholder is provided, create a default option
    const finalOptions = props.placeholder
      ? [placeholder, ...props.options]
      : props.options

    const { defaultValue } = props

    const finalDefault = validateDefaultValue(defaultValue, finalOptions)

    this.state = {
      value: finalDefault || (finalOptions[0] && finalOptions[0].value) || '',
      options: finalOptions
    }
  }

  componentDidMount () {
    // Notify in case of having an initial value
    const { value } = this.state
    if (value) {
      this.notifyChange()
    }
  }

  getFinalDefault = props => {}

  handleChange = e => {
    const value = e.nativeEvent ? e.target.value : e
    this.setState({ value }, this.notifyChange)
  }

  /**
   * Call the onChange handler with the current name or value prop
   */
  notifyChange = () => {
    const { value } = this.state
    const { onChange, id, name } = this.props
    onChange(value, name || id)
  }

  // Main render
  render () {
    const {
      label,
      className,
      id,
      disabled,
      invalidMessage,
      isInvalid,
      tooltip,
      onBlur
    } = this.props
    const { value, options } = this.state

    const selectedPlaceHolder = value === ''
    return (
      <div
        className={classNames(styles.select, {
          [className]: !!className
        })}
      >
        <label htmlFor={id}>
          {label && (
            <div className={styles.label_container}>
              <p className={classNames(styles.label, 'text-smallest')}>
                {label}
              </p>

              {tooltip && (
                <Tooltip tooltip={tooltip}>
                  <Icon className='color-sky-blue' icon='information-outline' />
                </Tooltip>
              )}
            </div>
          )}

          <div
            className={classNames(styles.select_container, {
              [styles.invalid]: invalidMessage,
              [styles.disabled]: disabled
            })}
          >
            <span
              className={classNames(
                'mdi mdi-chevron-down',
                {
                  'color-light-gray': disabled,
                  'color-orient': !disabled
                },
                styles.select_toggle
              )}
              tabIndex={-1}
            />
            <select
              value={value}
              onChange={this.handleChange}
              className={classNames(styles.select_field, {
                'color-light-gray': selectedPlaceHolder,
                'color-orient': !selectedPlaceHolder,
                [styles.invalid]: invalidMessage || isInvalid
              })}
              onBlur={onBlur}
              id={id}
              data-testid={id}
              disabled={disabled}
            >
              {options.map(({ value, label, disabled }) => (
                <option disabled={disabled} key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          {<p className='color-error text-small'>{invalidMessage}</p>}
        </label>
      </div>
    )
  }
}

Select.defaultProps = {
  className: '',
  defaultValue: '',
  disabled: false,
  invalidMessage: '',
  onChange: () => {},
  onBlur: () => {},
  placeholder: '',
  options: [],
  isInvalid: false,
  label: '',
  name: '',
  tooltip: ''
}

Select.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  invalidMessage: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired
    })
  ),
  onBlur: PropTypes.func,
  tooltip: PropTypes.string
}

export default Select
