// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'
import uuid from 'uuid/v4'
import classNames from 'classnames'

// Components
import BeneficiaryDataForm from './beneficiaryDataForm'
import Button from 'shared/button'
import BeneficiariesPercentageSelector from './beneficiariesPercentageSelector'
import { range, cleanObject, objectIsEmpty, createDate } from 'utils'
import {
  validateInput,
  lettersOnly,
  minLength,
  validateNullField,
  numbersOnly,
  validateNullSelect,
  validateComposedObject,
  genericValidationMessage,
  validateDate,
  validateMinDate,
  validateMaxDate
} from 'utils/formValidations'
import { getBeneficiariesFormRequest } from '../utils'
import API from 'api'
import { notifyError } from 'utils/notifications'
import { cancelEvent } from 'utils/events'

/**
 * @typedef {Object} Beneficiary
 * @property {string} percentage
 *
 * @typedef {Object} BeneficiariesList
 * @property {Beneficiary[]} beneficiaries
 */
class BeneficiariesForm extends Component {
  state = {
    beneficiariesNumber: 1,
    beneficiariesArray: []
  }

  componentDidMount () {
    this.createInitialBeneficiariesArray()
  }

  handleAddBeneficiary = () => {
    // Just add one beneficiaries and create the new beneficiary object
    this.setState(
      ({ beneficiariesArray }) => ({
        // Create a new empty beneficiary
        beneficiariesArray: [
          ...beneficiariesArray,
          { id: uuid(), defaultValues: {} }
        ]
      }),
      this.updateBeneficiariesNumberValue
    )
  }

  handleRemoveBeneficiary = id => {
    const { setFieldValue, values } = this.props

    if (values && values.beneficiaries) {
      // Remove the values in formik
      setFieldValue(
        'beneficiaries',
        values.beneficiaries.filter(
          beneficiary => !beneficiary || beneficiary.id !== id
        )
      )
    }

    this.setState(
      ({ beneficiariesArray }) => ({
        // filter the beneficiaries Array
        beneficiariesArray: [
          ...beneficiariesArray.filter(beneficiary => beneficiary.id !== id)
        ]
      }),
      this.updateBeneficiariesNumberValue
    )
  }

  /**
   * Just updates the beneficiaries values in the formiik values
   * It is used as a callback of setstate
   */
  updateBeneficiariesNumberValue = () => {
    const { setFieldValue } = this.props
    const { beneficiariesArray } = this.state
    const beneficiariesNumber = beneficiariesArray.length
    this.setState({ beneficiariesNumber })
    setFieldValue('beneficiariesNumber', beneficiariesNumber)
  }

  // Sets the value in the formik form
  handleChange = (value, id) => {
    const { setFieldValue } = this.props
    setFieldValue(id, value)
  }

  handleBeneficiaryDataChanged = (beneficiaryNumber, value, id) => {
    const { values, setFieldTouched } = this.props
    const beneficiariesArray = values.beneficiaries || []
    const arrayTarget = `beneficiaries[${beneficiaryNumber - 1}]`
    setFieldTouched(`${arrayTarget}.${id}`)

    const newBeneficiariesValues = {
      ...beneficiariesArray[beneficiaryNumber - 1],
      [id]: value
    }
    // Update the new values in formik
    this.handleChange(newBeneficiariesValues, arrayTarget)
  }

  createInitialBeneficiariesArray () {
    const { defaultValues, setFieldValue } = this.props
    const totalBeneficiaries =
      defaultValues.beneficiaries && defaultValues.beneficiaries.length > 0
        ? defaultValues.beneficiaries.length
        : 1
    // Create a new array with unique ids for each beneficiary
    const beneficiariesArray = range(totalBeneficiaries).map(index => ({
      id: uuid(),
      // If provided, assign the default values for each beneficiary otherwise just an empty object
      ...(defaultValues.beneficiaries && defaultValues.beneficiaries[index]
        ? defaultValues.beneficiaries[index]
        : {})
    }))
    // Set the initial beneficiaries number value
    setFieldValue('beneficiariesNumber', beneficiariesArray.length)
    // Set the complete beneficiaries array in the formik data to avoid async issues
    setFieldValue('beneficiaries', beneficiariesArray)
    this.setState({ beneficiariesArray }, this.updateBeneficiariesNumberValue)
  }

  render () {
    const {
      handleSubmit,
      isSubmitting,
      submitCount,
      errors,
      touched,
      values
    } = this.props
    const { beneficiariesNumber, beneficiariesArray } = this.state
    const beneficiariesErrors = errors.beneficiaries || []
    const beneficiariesTouched = { ...touched.beneficiaries } || {}

    return (
      <form onSubmit={isSubmitting ? cancelEvent : handleSubmit}>
        <p className='start-xs margin-bottom-small'>
          Un beneficiario es la persona que tiene derecho a recibir el monto de
          la inversión en caso de fallecimiento del titular de la cuenta. Es
          posible tener más de un beneficiario, en este caso deberás elegir el
          porcentaje de la inversión que se asignará a uno.
        </p>
        {beneficiariesArray.map((beneficiary, index) => (
          <BeneficiaryDataForm
            id={beneficiary.id}
            key={beneficiary.id}
            defaultValues={beneficiary}
            beneficiaryNumber={index + 1}
            onChange={this.handleBeneficiaryDataChanged}
            submitCount={submitCount}
            errors={beneficiariesErrors[index] || {}}
            touched={
              typeof beneficiariesTouched[index] === 'object'
                ? beneficiariesTouched[index]
                : {}
            }
          />
        ))}
        {beneficiariesNumber > 1 && (
          <BeneficiariesPercentageSelector
            onDelete={this.handleRemoveBeneficiary}
            onChange={this.handleBeneficiaryDataChanged}
            beneficiariesArray={beneficiariesArray}
            values={values}
            errors={errors}
            touched={touched}
            submitCount={submitCount}
          />
        )}
        <p
          className={classNames(
            'text-smallest start-xs color-dark-gray',
            `margin-bottom${beneficiariesNumber < 4 ? '-medium' : ''}`
          )}
        >
          * Campos obligatorios
        </p>
        {beneficiariesNumber < 4 && (
          <div className='start-xs margin-bottom-small'>
            <Button
              onClick={this.handleAddBeneficiary}
              className='text-bold text-small color-sky-blue text-underline'
              disabled={isSubmitting}
            >
              Agregar otro beneficiario
            </Button>
          </div>
        )}
        <Button isLoading={isSubmitting} theme='primary' type='submit'>
          Continuar
        </Button>
      </form>
    )
  }
}

const validateBeneficiary = (beneficiary, totalBeneficiaries) => {
  const currentDate = new Date()

  const errors = {
    firstName: validateInput(beneficiary.firstName, [
      validateNullField(genericValidationMessage('el nombre del beneficiario')),
      lettersOnly
    ]),
    secondName: validateInput(beneficiary.secondName, [lettersOnly]),
    lastName: validateInput(beneficiary.lastName, [
      validateNullField(genericValidationMessage('su apellido paterno')),
      lettersOnly
    ]),
    secondLastName: validateInput(beneficiary.secondLastName, [
      validateNullField(genericValidationMessage('su apellido materno')),
      lettersOnly
    ]),
    gender: validateNullSelect(beneficiary.gender),
    // Validate if something has been entered to the date selector
    birthdate:
      !beneficiary.birthdate || objectIsEmpty(beneficiary.birthdate)
        ? genericValidationMessage('su fecha de nacimiento')
        : validateInput(beneficiary.birthdate, [
          validateComposedObject({
            day: [
              validateNullField(
                genericValidationMessage('el día de su nacimiento')
              )
            ],
            month: [
              validateNullField(
                genericValidationMessage('el mes de su nacimiento')
              )
            ],
            year: [
              validateNullField(
                genericValidationMessage('el año de su nacimiento')
              ),
              minLength(4, 'Ingresa su año de nacimiento a 4 dígitos.')
            ]
          }),
          validateDate(
            'Parece que el día que ingresaste no es correcto. Por favor, ingresa un día que corresponda al mes que seleccionaste.'
          ),
          validateMinDate(
            createDate(
              currentDate.getFullYear() - 100,
              currentDate.getMonth() + 1,
              currentDate.getDate()
            ),
            'Ingresa una fecha de nacimiento válida.'
          ),
          validateMaxDate(
            createDate(
              currentDate.getFullYear(),
              currentDate.getMonth() + 1,
              currentDate.getDate()
            ),
            'Ingresa una fecha de nacimiento válida.'
          )
        ]),
    relationship: validateNullSelect(beneficiary.relationship),
    // Validate percentage only if we have more than one beneficiary
    percentage:
      totalBeneficiaries > 1
        ? validateInput(beneficiary.percentage, [
          validateNullField('Este campo requerido.')
        ])
        : ''
  }
  return cleanObject(errors)
}

/**
 * validate function called before handle submmit
 * @param {BeneficiariesList} values
 */
const validate = values => {
  // Create an empty array to make the validations
  const beneficiariesErrors = range(values.beneficiariesNumber || 1).map(
    // Validate each element of array, sending an empty object as a default
    index =>
      validateBeneficiary(
        values.beneficiaries && values.beneficiaries[index]
          ? values.beneficiaries[index]
          : {},
        values.beneficiariesNumber
      )
  )

  // Verify that the percentages do not have any error (all are set)
  const areAllThePercentagesSet = range(values.beneficiariesNumber).every(
    index => !beneficiariesErrors[index].percentage
  )

  let totalPercentages = ''
  // If all the percentages are set, validate the amount
  if (areAllThePercentagesSet) {
    if (values.beneficiariesNumber > 1 && !validatePercentages(values)) {
      totalPercentages = 'La suma de los porcentajes debe ser igual a 100%.'
    }
  }

  // verify if the form has any error, if the beneficiary is not an empty object it has an error
  const hasError = !beneficiariesErrors.every(beneficiary =>
    objectIsEmpty(beneficiary)
  )

  // if we have an error, send a null value instead of an actual object
  const errors = {
    beneficiaries: hasError ? beneficiariesErrors : null,
    totalPercentages
  }
  return cleanObject(errors)
}

/**
 * Validates that the sum of all the percentage values are equal to 100
 * @param {BeneficiariesList} values
 */
const validatePercentages = ({ beneficiaries }) => {
  // get the sum of all percentages
  const totalPercentage = beneficiaries.reduce(
    (acc, beneficiary) => acc + Number(beneficiary.percentage),
    0
  )
  return totalPercentage === 100
}

const handleSubmit = async (values, { setSubmitting, props }) => {
  setSubmitting(true)
  try {
    await API.Opportunities.Update(getBeneficiariesFormRequest(values))
    props.goNext(values)
  } catch (e) {
    console.error(e)
    notifyError('Ocurrió un error, intenta de nuevo más tarde.')
    setSubmitting(false)
  }
}

BeneficiariesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  submitCount: PropTypes.number.isRequired,
  defaultValues: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  values: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
}

export default withFormik({
  // Avoid taking props as values
  mapPropsToValues: () => {},
  validateOnBlur: false,
  validate,
  handleSubmit
})(BeneficiariesForm)
