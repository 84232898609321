// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Components
import SwitchOption from './switchOption'
import styles from './optionSwitch.module.scss'

class OptionSwitch extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selected: props.defaultValue
    }
  }

  componentDidMount () {
    const { selected } = this.state
    if (selected !== null && typeof selected !== 'undefined') {
      this.notifyChange()
    }
  }

  notifyChange = () => {
    const { onChange, id, name } = this.props
    const { selected } = this.state
    onChange(selected, name || id)
  }
  onSelectOption = selected => {
    this.setState({ selected }, this.notifyChange)
  }
  render () {
    const { selected } = this.state
    const {
      options,
      optionClassName,
      label,
      className,
      wrap,
      invalidMessage,
      id
    } = this.props
    return (
      <div className={`${className} ${styles.option_switch}`}>
        {label}
        <div
          className={classNames(styles.group, {
            'flex-wrap': wrap,
            'inline-direction': !wrap
          })}
        >
          {options.map(({ value, label }) => (
            <SwitchOption
              id={`${id}-${value}`}
              selected={selected === value}
              value={value}
              onSelect={this.onSelectOption}
              key={`switch-option-${id}-${value}`}
              label={label}
              className={optionClassName}
            />
          ))}
        </div>
        {<p className='color-error text-small start-xs'>{invalidMessage}</p>}
      </div>
    )
  }
}

OptionSwitch.defaultProps = {
  label: '',
  defaultValue: undefined,
  onChange: () => {},
  className: '',
  wrap: true,
  optionClassName: '',
  invalidMessage: '',
  name: ''
}

OptionSwitch.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
      ]).isRequired
    })
  ).isRequired,
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  className: PropTypes.string,
  wrap: PropTypes.bool,
  optionClassName: PropTypes.string,
  invalidMessage: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string
}

export default OptionSwitch
