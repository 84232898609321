// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'

// Components
import Button from 'shared/button'
import Input from 'shared/input'
import Select from 'shared/select'
import {
  WORK_ACTIVITY_OPTIONS,
  EDUCATION_LEVEL_OPTIONS,
  LOCAL_COUNTRY
} from 'shared/catalogs'
import Icon from 'shared/icon'

import {
  validateInput,
  minLength,
  validateNullField,
  validateNullSelect,
  genericValidationMessage,
  lettersAndNumbersOnly
} from 'utils/formValidations'
import { cleanObject, createDate, DateToString } from 'utils'
import { getWorkDataFormRequest } from './utils'
import API from 'api'
import { notifyError } from 'utils/notifications'
import { getCurp } from 'utils/curp'
import { cancelEvent } from 'utils/events'

const defaultState = {
  isLoadingRFC: false,
  rfc: '',
  curp: ''
}
class WorkDataForm extends Component {
  state = {
    ...defaultState
  }

  constructor (props) {
    super(props)
    const { defaultValues } = props
    if (!defaultValues.curp) {
      this.state = {
        ...defaultState,
        curp:
          defaultValues.birthPlace === LOCAL_COUNTRY
            ? getCurp(defaultValues)
            : ''
      }
    }
  }

  componentDidMount () {
    const { defaultValues } = this.props
    // If no default rfc is set, make the request
    if (!defaultValues.rfc) {
      this.getRFC()
    }
  }

  getRFC = async () => {
    const { defaultValues } = this.props
    if (!defaultValues.rfc) {
      this.setState({ isLoadingRFC: true })

      try {
        const {
          firstName,
          secondName,
          lastName,
          secondLastName,
          birthdate
        } = defaultValues
        const {
          data: {
            data: { rfc }
          }
        } = await API.Services.RFC({
          rfc: {
            // build the names from the default values
            name: `${firstName}${secondName ? ` ${secondName}` : ''}`,
            lastname: lastName,
            lastname2: secondLastName,
            birthdate: DateToString(
              createDate(birthdate.year, birthdate.month, birthdate.day)
            )
          }
        })
        this.setState({ rfc })
      } catch (error) {
        console.error(error)
        notifyError('Hubo un problema al calcular tu RFC.')
      } finally {
        this.setState({ isLoadingRFC: false })
      }
    }
  }

  // Sets the value in the formik form
  handleChange = (value, id) => {
    const { setFieldTouched, setFieldValue } = this.props
    setFieldTouched(id)
    setFieldValue(id, value)
  }

  getError = field => {
    const { errors, touched, submitCount } = this.props
    // If submitted or field touched, return field error
    return submitCount > 0 || touched[field] ? errors[field] : ''
  }

  render () {
    const { handleSubmit, isSubmitting, defaultValues } = this.props
    const { isLoadingRFC, rfc, curp } = this.state

    const currentRFC = defaultValues.rfc || rfc
    const currentHomoclave =
      currentRFC && currentRFC.length === 13 ? currentRFC.substring(10) : ''
    return (
      <form onSubmit={isSubmitting ? cancelEvent : handleSubmit}>
        <p className='start-xs margin-bottom-small margin-bottom'>
          Por favor, verifica que tu RFC y tu CURP sean correctos
        </p>
        <div className='inline-direction'>
          <Input
            className='margin-bottom-small'
            label='Rfc*'
            id='rfc'
            onChange={this.handleChange}
            invalidMessage={this.getError('rfc')}
            maxLength='10'
            defaultValue={currentRFC.substring(0, 10)}
            disabled={isLoadingRFC}
            indicator={isLoadingRFC ? <Icon icon='loading' /> : null}
            // set the key to force re render when rfc is loaded
            key={isLoadingRFC ? 'no_rfc' : 'rfc'}
          />
          <Input
            className='margin-bottom-small'
            label='Homoclave'
            id='homoclave'
            onChange={this.handleChange}
            invalidMessage={this.getError('homoclave')}
            maxLength='3'
            defaultValue={currentHomoclave}
            disabled={isLoadingRFC}
            indicator={isLoadingRFC ? <Icon icon='loading' /> : null}
            // set the key to force re render when rfc is loaded
            key={isLoadingRFC ? 'no_homoclave' : 'homoclave'}
          />
        </div>

        <Input
          className='margin-bottom-small'
          label='Curp *'
          id='curp'
          invalidMessage={this.getError('curp')}
          onChange={this.handleChange}
          maxLength='18'
          defaultValue={defaultValues.curp || curp}
        />
        <Select
          className='margin-bottom-small'
          label='Nivel de estudios *'
          id='educationLevel'
          invalidMessage={this.getError('educationLevel')}
          onChange={this.handleChange}
          options={EDUCATION_LEVEL_OPTIONS}
          placeholder='Selecciona'
          defaultValue={defaultValues.educationLevel}
        />
        <Select
          id='workActivity'
          className='margin-bottom-small'
          label='Actividad laboral *'
          onChange={this.handleChange}
          options={WORK_ACTIVITY_OPTIONS}
          placeholder='Selecciona'
          invalidMessage={this.getError('workActivity')}
          defaultValue={defaultValues.workActivity}
        />
        <p className='text-smallest start-xs color-dark-gray margin-bottom'>
          * Campos obligatorios
        </p>
        <Button isLoading={isSubmitting} theme='primary' type='submit'>
          Guardar
        </Button>
      </form>
    )
  }
}

WorkDataForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValues: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

// validate function called before handle submit
const validate = values => {
  // Create an errors object with built with the form form validations
  const errors = {
    rfc: validateInput(values.rfc, [
      validateNullField(genericValidationMessage('tu RFC con homoclave')),
      lettersAndNumbersOnly,
      minLength(10, 'Tu RFC debe contener 10 caracteres.')
    ]),
    homoclave: validateInput(values.homoclave, [
      lettersAndNumbersOnly,
      minLength(3, 'Tu Homoclave debe contener 3 dígitos.')
    ]),
    curp: validateInput(values.curp, [
      validateNullField(genericValidationMessage('los 18 dígitos de tu CURP')),
      lettersAndNumbersOnly,
      minLength(18, 'Debes ingresar los 18 dígitos de tu CURP.')
    ]),
    workActivity: validateInput(values.workActivity, [validateNullSelect]),
    educationLevel: validateInput(values.educationLevel, [validateNullSelect])
  }
  // Remove the empty keys and return the errors object
  return cleanObject(errors)
}

const handleSubmit = async (values, { props, setSubmitting }) => {
  setSubmitting(true)
  try {
    await API.Opportunities.Update(getWorkDataFormRequest(values))

    // Remove homoclave from values, then add the homoclave to the rfc
    const { homoclave, ...finalValues } = values
    props.onComplete({
      ...finalValues,
      rfc: `${finalValues.rfc}${homoclave || ''}`
    })
  } catch (e) {
    console.error(e)
    notifyError('Ocurrió un error, intenta de nuevo más tarde.')
    setSubmitting(false)
  }
}

export default withFormik({
  validate,
  handleSubmit,
  // Avoid taking props as values
  mapPropsToValues: () => {},
  validateOnBlur: false
})(WorkDataForm)
