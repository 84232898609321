// Libraries
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

// Components
import PublicPage from 'shared/publicPage'
import DocumentTitle from 'shared/documentTitle'
import SignInForm from './signInForm'
import { AuthConsumer } from 'contexts/auth'

class SignInView extends Component {
  render () {
    return (
      <PublicPage>
        <DocumentTitle title='Continúa tu solicitud' />
        <h1 className='color-dark-blue margin-bottom-small text-bold text-uppercase'>
          Continúa tu solicitud
        </h1>
        <h3 className='margin-bottom-large text-light'>
          Para continuar con tu solicitud es necesario que ingreses el correo y
          la contraseña con la que te registraste.
        </h3>
        <AuthConsumer>
          {({ createSession }) => <SignInForm createSession={createSession} />}
        </AuthConsumer>
      </PublicPage>
    )
  }
}

export default withRouter(SignInView)
