// Libraries
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import animatedScrollTo from 'animated-scroll-to'

// Components
import PublicPage from 'shared/publicPage'
import DocumentTitle from 'shared/documentTitle'
import PageHeader from 'shared/pageHeader'
import {
  START_TO_INVEST_ROUTE,
  START_TO_INVEST_COMPLETED
} from 'routes/privateRoutes'
import StartToInvestForm from './startToInvestForm'
import { FORM_STEPS } from './constants'
import withOpportunityProgress from 'contexts/opportunity/withOpportunityProgress'
import { notifyInfo } from 'utils/notifications'

const pageHeader = (
  <PageHeader
    title={'¡Comienza a invertir!'}
    subtitle={
      'Para poder generar tus contratos y garantizar la seguridad de tu inversión, es necesario que nos proporciones la siguiente información.'
    }
  />
)

class StartToInvestView extends Component {
  referenceDivRef = React.createRef()

  constructor (props) {
    super(props)
    this.state = {
      currentStep: 0,
      formData: props.savedData
    }
  }

  componentDidMount () {
    // Change for the first step on mount
    this.redirectToFirstStep()
  }

  componentDidUpdate (prevProps) {
    const { step } = this.props
    if (step !== prevProps.step) {
      // The step prop has change, validate that is a valid one
      this.validateStep()
    }
  }

  scrollToTop = () => {
    const { current } = this.referenceDivRef
    // Give 5px of offset
    animatedScrollTo(current.offsetTop - 5, {
      element: document.getElementById('public-page')
    })
  }

  onComplete = data => {
    const { formData } = this.state
    const { setOpportunityData } = this.props
    const savedData = { ...formData, ...data }
    // Set the progress, it will automatically redirect to the new view
    setOpportunityData({ savedData, progress: START_TO_INVEST_COMPLETED })
  }

  redirectToFirstStep = () => {
    this.goToStep(0)
  }
  validateStep = () => {
    const { step } = this.props
    // Find a step according to the provided route
    const nextStep = FORM_STEPS.find(fStep => fStep.route === step)
    if (nextStep) {
      // If we find it, set it as the current step
      this.setState({ currentStep: FORM_STEPS.indexOf(nextStep) })
    } else {
      // If an invalid step is provided, redirect to the first step
      this.redirectToFirstStep()
    }
  }

  goNext = data => {
    const { currentStep } = this.state
    const { history } = this.props
    // Append the data when clicking on go next
    if (this.props.location.pathname === '/correccion-documentacion') {
      history.push('/completado')
      notifyInfo('Tus documentos se han actualizado!')
      return
    }
    this.setState(({ formData }) => ({ formData: { ...formData, ...data } }))
    this.goToStep(currentStep + 1)
    this.scrollToTop()
  }

  goBack = () => {
    const { history } = this.props
    history.goBack()
  }

  goToStep = step => {
    const { history, location } = this.props
    const nextStep = FORM_STEPS[step]
    if (location.pathname === '/correccion-documentacion' && step === 0) {
      return
    }
    if (nextStep) {
      history.push(`${START_TO_INVEST_ROUTE}/${nextStep.route}`)
    }
  }

  render () {
    const { currentStep, formData } = this.state
    return (
      <PublicPage pageHeader={pageHeader}>
        <DocumentTitle title='¡Comienza a invertir!' />
        <div ref={this.referenceDivRef}>
          <StartToInvestForm
            steps={FORM_STEPS}
            currentStep={currentStep}
            onComplete={this.onComplete}
            goNext={this.goNext}
            goBack={this.goBack}
            data={formData}
          />
        </div>
      </PublicPage>
    )
  }
}

StartToInvestView.defaultProps = {
  step: '',
  savedData: {}
}

StartToInvestView.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func
  }).isRequired,
  step: PropTypes.string,
  savedData: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  setOpportunityData: PropTypes.func.isRequired
}

export default withRouter(withOpportunityProgress(StartToInvestView))
