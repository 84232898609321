// Libraries
import axios from 'axios'

// Components
import AuthStore from 'stores/authStore'

// Sepomex API data
const SEPOMEX_CONFIG = {
  baseURL: process.env.REACT_APP_TASA_URL,
  headers: { Authorization: process.env.REACT_APP_TASA_URL }
}

// RFC API config
const RFC_CONFIG = {
  baseURL: process.env.RESUELVE_APP_URL,
  headers: {
    'Content-Type': 'application/json'
  }
}

// Config for axios
const getApiConfig = () => {
  const session = AuthStore.getSession()
  // add the auth token if it exists
  const authHeader = session
    ? {
      Authorization: `bearer ${session.token}`
    }
    : {}
  return {
    baseURL: process.env.API_URL,
    headers: {
      'Content-Type': 'application/json',
      ...authHeader
    }
  }
}

const makePostRequest = (url, data) => {
  return axios.post(url, data, getApiConfig())
}

const makePutRequest = (url, data) => {
  return axios.put(url, data, getApiConfig())
}

const makeGetRequest = url => {
  return axios.get(url, getApiConfig())
}
class User {
  // Updates user data
  static Update (data) {
    return makePutRequest(
      `opportunities/investor/${AuthStore.getSession().userId}`,
      data
    )
  }
}
class Opportunities {
  // Handle sign up
  static SignUp (data) {
    return makePostRequest('opportunities/investor', data)
  }

  // Updates opportunity data
  static Update (data) {
    return makePutRequest(
      `opportunities/investor/${AuthStore.getSession().id}`,
      data
    )
  }

  static SignIn (data) {
    return makePostRequest('auth/login', data)
  }

  // load opportunity data
  static Show () {
    return makeGetRequest(`opportunities/investor/${AuthStore.getSession().id}`)
  }

  static RecoverPassword (data) {
    return makePostRequest('auth/password-reset', data)
  }

  static ResetPassword (token, data) {
    return makePostRequest(`auth/password-reset/${token}`, data)
  }
}

class Services {
  // Send a postal code, receive address info
  static PostalCode (postalCode) {
    const params = { zip_code: postalCode }
    return axios.get('sepomex', { ...SEPOMEX_CONFIG, params })
  }
  // Send general data, receive complete rfc
  static RFC (data) {
    return axios.post('rfc', data, RFC_CONFIG)
  }
}

export default {
  Opportunities,
  Services,
  User
}
