// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Components
import styles from './switchOption.module.scss'
import Button from 'shared/button'

class SwitchOption extends PureComponent {
  handleSelect = () => {
    const { onSelect, value } = this.props
    onSelect(value)
  }

  render () {
    const { className, label, selected, id } = this.props
    // Generate and id object if provided
    const idProps = id ? { id, 'data-testid': id } : {}
    return (
      <Button
        {...idProps}
        className={classNames('focusable', styles.switch_option, {
          [styles.selected]: selected,
          [className]: !!className
        })}
        onClick={this.handleSelect}
      >
        {label}
      </Button>
    )
  }
}

SwitchOption.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired
}

SwitchOption.defaultProps = {
  label: '',
  selected: false,
  className: ''
}

export default SwitchOption
