// Libraries
import React from 'react'
import uuid from 'uuid/v4'
import PropTypes from 'prop-types'

// Components
import RoutesList from './routesList'
import ParametrizedRoute from './parametrizedRoute'

// Views
import ComplementProfileView from 'views/complementProfileView'
import StartToInvestView from 'views/startToInvestView'
import GeneratingContractView from 'views/generatingContractView'
// Exports
export const COMPLEMENT_PROFILE_ROUTE = '/complementar-perfil'
export const START_TO_INVEST_ROUTE = '/comienza-a-invertir'
export const FORM_COMPLETED_ROUTE = '/completado'

// Steps
export const INITIAL_STEP = 'initialStep'
export const COMPLEMENT_PROFILE_COMPLETED = 'complementProfileCompleted'
export const START_TO_INVEST_COMPLETED = 'startToInvestCompleted'

const routesByStep = {
  [INITIAL_STEP]: COMPLEMENT_PROFILE_ROUTE,
  [COMPLEMENT_PROFILE_COMPLETED]: START_TO_INVEST_ROUTE,
  [START_TO_INVEST_COMPLETED]: FORM_COMPLETED_ROUTE
}

const PRIVATE_ROUTES = [
  {
    id: uuid(),
    path: `${COMPLEMENT_PROFILE_ROUTE}/:step?`,
    render: props => (
      <ParametrizedRoute {...props} component={ComplementProfileView} />
    ),
    showOn: INITIAL_STEP
  },
  {
    id: uuid(),
    path: `${START_TO_INVEST_ROUTE}/:step?`,
    render: props => (
      <ParametrizedRoute {...props} component={StartToInvestView} />
    ),
    showOn: COMPLEMENT_PROFILE_COMPLETED
  },
  {
    id: uuid(),
    path: `/correccion-documentacion`,
    render: props => {
      return <ParametrizedRoute {...props} component={StartToInvestView} />
    },
    showOn: START_TO_INVEST_COMPLETED
  },
  {
    id: uuid(),
    path: `${FORM_COMPLETED_ROUTE}`,
    render: props => {
      return <ParametrizedRoute {...props} component={GeneratingContractView} />
    },
    // component: GeneratingContractView,
    showOn: START_TO_INVEST_COMPLETED
  }
]

const PrivateRoutes = ({ currentStep }) => {
  // Extract the default route depeending on the current progress
  const defaultRoute = routesByStep[currentStep]
  // Filter all routes that are not visible for the step
  const currentRoutes = PRIVATE_ROUTES.filter(
    ({ showOn }) => showOn === currentStep
  )
  return (
    <RoutesList
      routes={currentRoutes}
      defaultRoute={defaultRoute}
      id='private-routes'
    />
  )
}

PrivateRoutes.propTypes = {
  currentStep: PropTypes.string.isRequired
}

export default PrivateRoutes
