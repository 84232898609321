// Libraries
import React, { Component } from 'react'
import { withFormik } from 'formik'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Components
import Button from 'shared/button'
import Input from 'shared/input'
import API from 'api'
import { notifyError } from 'utils/notifications'
import { cleanObject, getResponseError } from 'utils'
import {
  validateNullField,
  genericValidationMessage,
  validateInput,
  validateEmail
} from 'utils/formValidations'
import { SIGN_UP_ROUTE, SIGN_IN_ROUTE } from 'routes/publicRoutes'
import { cancelEvent } from 'utils/events'

class RecoverPasswordForm extends Component {
  componentDidUpdate (prevProps) {
    const { status, onSuccess, values } = this.props
    if (status.success && !prevProps.status.success) {
      // If the form has succeded notify the parent
      onSuccess(values.email)
    }
  }
  // Sets the value in the formik form
  handleChange = (value, id) => {
    const { setFieldTouched, setFieldValue } = this.props
    setFieldValue(id, value)
    setFieldTouched(id)
  }

  getError = field => {
    const { errors, touched } = this.props
    return touched[field] ? errors[field] : ''
  }

  render () {
    const { isValid, handleSubmit, isSubmitting } = this.props
    return (
      <React.Fragment>
        <form onSubmit={isSubmitting ? cancelEvent : handleSubmit}>
          <Input
            className='margin-bottom-small'
            label='Correo electrónico *'
            onChange={this.handleChange}
            id='email'
            type='email'
            invalidMessage={this.getError('email')}
          />
          <p className='text-smallest start-xs color-dark-gray margin-bottom'>
            * Campos obligatorios
          </p>
          <Button
            className='margin-bottom-small'
            theme='primary'
            type='submit'
            isLoading={isSubmitting}
            disabled={!isValid}
          >
            Aceptar
          </Button>
        </form>
        <p>
          <Link className='color-light-gray text-small' to={SIGN_IN_ROUTE}>
            ¿Ya tienes cuenta?
          </Link>
        </p>
        <p>
          <Link className='color-light-gray text-small' to={SIGN_UP_ROUTE}>
            ¿Aún no estás registrado?
          </Link>
        </p>
      </React.Fragment>
    )
  }
}

// validate function called before handle submit
const validate = values => {
  const { email } = values
  // Create an errors object
  const errors = {
    email: validateInput(email, [
      validateNullField(
        genericValidationMessage('tu dirección de correo electrónico')
      ),
      validateEmail(
        'Ingresa una dirección de correo electrónico válida, ejemplo: nombre@mail.com'
      )
    ])
  }

  return cleanObject(errors)
}

const handleSubmit = async (values, { setSubmitting, setStatus }) => {
  try {
    await API.Opportunities.RecoverPassword({ ...values })
    setStatus({ success: true })
  } catch (e) {
    const error = getResponseError(e)
    if (error) {
      notifyError(error)
    } else {
      notifyError('Ocurrió un error, intenta de nuevo más tarde')
    }
    console.error(e)
    // Only setSubmitting false when the sign up failed, beacuse when not, it will redirect to another page
    setSubmitting(false)
  }
}

RecoverPasswordForm.defaultProps = {
  status: {},
  errors: {},
  touched: {},
  values: {}
}

RecoverPasswordForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string
  }),
  touched: PropTypes.shape({
    email: PropTypes.bool
  }),
  values: PropTypes.shape({
    email: PropTypes.string
  }),
  isSubmitting: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.shape({
    success: PropTypes.bool
  }),
  onSuccess: PropTypes.func.isRequired
}

export default withFormik({
  validate,
  handleSubmit,
  // Avoid taking props as values
  mapPropsToValues: () => {}
})(RecoverPasswordForm)
