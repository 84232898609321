import { GRAPHIC_ELEMENTS } from './constants'
import { formatMoney } from 'utils'

const calculateEarnings = (
  initialInvestment,
  monthlyInvestment,
  yearlyRate,
  years
) => {
  // Get the total investment months as the motal months of the year
  const totalInvestmentMonths = years * 12
  // Get the total investment amount, initial + monthly - the first month
  const totalInvestment =
    initialInvestment + monthlyInvestment * (totalInvestmentMonths - 1)
  // Monthly rate converted as a floating number
  const monthlyRate = yearlyRate / 12 / 100

  const monthsArray = [...Array(totalInvestmentMonths).keys()]
  // Reduce function to calculate the final amount with the initialamount
  const finalAmount = monthsArray.reduce(
    // Return the total and the monthly investment multiplied for the monthly rate
    (total, _, index) => {
      // Ignore the monthlyInvestment in the first month
      const newMonthlyAmount = index === 0 ? total : total + monthlyInvestment
      return newMonthlyAmount * (1 + monthlyRate)
    },
    initialInvestment
  )

  return {
    totalInvestment,
    finalAmount: Math.floor(finalAmount),
    totalEarnings: Math.floor(finalAmount - totalInvestment)
  }
}

// Returns the graphic data, according to the graphic elements
export const getGraphicData = (initialInvestment, monthlyInvestment, years) => {
  const data = GRAPHIC_ELEMENTS.reduce((previous, element) => {
    // Get the earnings for each element in the array
    const earnings = calculateEarnings(
      initialInvestment,
      monthlyInvestment,
      element.rate,
      years
    )
    // Return the previous object with the new data element
    return {
      ...previous,
      [element.id]: {
        ...element,
        name: element.label.toUpperCase(),
        ...earnings,
        moneyValue: formatMoney(earnings.finalAmount)
      }
    }
  }, {})
  // Return it as an array
  return [data]
}
