// Libraries
import React, { Component } from 'react'
import { withFormik } from 'formik'
import PropTypes from 'prop-types'

// Components
import Button from 'shared/button'
import Input from 'shared/input'
import Checkbox from 'shared/checkbox'
import PasswordValidator from './passwordValidator'
import API from 'api'
import { notifyError } from 'utils/notifications'
import { cleanObject } from 'utils'
import { getSignUpRequest } from './utils'
import {
  validateNullField,
  genericValidationMessage,
  validateEmail,
  validateInput,
  validatePassword
} from 'utils/formValidations'
import { cancelEvent } from 'utils/events'

/**
 * HTML markup to be rendered as the checkbox label
 * @type {React.ReactNode}
 */
const checkoxLabel = (
  <p>
    Acepto los
    {' '}
    <a
      tabIndex={-1}
      href='https://latasa.mx/Docs/terminos_condiciones_la_tasa.pdf'
      target='_blank'
      rel='noopener noreferrer'
      className='color-blue'
    >
      Términos y Condiciones
    </a>
    {', la '}
    <a
      tabIndex={-1}
      href='https://latasa.mx/Docs/aviso_privacidad_latasa.pdf'
      target='_blank'
      rel='noopener noreferrer'
      className='color-blue'
    >
      {'Política de Privacidad'}
    </a>
    {' y la '}
    <a
      tabIndex={-1}
      href='https://latasa.mx/Docs/politicas_cookies_latasa.pdf'
      target='_blank'
      rel='noopener noreferrer'
      className='color-blue'
    >
      Política de Cookies
    </a>
  </p>
)
class SignUpForm extends Component {
  state = {
    password: ''
  }

  onPaswordChange = (password, id) => {
    this.setState({ password }, () => this.handleChange(password, id))
  }

  // Sets the value in the formik form
  handleChange = (value, id) => {
    const { setFieldTouched, setFieldValue } = this.props
    setFieldValue(id, value)
    setFieldTouched(id)
  }

  getError = field => {
    const { errors, touched } = this.props
    return touched[field] ? errors[field] : ''
  }

  render () {
    const { password } = this.state
    const { isValid, handleSubmit, isSubmitting } = this.props
    return (
      <form onSubmit={isSubmitting ? cancelEvent : handleSubmit}>
        <Input
          className='margin-bottom-small'
          label='Correo electrónico *'
          onChange={this.handleChange}
          id='email'
          invalidMessage={this.getError('email')}
          type='email'
        />
        <Input
          className='margin-bottom-small'
          label='Contraseña *'
          type='password'
          showPasswordToggle
          id='password'
          onChange={this.onPaswordChange}
          invalidMessage={this.getError('password')}
        />
        <PasswordValidator password={password} />
        <p className='margin-bottom-medium text-small'>*Campos obligatorios</p>
        <Checkbox
          className='margin-bottom'
          id='termsAndConditions'
          label={checkoxLabel}
          onChange={this.handleChange}
        />
        <Button
          theme='primary'
          type='submit'
          isLoading={isSubmitting}
          disabled={!isValid}
        >
          Crear mi cuenta
        </Button>
      </form>
    )
  }
}

// validate function called before handle submit
const validate = values => {
  const { termsAndConditions, email, password } = values
  // Create an errors object
  const errors = {
    termsAndConditions: validateInput(
      // Force a truthy value
      termsAndConditions || '',
      [validateNullField('Debes aceptar los términos y condiciones.')]
    ),
    email: validateInput(email, [
      validateNullField(
        genericValidationMessage('tu dirección de correo electrónico')
      ),
      validateEmail(
        'Ingresa una dirección de correo electrónico válida, ejemplo: nombre@mail.com'
      )
    ]),
    password: validateInput(password, [
      validateNullField(genericValidationMessage('tu contraseña')),
      validatePassword(
        'Tu contraseña debe incluir al menos 8 caracteres alfanuméricos, 1 letra y 1 número.'
      )
    ])
  }

  return cleanObject(errors)
}

const handleSubmit = async (values, { setSubmitting, props, setStatus }) => {
  try {
    const {
      data: {
        data: {
          token,
          opportunity: {
            id,
            user: { id: userId }
          }
        }
      }
    } = await API.Opportunities.SignUp(
      getSignUpRequest(values, props.investment)
    )
    setStatus({ success: true })
    props.createSession({ email: values.email, token, id, userId })
  } catch (error) {
    const { response } = error
    if (response && response.data && response.data.message) {
      notifyError(response.data.message)
    } else {
      notifyError('Ocurrió un error, intenta de nuevo más tarde')
    }
    console.error(error)
    setStatus({ success: false })
    // Only setSubmitting false when the sign up failed, beacuse when not, it will redirect to another page
    setSubmitting(false)
  }
}

SignUpForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    termsAndConditions: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string
  }).isRequired,
  touched: PropTypes.shape({
    termsAndConditions: PropTypes.bool,
    email: PropTypes.bool,
    password: PropTypes.bool
  }).isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  investment: PropTypes.shape({
    initialInvestment: PropTypes.string.isRequired,
    monthlyInvestment: PropTypes.string.isRequired,
    yearsOfInvestment: PropTypes.string.isRequired
  }).isRequired
}

export default withFormik({
  validate,
  handleSubmit,
  // Avoid taking props as values
  mapPropsToValues: () => {}
})(SignUpForm)
