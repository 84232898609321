// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'

// Components
import Icon from 'shared/icon'
import { fileToBase64, toSnakeCase } from 'utils'

import styles from './fileUploader.module.scss'

const defaultState = {
  isLoading: false,
  fileName: '',
  size: 0,
  value: '',
  contentType: ''
}

/**
 * @typedef {Object} Props
 * @property {string} className
 * @property {string} id
 * @property {string} label
 * @property {string | string[]} accept A collection of accepted MIME types
 * @property {function} onChange
 * @property {string} invalidMessage
 * @property {string} uploadedFile Name of a uploaded file to show in the component
 *
 * @typedef {Object} State
 * @property {boolean} isLoading
 * @property {string} fileName
 * @property {number} size
 * @property {string} value the file encoding in base64
 * @property {string} contentType the file MIME type
 */

/**
 * @extends {Component<Props, State>}
 */
class FileUploader extends Component {
  state = {
    ...defaultState
  }

  componentDidMount () {
    const { uploadedFile } = this.props
    if (uploadedFile) {
      this.notifyChange()
    }
  }

  prepareFile = async file => {
    try {
      const { fileName, base64, size, contentType } = await fileToBase64(file)
      this.setState(
        {
          isLoading: false,
          fileName: toSnakeCase(fileName),
          value: base64,
          size,
          contentType
        },
        this.notifyChange
      )
    } catch (error) {
      console.error(error)
      this.setState({ ...defaultState })
    }
  }

  /**
   * Call the onChange handler with the fileName, the encoded file, the size, contentType and id
   */
  notifyChange = () => {
    const { id, onChange, uploadedFile } = this.props
    const { fileName, value, size, contentType } = this.state
    onChange(
      {
        fileName: fileName || uploadedFile,
        value,
        size,
        contentType
      },
      id
    )
  }

  /**
   * Listens to the onDrop event, it calls the process encode the file
   */
  handleDropFiles = dropFiles => {
    const [target] = dropFiles
    // Reset the file and set the loading flag
    this.setState({ ...defaultState, isLoading: true }, this.notifyChange)
    this.prepareFile(target)
  }

  render () {
    const {
      className,
      label,
      id,
      accept,
      invalidMessage,
      uploadedFile
    } = this.props
    const { isLoading, fileName } = this.state
    const inputProps = id ? { id, 'data-testid': id } : {}
    return (
      <div className={className}>
        <Dropzone
          className={classNames(styles.file_uploader, 'focusable', {
            [styles.invalid]: invalidMessage
          })}
          inputProps={inputProps}
          accept={accept}
          multiple={false}
          onDrop={this.handleDropFiles}
        >
          <div className='row'>
            <div className='col-xs-12 start-xs color-dark-blue'>{label}</div>
            <div className='col-xs-12 start-xs text-smallest'>
              {isLoading ? 'Cargando...' : fileName || uploadedFile}
            </div>
          </div>
          <Icon
            className={classNames(styles.icon, 'color-sky-blue')}
            size='small'
            icon='folder-upload'
          />
        </Dropzone>
        <p className='color-error text-small start-xs'>{invalidMessage}</p>
      </div>
    )
  }
}

FileUploader.defaultProps = {
  className: '',
  label: '',
  accept: ['image/png', 'image/jpeg', 'application/pdf'],
  invalidMessage: '',
  uploadedFile: ''
}

FileUploader.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
  accept: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  invalidMessage: PropTypes.string,
  uploadedFile: PropTypes.string
}

export default FileUploader
