// Libraries
import { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import WizardStepper from './wizardStepper'

const validateSteps = (prevSteps, nextSteps) => {
  // Validates that all the steps have the same id and label, if something change it should return false
  prevSteps.every(step =>
    nextSteps.find(
      nextStep => nextStep.id === step.id && nextStep.label === step.label
    )
  )
}

class Wizard extends Component {
  static Stepper = WizardStepper

  constructor (props) {
    super(props)
    this.state = {
      // add the index as the step id
      steps: props.steps.map((step, index) => ({ ...step, id: index })),
      currentStep: props.currentStep,
      totalSteps: props.steps.length
    }
  }

  goNext = () => {
    // Advance 1 step if possible
    this.setState(({ currentStep, totalSteps }) => ({
      currentStep: currentStep >= totalSteps - 1 ? currentStep : currentStep + 1
    }))
  }

  goBack = () => {
    // Return 1 step if possible
    this.setState(({ currentStep }) => ({
      currentStep: currentStep <= 0 ? 0 : currentStep - 1
    }))
  }

  render () {
    const { children } = this.props
    return children({
      ...this.state,
      goNext: this.goNext,
      goBack: this.goBack
    })
  }
}

Wizard.defaultProps = {
  steps: [],
  currentStep: 0
}

Wizard.propTypes = {
  children: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    })
  ),
  currentStep: PropTypes.number
}

export default Wizard
