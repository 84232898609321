// Libraries
import React, { Component } from 'react'
import { withFormik } from 'formik'
import PropTypes from 'prop-types'

// Components
import Button from 'shared/button'
import Input from 'shared/input'
import API from 'api'
import { notifyError } from 'utils/notifications'
import { cleanObject, getResponseError } from 'utils'
import {
  validateNullField,
  genericValidationMessage,
  validateInput,
  validatePassword,
  isEqual
} from 'utils/formValidations'
import PasswordValidator from 'views/signUpView/signUpForm/passwordValidator'
import { cancelEvent } from 'utils/events'

class ResetPasswordForm extends Component {
  componentDidUpdate (prevProps) {
    const { status, onSuccess } = this.props
    if (status.success && !prevProps.status.success) {
      // If the form has succeded notify the parent
      onSuccess()
    }
  }
  // Sets the value in the formik form
  handleChange = (value, id) => {
    const { setFieldTouched, setFieldValue } = this.props
    setFieldValue(id, value)
    setFieldTouched(id)
  }

  getError = field => {
    const { errors, touched, submitCount } = this.props
    return submitCount > 0 || touched[field] ? errors[field] : ''
  }

  render () {
    const {
      handleSubmit,
      isSubmitting,
      values: { password = '' }
    } = this.props
    return (
      <React.Fragment>
        <form
          onSubmit={isSubmitting ? cancelEvent : handleSubmit}
          className='start-xs'
        >
          <Input
            className='margin-bottom-small'
            label='Contraseña *'
            type='password'
            showPasswordToggle
            id='password'
            onChange={this.handleChange}
            invalidMessage={this.getError('password')}
          />
          <Input
            className='margin-bottom-small'
            label='Confirmar contraseña *'
            type='password'
            showPasswordToggle
            id='passwordConfirm'
            onChange={this.handleChange}
            invalidMessage={this.getError('passwordConfirm')}
          />
          <PasswordValidator password={password} />
          <p className='text-smallest start-xs color-dark-gray margin-bottom'>
            * Campos obligatorios
          </p>
          <Button
            className='margin-bottom-small'
            theme='primary'
            type='submit'
            isLoading={isSubmitting}
          >
            Aceptar
          </Button>
        </form>
      </React.Fragment>
    )
  }
}

// validate function called before handle submit
const validate = values => {
  const { password, passwordConfirm } = values
  // Create an errors object
  const errors = {
    password: validateInput(password, [
      validateNullField(genericValidationMessage('tu contraseña')),
      validatePassword(
        'Tu contraseña debe incluir al menos 8 caracteres alfanuméricos, 1 letra y 1 número.'
      )
    ]),
    passwordConfirm: validateInput(passwordConfirm, [
      validateNullField(
        genericValidationMessage('la confirmación de tu contraseña')
      ),
      isEqual(password, 'Las contraseñas no coinciden.')
    ])
  }

  return cleanObject(errors)
}

const handleSubmit = async (
  { password, passwordConfirm },
  { setSubmitting, props: { token }, setStatus }
) => {
  try {
    await API.Opportunities.ResetPassword(token, {
      password,
      password_confirmation: passwordConfirm
    })
    setStatus({ success: true })
  } catch (e) {
    const error = getResponseError(e)
    if (error) {
      notifyError(error)
    } else {
      notifyError('Ocurrió un error, intenta de nuevo más tarde')
    }
    console.error(e)
    // Only setSubmitting false when the sign up failed, beacuse when not, it will redirect to another page
    setSubmitting(false)
  }
}

ResetPasswordForm.defaultProps = {
  status: {},
  errors: {},
  touched: {},
  values: {}
}

ResetPasswordForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  errors: PropTypes.shape({
    password: PropTypes.string,
    passwordConfirm: PropTypes.string
  }),
  touched: PropTypes.shape({
    password: PropTypes.bool,
    passwordConfirm: PropTypes.bool
  }),
  values: PropTypes.shape({
    password: PropTypes.string,
    passwordConfirm: PropTypes.string
  }),
  isSubmitting: PropTypes.bool.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  status: PropTypes.shape({
    success: PropTypes.bool
  }),
  onSuccess: PropTypes.func.isRequired
}

export default withFormik({
  validate,
  handleSubmit,
  // Avoid taking props as values
  mapPropsToValues: () => {}
})(ResetPasswordForm)
