// Libraries
import React, { Component } from 'react'

// Components
import PublicPage from 'shared/publicPage'
import DocumentTitle from 'shared/documentTitle'
import RecoverPasswordForm from './recoverPasswordForm'

class RecoverPasswordView extends Component {
  state = {
    success: false,
    email: ''
  }
  handleOnSuccess = email => {
    this.setState({
      success: true,
      email
    })
  }
  render () {
    const { success, email } = this.state
    const title = success ? '¡Listo!' : 'Recupera tu contraseña'
    return (
      <PublicPage>
        <DocumentTitle title={title} />
        <h1 className='color-dark-blue margin-bottom-small text-bold text-uppercase'>
          {title}
        </h1>
        {success ? (
          <React.Fragment>
            <h3 className='margin-bottom-large text-light'>{`Hemos enviado un correo a ${email} con las intrucciones para poder reestablecer tu contraseña.`}</h3>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h3 className='margin-bottom-large text-light'>
              Es necesario que ingreses el correo con el que te registrarte para
              mandarte instrucciones sobre cómo reestablecer tu contraseña.
            </h3>
            <RecoverPasswordForm onSuccess={this.handleOnSuccess} />
          </React.Fragment>
        )}
      </PublicPage>
    )
  }
}

export default RecoverPasswordView
