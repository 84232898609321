const AUTH_STORE_KEY = 'auth'

class AuthStore {
  static getSession () {
    return JSON.parse(window.localStorage.getItem(AUTH_STORE_KEY))
  }
  static setSession (session) {
    window.localStorage.setItem(AUTH_STORE_KEY, JSON.stringify(session))
  }
  static clear () {
    window.localStorage.removeItem(AUTH_STORE_KEY)
  }
}

export default AuthStore
