import { sortByKey } from 'utils'
/**
 * @typedef {Object} CatalogItem
 * @property {string | number | boolean} value
 * @property {string} label
 */

/**
 * @typedef {CatalogItem & Object} StateItem
 * @property {string} code // Code used to calculate the curp
 */

/**
 * List of available states for the local country
 * Used for the statePlace select from local opportunities
 * @type {StateItem[]}
 */
export const MEX_STATES = [
  { value: 'AGUASCALIENTES', label: 'Aguascalientes', code: 'AS' },
  { value: 'BAJA CALIFORNIA', label: 'Baja California', code: 'BC' },
  { value: 'BAJA CALIFORNIA SUR', label: 'Baja California Sur', code: 'BS' },
  { value: 'CAMPECHE', label: 'Campeche', code: 'CC' },
  { value: 'COAHUILA', label: 'Coahuila', code: 'CL' },
  { value: 'COLIMA', label: 'Colima', code: 'CM' },
  { value: 'CHIAPAS', label: 'Chiapas', code: 'CS' },
  { value: 'CHIHUAHUA', label: 'Chihuahua', code: 'CH' },
  { value: 'CIUDAD DE MÉXICO', label: 'Ciudad de México', code: 'DF' },
  { value: 'DURANGO', label: 'Durango', code: 'DG' },
  { value: 'GUANAJUATO', label: 'Guanajuato', code: 'GT' },
  { value: 'GUERRERO', label: 'Guerrero', code: 'GR' },
  { value: 'HIDALGO', label: 'Hidalgo', code: 'HG' },
  { value: 'JALISCO', label: 'Jalisco', code: 'JC' },
  { value: 'ESTADO DE MEXICO', label: 'Estado de México', code: 'MC' },
  { value: 'MICHOACAN', label: 'Michoacán', code: 'MN' },
  { value: 'MORELOS', label: 'Morelos', code: 'MS' },
  { value: 'NAYARIT', label: 'Nayarit', code: 'NT' },
  { value: 'NUEVO LEON', label: 'Nuevo León', code: 'NL' },
  { value: 'OAXACA', label: 'Oaxaca', code: 'OC' },
  { value: 'PUEBLA', label: 'Puebla', code: 'PL' },
  { value: 'QUERETARO', label: 'Querétaro', code: 'QT' },
  { value: 'QUINTANA ROO', label: 'Quintana Roo', code: 'QR' },
  { value: 'SAN LUIS POTOSI', label: 'San Luis Potosí', code: 'SP' },
  { value: 'SINALOA', label: 'Sinaloa', code: 'SL' },
  { value: 'SONORA', label: 'Sonora', code: 'SR' },
  { value: 'TABASCO', label: 'Tabasco', code: 'TC' },
  { value: 'TAMAULIPAS', label: 'Tamaulipas', code: 'TS' },
  { value: 'TLAXCALA', label: 'Tlaxcala', code: 'TL' },
  { value: 'VERACRUZ', label: 'Veracruz', code: 'VZ' },
  { value: 'YUCATAN', label: 'Yucatán', code: 'YN' },
  { value: 'ZACATECAS', label: 'Zacatecas', code: 'ZS' }
]

/**
 * The name of the local country
 * @type {string}
 */
export const LOCAL_COUNTRY = 'Mexico'

/**
 * Available countries which can be used for the birth place options
 * @type {CatalogItem[]}
 */
export const COUNTRIES = [
  { value: LOCAL_COUNTRY, label: 'México' },
  { value: 'Other', label: 'Otro' }
]

/**
 * Available genders used in the personal data and beneficiary selects
 * @type {CatalogItem[]}
 */
export const GENDER_OPTIONS = [
  {
    value: 'male',
    label: 'Masculino'
  },
  {
    value: 'female',
    label: 'Femenino'
  }
]

/**
 * Available options for work activities select
 * @type {CatalogItem[]}
 */
export const WORK_ACTIVITY_OPTIONS = [
  {
    value: 'business_owner',
    label: 'Comerciante'
  },
  {
    value: 'employee',
    label: 'Empleado'
  },
  {
    value: 'retired',
    label: 'Jubilado'
  },
  {
    value: 'other',
    label: 'Otro'
  }
]

/**
 * List of months formatted to be used in a select
 * @type {CatalogItem[]}
 */
export const MONTH_OPTIONS = [
  {
    value: 1,
    label: 'Enero'
  },
  {
    value: 2,
    label: 'Febrero'
  },
  {
    value: 3,
    label: 'Marzo'
  },
  {
    value: 4,
    label: 'Abril'
  },
  {
    value: 5,
    label: 'Mayo'
  },
  {
    value: 6,
    label: 'Junio'
  },
  {
    value: 7,
    label: 'Julio'
  },
  {
    value: 8,
    label: 'Agosto'
  },
  {
    value: 9,
    label: 'Septiembre'
  },
  {
    value: 10,
    label: 'Octubre'
  },
  {
    value: 11,
    label: 'Noviembre'
  },
  {
    value: 12,
    label: 'Diciembre'
  }
]

/**
 * A collection with yes/no response with boolean values
 * It can be used in a select or in an option switch component
 * @type {CatalogItem[]}
 */
export const YES_NO_OPTIONS = [
  {
    value: true,
    label: 'Sí'
  },
  {
    value: false,
    label: 'No'
  }
]

/**
 * Available educational level options for being used in a select
 * @type {CatalogItem[]}
 */
export const EDUCATION_LEVEL_OPTIONS = [
  {
    value: 'elementary_school',
    label: 'Básico'
  },
  {
    value: 'middle_school',
    label: 'Secundaria'
  },
  {
    value: 'high_school',
    label: 'Preparatoria'
  },
  {
    value: 'bachelor_degree',
    label: 'Licenciatura'
  },
  {
    value: 'master_degree',
    label: 'Maestría'
  },
  {
    value: 'doctor_degree',
    label: 'Doctorado'
  }
]

/**
 * Available marital status for the opportunities
 * @type {CatalogItem[]}}
 */
export const MARITAL_STATUS_OPTIONS = [
  {
    value: 'single',
    label: 'Soltero'
  },
  {
    value: 'married',
    label: 'Casado'
  },
  {
    value: 'divorced',
    label: 'Divorciado'
  },
  {
    value: 'widowed',
    label: 'Viudo'
  }
]

/**
 * Available investment source (where the money comes from)
 * @type {CatalogItem[]}
 */
export const INVESTMENT_SOURCE_OPTIONS = [
  {
    value: 'salary',
    label: 'Sueldos y salarios'
  },
  {
    value: 'business',
    label: 'Ingresos de mi negocio'
  },
  {
    value: 'savings',
    label: 'Ahorro e inversión'
  },
  {
    value: 'other',
    label: 'Otro'
  }
]

/**
 * List of average amount (how much money will have each month)
 * @type {CatalogItem[]}
 */
export const MONTHLY_AVERAGE_AMOUNT_OPTIONS = [
  {
    value: 'less_than_25000',
    label: 'Menos de $25,000'
  },
  {
    value: 'from_25001_to_50000',
    label: 'De $25,001 a $50,000'
  },
  {
    value: 'from_50001_to_100000',
    label: 'De $50,001 a $100,000'
  },
  {
    value: 'from_100001_to_200000',
    label: 'De $100,001 a $200,000'
  },
  {
    value: 'from_200001_to_350000',
    label: 'De $200,001 a $350,000'
  },
  {
    value: 'from_350001_to_500000',
    label: 'De $350,001 a $500,000'
  },
  {
    value: 'from_500001_to_1000000',
    label: 'De $500,001 a $1,000,000'
  },
  {
    value: 'more_than_1000000',
    label: 'Más de $1,000,000'
  }
]

/**
 * Available relationships that a beneficiary can have
 * @type {CatalogItem[]}
 */
export const RELATIONSHIP_OPTIONS = [
  {
    value: 'grandparent',
    label: 'Abuelo (a)'
  },
  {
    value: 'parent',
    label: 'Padre o madre'
  },
  {
    value: 'couple',
    label: 'Cónyuge'
  },
  {
    value: 'child',
    label: 'Hijo (a)'
  },
  {
    value: 'sibling',
    label: 'Hermano (a)'
  },
  {
    value: 'other',
    label: 'Otro (a)'
  }
]

/**
 * Passport document
 * @type {string}
 */
export const DOCUMENT_TYPE_PASSPORT = 'passport'

/**
 * Personal identification document
 * @type {string}
 */
export const DOCUMENT_TYPE_VOTER_CARD = 'voter_card'

/**
 * Available documents which can be used as an official identification
 * @type {CatalogItem[]}
 */
export const DOCUMENT_TYPE_OPTIONS = [
  {
    value: DOCUMENT_TYPE_VOTER_CARD,
    label: 'IFE / INE'
  },
  {
    value: DOCUMENT_TYPE_PASSPORT,
    label: 'Pasaporte'
  }
]

/**
 * @constant
 */
export const BANK_ACCOUNT_TYPE_CLABE = 'clabe'

/**
 * @constant
 */
export const BANK_ACCOUNT_TYPE_DEBIT_CARD = 'debit_card'

/**
 * Available bank account types which can be used for the initial investment
 * @type {CatalogItem[]}
 */
export const BANK_ACCOUNT_TYPE_OPTIONS = [
  {
    value: BANK_ACCOUNT_TYPE_CLABE,
    label: 'CLABE'
  },
  {
    value: BANK_ACCOUNT_TYPE_DEBIT_CARD,
    label: 'Tarjeta de debito'
  }
]

/**
 * List of available bank institutions ordered by name
 * Useful to be used in the investment plan form
 * @type {CatalogItem[]}
 */
export const BANK_OPTIONS = sortByKey(
  [
    {
      value: 'BANAMEX',
      label: 'Banamex'
    },
    {
      value: 'BBVA BANCOMER',
      label: 'BBVA Bancomer'
    },
    {
      value: 'HSBC',
      label: 'HSBC'
    },
    {
      value: 'SANTANDER',
      label: 'Santander'
    },
    {
      value: 'BAJIO',
      label: 'Banco del Bajío'
    },
    {
      value: 'INBURSA',
      label: 'Inbursa'
    },
    {
      value: 'INTERACCIONES',
      label: 'Interacciones'
    },
    {
      value: 'MIFEL',
      label: 'Banca MIFEL'
    },
    {
      value: 'SCOTIABANK INVERLAT',
      label: 'Scotiabank'
    },
    {
      value: 'BANREGIO',
      label: 'Banregio'
    },
    {
      value: 'INVEX',
      label: 'Invex'
    },
    {
      value: 'AFIRME',
      label: 'AFIRME'
    },
    {
      value: 'BANORTE IXE',
      label: 'Banorte'
    },
    {
      value: 'AZTECA',
      label: 'Banco Azteca'
    },
    {
      value: 'FAMSA',
      label: 'Famsa'
    },
    {
      value: 'MULTIVA',
      label: 'Multiva'
    },
    {
      value: 'INTERCAM',
      label: 'Intercam'
    },
    {
      value: 'BANCOPPEL',
      label: 'BanCoppel'
    },
    {
      value: 'CIBANCO',
      label: 'CI Banco'
    },
    {
      value: 'BANJERCITO',
      label: 'Banjercito'
    },
    {
      value: 'BANK OF AMERICA (MEXICO)',
      label: 'Bank Of America México'
    }
  ],
  'label'
)

/**
 * THe size of a megabyte in bytes
 * @type {number}
 */
export const MB_IN_BYTES = 1048576
