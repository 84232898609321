// Libraries
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'

// Components
import PublicPage from 'shared/publicPage'
import DocumentTitle from 'shared/documentTitle'
import SignUpForm from './signUpForm'
import { BASE_ROUTE, SIGN_UP_ROUTE } from 'routes/publicRoutes'
import { AuthConsumer } from 'contexts/auth'

class SignUpView extends Component {
  state = {
    investment: {}
  }

  componentDidMount () {
    const { location, history } = this.props
    if (location && location.search) {
      const investment = queryString.parse(location.search)
      // Is a valid investment object
      if (
        investment.initialInvestment &&
        investment.monthlyInvestment &&
        investment.yearsOfInvestment
      ) {
        this.setState({ investment })
        // Reset the search
        history.replace(SIGN_UP_ROUTE)
      } else {
        history.replace(BASE_ROUTE)
      }
    } else {
      history.replace(BASE_ROUTE)
    }
  }

  render () {
    const { investment } = this.state
    return (
      <PublicPage>
        <DocumentTitle title='Crear mi cuenta' />
        <h1 className='color-dark-blue margin-bottom-small text-bold text-uppercase'>
          ¿Cómo empezar a invertir?
        </h1>
        <h3 className='margin-bottom-large text-light'>
          El primer paso es crear tu cuenta en La Tasa, con ella tendrás control
          sobre tu inversión y podrás monitorear tus rendimientos.
        </h3>
        <div className='start-xs'>
          <AuthConsumer>
            {({ createSession }) => (
              <SignUpForm
                investment={investment}
                createSession={createSession}
              />
            )}
          </AuthConsumer>
        </div>
      </PublicPage>
    )
  }
}

SignUpView.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired
}

export default withRouter(SignUpView)
