import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

// Components
import BeneficiaryPercentageSelector from './beneficiaryPercentageSelector'

class BeneficiariesPercentageSelector extends PureComponent {
  render () {
    const {
      beneficiariesArray,
      onDelete,
      values,
      onChange,
      errors,
      touched,
      submitCount
    } = this.props
    const error = errors.totalPercentages
    const hasError = !!error
    return (
      <Fragment>
        <p className='color-dark-blue margin-bottom-small start-xs text-uppercase text-smallest'>
          ¿Qué porcentaje deseas asignar a cada uno de tus beneficiarios? *
        </p>
        {beneficiariesArray.map((beneficiary, index) => {
          const beneficiaryValues =
            values.beneficiaries && values.beneficiaries[index]
              ? values.beneficiaries[index]
              : {}

          const beneficiaryErrors =
            errors.beneficiaries && errors.beneficiaries[index]
              ? errors.beneficiaries[index]
              : {}

          const beficiaryTouched =
            touched.beneficiaries && touched.beneficiaries[index]
              ? touched.beneficiaries[index]
              : {}

          return (
            <BeneficiaryPercentageSelector
              key={beneficiary.id}
              name={beneficiaryValues.firstName}
              lastName={beneficiaryValues.lastName}
              onDelete={onDelete}
              beneficiaryNumber={index + 1}
              onChange={onChange}
              errors={beneficiaryErrors}
              touched={beficiaryTouched}
              submitCount={submitCount}
              isInvalid={hasError}
              defaultValues={beneficiary}
              {...beneficiary}
            />
          )
        })}
        {error && (
          <p className='color-error text-small start-xs margin-bottom-small'>
            {error}
          </p>
        )}
      </Fragment>
    )
  }
}

BeneficiariesPercentageSelector.propTypes = {
  beneficiariesArray: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      defaultValues: PropTypes.object // eslint-disable-line react/forbid-prop-types
    })
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
}

export default BeneficiariesPercentageSelector
