// Libraries
import React, { Component } from 'react'

// Components
import PublicPage from 'shared/publicPage'
import Icon from 'shared/icon'
import DocumentTitle from 'shared/documentTitle'

class LoadingView extends Component {
  render () {
    return (
      <PublicPage>
        <DocumentTitle />
        <div className='row center-xs middle-xs'>
          <Icon size='large' icon='loading' />
        </div>
      </PublicPage>
    )
  }
}

export default LoadingView
