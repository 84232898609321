import InvestmentPlanForm from './startToInvestForm/investmentPlanForm'
import BeneficiariesForm from './startToInvestForm/beneficiariesForm'
import DocumentationForm from './startToInvestForm/documentationForm'

export const FORM_STEPS = [
  {
    label: 'Documentación',
    route: 'documentacion',
    component: DocumentationForm,
    id: 'documentation'
  },
  {
    label: 'Beneficiario (s)',
    route: 'beneficiarios',
    component: BeneficiariesForm,
    id: 'beneficiaries'
  },
  {
    label: 'Plan de Inversión',
    route: 'plan-de-inversion',
    component: InvestmentPlanForm,
    id: 'investment-plan'
  }
]
