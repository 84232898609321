import React from 'react'
import ReactDOM from 'react-dom'
import './css/index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

// Manages S3 redirect, since in the redirection rule the url will contains a hash before the whole route
const path = window.location.hash.slice(1)
if (path) {
  window.location.hash = ''
  window.history.replaceState({}, '', `${process.env.PUBLIC_URL}/${path}`)
}
ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
