// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'

// Components
import Button from 'shared/button'
import {
  DOCUMENT_TYPE_OPTIONS,
  DOCUMENT_TYPE_VOTER_CARD,
  DOCUMENT_TYPE_PASSPORT,
  MB_IN_BYTES
} from 'shared/catalogs'
import {
  validateNullSelect,
  validateInput,
  validateComposedObject,
  validateMaxValue
} from 'utils/formValidations'
import { cleanObject, objectIsEmpty } from 'utils'
import { getDocumentationArray } from './../utils'
import API from 'api'
import { notifyError } from 'utils/notifications'
import OptionSwitch from 'shared/optionSwitch'
import { DOCUMENT_TYPE_COMPONENTS } from './constants'
import { cancelEvent } from 'utils/events'

class DocuentationForm extends Component {
  state = {
    documentType: ''
  }

  handleDocumentTypeChange = (value, id) => {
    const { resetForm } = this.props
    resetForm()
    this.setState({ documentType: value }, () => this.handleChange(value, id))
  }

  // Sets the value in the formik form
  handleChange = (value, id) => {
    const { setFieldTouched, setFieldValue } = this.props
    setFieldTouched(id)
    setFieldValue(id, value)
  }

  getError = field => {
    const { errors, touched, submitCount } = this.props
    // If submitted or field touched, return field error
    return submitCount > 0 || touched[field] ? errors[field] : ''
  }

  render () {
    const { handleSubmit, defaultValues, isSubmitting } = this.props
    const { documentType } = this.state
    let button_msg = 'Continuar'
    if (window.location.pathname === '/correccion-documentacion') {
      button_msg = 'Actualizar'
    }
    const DocumentComponent =
      documentType && DOCUMENT_TYPE_COMPONENTS[documentType]
    return (
      <form onSubmit={isSubmitting ? cancelEvent : handleSubmit}>
        <h4 className='start-xs text-uppercase color-sky-blue margin-bottom-small'>
          Documentación
        </h4>

        <p className='start-xs margin-bottom-small'>
          Recuerda que tu documentación estará segura, únicamente la
          utilizaremos para generar tu contrato.
        </p>

        <OptionSwitch
          className='margin-bottom-small'
          label={
            <p className='color-dark-blue start-xs text-uppercase text-smallest'>
              Identificación oficial *
            </p>
          }
          onChange={this.handleDocumentTypeChange}
          id='documentType'
          options={DOCUMENT_TYPE_OPTIONS}
          optionClassName='color-dark-blue full-width'
          wrap={false}
          invalidMessage={this.getError('documentType')}
          defaultValue={defaultValues.documentType}
        />
        {DocumentComponent && (
          <DocumentComponent onChange={this.handleChange} {...this.props} />
        )}

        <p className='text-smallest start-xs color-dark-gray margin-bottom'>
          * Campos obligatorios
        </p>
        <Button isLoading={isSubmitting} theme='primary' type='submit'>
          {button_msg}
        </Button>
      </form>
    )
  }
}

DocuentationForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  goNext: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValues: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

// validate function called before handle submit
const validate = (values, { defaultValues }) => {
  // Create an errors object with built with the form form validations
  const errors = {
    documentType: validateNullSelect(values.documentType)
  }

  // If votercard selected, validate those fields
  const voterCardErrors =
    values.documentType === DOCUMENT_TYPE_VOTER_CARD
      ? validateVoterCard(values, defaultValues)
      : {}
  // If passport selected, validate those fields
  const passportErrors =
    values.documentType === DOCUMENT_TYPE_PASSPORT
      ? validatePassport(values, defaultValues)
      : {}

  // Remove the empty keys and return the errors object
  return cleanObject({ ...errors, ...voterCardErrors, ...passportErrors })
}

/**
 * Validates the voter card form, each file if it has been changed or not
 * @param {Object} values the form values
 * @param {Object} defaultValues the default values from the props
 */
const validateVoterCard = (
  values,
  { voterCardFront, voterCardBack, proofOfAddress }
) => ({
  isAddressTheSame: validateNullSelect(values.isAddressTheSame),
  voterCardFront: hasTheFileChanged(values.voterCardFront, voterCardFront)
    ? validateFile(values, 'voterCardFront')
    : '',
  voterCardBack: hasTheFileChanged(values.voterCardBack, voterCardBack)
    ? validateFile(values, 'voterCardBack')
    : '',
  proofOfAddress: !values.isAddressTheSame
    ? hasTheFileChanged(values.proofOfAddress, proofOfAddress)
      ? validateFile(values, 'proofOfAddress')
      : ''
    : ''
})

/**
 * Validates that the file has been change from its default
 * @param {object} value the actual file valu
 * @param {string} defaultValue loaded default value
 */
const hasTheFileChanged = (value, defaultValue) =>
  defaultValue ? !value || value.fileName === defaultValue : true

/**
 * Validates the voter card form, each file if it has been changed or not
 * @param {Object} values the form values
 * @param {Object} defaultValues the default values from the props
 */
const validatePassport = (values, { passport, proofOfAddress }) => ({
  passport: hasTheFileChanged(values.passport, passport)
    ? validateFile(values, 'passport')
    : '',
  proofOfAddress: hasTheFileChanged(values.proofOfAddress, proofOfAddress)
    ? validateFile(values, 'proofOfAddress')
    : ''
})

/**
 * Executes a general file validation funtion in this form
 * @param {object} values
 * @param {string} id
 */
const validateFile = (values, id) =>
  values[id] && !objectIsEmpty(values[id])
    ? validateInput(values[id], [
      validateComposedObject({
        size: [
          validateMaxValue(
            10 * MB_IN_BYTES,
            'Tu imagen no debe pesar más de 10MB.'
          )
        ]
      })
    ])
    : 'Por favor selecciona un archivo.'

const handleSubmit = async (
  values,
  { props: { defaultValues, goNext }, setSubmitting }
) => {
  setSubmitting(true)
  try {
    const docPayloads = getDocumentationArray(values)
    for (const docPayload of docPayloads) {
      // only request valid payloads
      if (docPayload) {
        await API.Opportunities.Update(docPayload)
      }
    }
    goNext({
      ...values,
      proofOfAddress: values.proofOfAddress
        ? values.proofOfAddress.fileName
        : defaultValues.proofOfAddress,
      voterCardBack: values.voterCardBack
        ? values.voterCardBack.fileName
        : defaultValues.voterCardBack,
      voterCardFront: values.voterCardFront
        ? values.voterCardFront.fileName
        : defaultValues.voterCardFront,
      passport: values.passport
        ? values.passport.fileName
        : defaultValues.passport
    })
  } catch (e) {
    console.error(e)
    notifyError('Ocurrió un error, intenta de nuevo más tarde.')
    setSubmitting(false)
  }
}

export default withFormik({
  validate,
  handleSubmit,
  // Avoid taking props as values
  mapPropsToValues: () => {},
  validateOnBlur: false
})(DocuentationForm)
