// Libraries
import React from 'react'
import PropTypes from 'prop-types'

const ParametrizedRoute = props => {
  const {
    match: { params },
    component: Component
  } = props

  return <Component {...props} {...params} />
}

ParametrizedRoute.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.object // eslint-disable-line react/forbid-prop-types
  }).isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired
}

export default ParametrizedRoute
