// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Commponents
import { formatMoney } from 'utils'

class InvestmentDetail extends Component {
  render () {
    const { totalInvestment, totalEarnings, finalAmount } = this.props
    return (
      <div className='row'>
        <div className='col col-xs-12 col-sm-4'>
          <p className='text-light'>Inversión</p>
          <p className='color-dark-blue text-bold'>
            {formatMoney(totalInvestment)}
          </p>
        </div>
        <div className='col col-xs-12 col-sm-4'>
          <p className='text-light'>Rendimiento</p>
          <p className='color-dark-blue text-bold'>
            {formatMoney(totalEarnings)}
          </p>
        </div>
        <div className='col col-xs-12 col-sm-4'>
          <p className='text-light'>Total</p>
          <p className='color-dark-blue text-bold'>
            {formatMoney(finalAmount)}
          </p>
        </div>
      </div>
    )
  }
}

InvestmentDetail.defaultProps = {
  totalInvestment: 0,
  totalEarnings: 0,
  finalAmount: 0
}

InvestmentDetail.propTypes = {
  totalInvestment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalEarnings: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  finalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default InvestmentDetail
