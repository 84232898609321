// Libraries
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const ICON_SIZES = {
  tiny: '18px',
  small: '24px',
  medium: '36px',
  large: '48px'
}

/**
 * @typedef {Object} Props
 * @property {string} icon Te mdi icon name to be rendered
 * @property {string} size the size of the icon, it could be tiny, small, medium or large
 * @property {string} className
 * @property {function} onClick
 * @property {function} onMouseDown
 * @property {function} onMouseUp
 * @property {string} tooltip
 * @property {string} id
 */

/**
 *
 * @param {Props} props
 */
const Icon = props => {
  const { icon, tooltip, size, className } = props

  // Remove id from the final props
  const { id, ...finalProps } = props

  // Add the id if exists
  const idProps = id
    ? {
      id,
      'data-testid': id
    }
    : {}
  return (
    <React.Fragment>
      <i
        {...finalProps}
        {...idProps}
        className={classNames(
          'mdi',
          `mdi-${icon}`,
          { [`mdi-${ICON_SIZES[size]}`]: ICON_SIZES[size] },
          className,
          {
            'mdi-spin': icon === 'loading'
          }
        )}
      />
      {tooltip && <div className='tooltip'>{tooltip}</div>}
    </React.Fragment>
  )
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['inherit', 'tiny', 'small', 'medium', 'large']),
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  tooltip: PropTypes.string,
  id: PropTypes.string
}

Icon.defaultProps = {
  size: 'tiny',
  onClick: () => {},
  onMouseDown: () => {},
  onMouseUp: () => {},
  tooltip: '',
  className: '',
  id: ''
}

export default Icon
