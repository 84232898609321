// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Components
import styles from './wizardStepper.module.scss'

const WizardLabel = ({ label, id, isActive, isCompleted }) => (
  <div
    key={id}
    className={classNames(styles.label, {
      [styles.completed]: isCompleted,
      [styles.inactive]: !isActive && !isCompleted,
      [styles.active]: isActive
    })}
  >
    {label}
  </div>
)

WizardLabel.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired
}

export default WizardLabel
