// Libraries
import React, { Component } from 'react'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import queryString from 'query-string'

// Components
import PublicPage from 'shared/publicPage'
import DocumentTitle from 'shared/documentTitle'
import ResetPasswordForm from './resetPasswordForm'
import { RECOVER_PASSWORD_ROUTE, SIGN_IN_ROUTE } from 'routes/publicRoutes'
import LoadingView from 'views/loadingView'
import Button from 'shared/button'

class ResetPasswordView extends Component {
  state = {
    success: false,
    token: ''
  }
  componentDidMount () {
    this.validateToken()
  }

  validateToken = () => {
    const {
      location: { search },
      history
    } = this.props
    if (search) {
      const { token } = queryString.parse(search)
      if (token) {
        this.setState({ token })
        // end the funtion flow to avoid pushing to another route
        return
      }
    }
    history.replace(RECOVER_PASSWORD_ROUTE)
  }

  handleOnSuccess = () => {
    this.setState({
      success: true
    })
  }

  render () {
    const { success, token } = this.state
    if (!token) {
      return <LoadingView />
    }
    const title = success ? '¡Listo!' : 'Reestablece tu contraseña'
    return (
      <PublicPage>
        <DocumentTitle title={title} />
        <h1 className='color-dark-blue margin-bottom-small text-bold text-uppercase'>
          {title}
        </h1>
        {success ? (
          <React.Fragment>
            <h3 className='margin-bottom-large text-light'>
              Ya puedes continuar tu solicitud con el correo y la contraseña que
              acabas de establecer.
            </h3>
            <Link to={SIGN_IN_ROUTE}>
              <Button theme='primary'>Continuar solicitud</Button>
            </Link>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h3 className='margin-bottom-large text-light'>
              Es necesario que ingreses una nueva contraseña para tu cuenta,
              recuerda que debe incluir al menos 8 caracteres alfanuméricos, 1
              letra y 1 número.
            </h3>
            <ResetPasswordForm token={token} onSuccess={this.handleOnSuccess} />
          </React.Fragment>
        )}
      </PublicPage>
    )
  }
}

ResetPasswordView.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired
  }).isRequired
}

export default withRouter(ResetPasswordView)
