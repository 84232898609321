export const LA_TASA_KEY = 'laTasa'
// Elements to be compared in the graphic
export const GRAPHIC_ELEMENTS = [
  {
    id: 'cetes',
    label: 'Cetes',
    labelColor: '#142743',
    gradientColor1: '#00548B',
    gradientColor2: '#142743',
    rate: '7.5'
  },
  {
    id: 'dolar',
    label: 'Dólar',
    labelColor: '#142743',
    gradientColor1: '#00548B',
    gradientColor2: '#142743',
    rate: '5.5'
  },
  {
    id: 'ipc',
    label: 'IPC**',
    labelColor: '#142743',
    gradientColor1: '#00548B',
    gradientColor2: '#142743',
    rate: '5.3'
  },
  {
    id: LA_TASA_KEY,
    label: 'La Tasa',
    labelColor: '#46B0F2',
    gradientColor1: '#7BC6F4',
    gradientColor2: '#46B0F2',
    rate: '16.91'
  }
]
