import {
  DOCUMENT_TYPE_PASSPORT,
  DOCUMENT_TYPE_VOTER_CARD
} from 'shared/catalogs'
import PassportDocuments from './passportDocuments'
import VoterCardDocuments from './voterCardDocuments'

export const DOCUMENT_TYPE_COMPONENTS = {
  [DOCUMENT_TYPE_PASSPORT]: PassportDocuments,
  [DOCUMENT_TYPE_VOTER_CARD]: VoterCardDocuments
}
