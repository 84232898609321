// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

// Components
import styles from './checkbox.module.scss'

class CheckBox extends PureComponent {
  state = {
    checked: false
  }

  // change the checked value and notify the change
  handleToggleChecked = () => {
    this.setState(({ checked }) => ({ checked: !checked }), this.notifyChange)
  }

  handleCheckClick = e => {
    e.preventDefault()
    this.handleToggleChecked()
  }

  // Calls the on change props function with the current value
  notifyChange = () => {
    const { checked } = this.state
    const { onChange, id } = this.props
    onChange(checked, id)
  }

  handleKeyDown = ({ keyCode }) => {
    // change the value if the enter key has been pressed
    if (keyCode === 13) {
      this.handleToggleChecked()
    }
  }

  preventEvent = e => {
    if (e.target.tagName !== 'A') {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  render () {
    const { id, label, className } = this.props
    const { checked } = this.state
    return (
      <div className={`${className} ${styles.checkbox}`}>
        <label htmlFor={id} onClick={this.preventEvent}>
          <input
            id={id}
            type='checkbox'
            onChange={this.handleCheckClick}
            checked={checked}
            tabIndex='-1'
            onClick={this.preventEvent}
            onKeyDown={this.handleKeyDown}
          />
          <span
            className={`focusable ${styles.checkmark} ${
              checked ? styles.checked : ''
            }`}
            tabIndex='0'
            onClick={this.handleCheckClick}
            onKeyDown={this.handleKeyDown}
            role='button'
          />
          <span
            onClick={this.preventEvent}
            onKeyDown={this.preventEvent}
            role='button'
            tabIndex='-1'
          >
            {label}
          </span>
        </label>
      </div>
    )
  }
}

CheckBox.defaultProps = {
  id: '',
  className: '',
  label: ''
}

CheckBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default CheckBox
