// Libraries
import React from 'react'
import PropTypes from 'prop-types'

// Components
import styles from './passwordValidator.module.scss'
import { hasNumbers, hasMinLength, hasLetters } from 'utils/stringValidations'
import Icon from 'shared/icon'

const PasswordValidatorItem = ({ isValid, label }) => (
  <p className={isValid ? 'color-sky-blue' : ''}>
    <Icon icon={isValid ? 'check' : 'minus'} size='inherit' />
    {label}
  </p>
)

PasswordValidatorItem.propTypes = {
  isValid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
}

const PasswordValidator = ({ password }) => (
  <div className={`margin-bottom-small text-small`}>
    <p className={styles.requirement_label}>
      Por tu seguridad incluye al menos
    </p>
    <PasswordValidatorItem
      isValid={hasMinLength(password, 8)}
      label='8 caracteres'
    />
    <PasswordValidatorItem isValid={hasLetters(password)} label='1 letra' />
    <PasswordValidatorItem isValid={hasNumbers(password)} label='1 número' />
  </div>
)

PasswordValidator.propTypes = {
  password: PropTypes.string.isRequired
}

export default PasswordValidator
