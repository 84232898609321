// Libraries
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import queryString from 'query-string'

// Components
import PublicPage from 'shared/publicPage'
import ProfitCalculator from './profitCalculator'
import Button from 'shared/button'
import DocumentTitle from 'shared/documentTitle'
import { SIGN_UP_ROUTE } from 'routes/publicRoutes'

class ProfitCalculatorView extends Component {
  state = {
    investment: undefined,
    calculationMade: false
  }

  onInvestmentChange = investment => {
    this.setState({ investment })
  }

  handleCalculationMade = () => {
    this.setState({ calculationMade: true })
  }

  render () {
    const { investment, calculationMade } = this.state
    // Transform the investment object into a url string
    const searchInvestment = `?${queryString.stringify(investment)}`
    return (
      <PublicPage>
        <DocumentTitle />
        <h1 className='color-dark-blue text-uppercase margin-bottom-small text-bold'>
          Mayores rendimientos
        </h1>
        <h3 className='margin-bottom-large text-light'>
          Éste es tu primer paso para convertirte en inversionista. Calcula tus
          rendimientos en nuestro simulador de inversión.
        </h3>
        <ProfitCalculator
          calculationMade={calculationMade}
          onCalculationMade={this.handleCalculationMade}
          onChange={this.onInvestmentChange}
        />
        <div className={`${calculationMade ? '' : 'hide-on-xs show-on-sm'}`}>
          <br />
          <h2 className='color-dark-blue margin-bottom'>
            Crea tu cuenta y comienza a invertir
          </h2>
          <Link to={`${SIGN_UP_ROUTE}${searchInvestment}`}>
            <Button theme='primary' className='margin-bottom-small'>
              Quiero invertir
            </Button>
          </Link>
          <div className='color-light-gray text-small'>
            <p>
              * Simulación basada en datos históricos, el rendimiento final
              dependerá de la diversificación y plazo de tu inversión.
            </p>
            <p>** IPC: Inversión en la bolsa.</p>
            <br />
            <p>Datos obtenidos al 24 de noviembre de 2017</p>
            <p>
              CETES rendimiento promedio a la fecha, dólar e IPC rendimiento
              promedio en los últimos 10 años, La Tasa rendimiento histórico a
              la fecha.
            </p>
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default withRouter(ProfitCalculatorView)
