import { stripAccents } from 'utils'
import { MEX_STATES } from 'shared/catalogs'

const notAcceptedNames = [
  'MARIA DEL ',
  'MARIA DE LOS ',
  'MARIA ',
  'JOSE DE ',
  'JOSE ',
  'MA. ',
  'MA ',
  'M. ',
  'J. ',
  'J '
]

const prefixes = [
  'DA ',
  'DAS ',
  'DE ',
  'DEL ',
  'DER ',
  'DI ',
  'DIE ',
  'DD ',
  'EL ',
  'LA ',
  'LOS ',
  'LAS ',
  'LE ',
  'LES ',
  'MAC ',
  'MC ',
  'VAN ',
  'VON ',
  'Y '
]

const badWordsList = {
  BACA: 'BXCA',
  LOCO: 'LXCO',
  BAKA: 'BXKA',
  BUEI: 'BXEI',
  BUEY: 'BXEY',
  CACA: 'CXCA',
  CACO: 'CXCO',
  CAGA: 'CXGA',
  CAGO: 'CXGO',
  CAKA: 'CXKA',
  CAKO: 'CXKO',
  COGE: 'CXGE',
  COGI: 'CXGI',
  COJA: 'CXJA',
  COJE: 'CXJE',
  COJI: 'CXJI',
  COJO: 'CXJO',
  COLA: 'CXLA',
  CULO: 'CXLO',
  FALO: 'FXLO',
  FETO: 'FXTO',
  GETA: 'GXTA',
  GUEI: 'GXEI',
  GUEY: 'GXEY',
  JETA: 'JXTA',
  JOTO: 'JXTO',
  KACA: 'KXCA',
  KACO: 'KXCO',
  KAGA: 'KXGA',
  KAGO: 'KXGO',
  KAKA: 'KXKA',
  KAKO: 'KXKO',
  KOGE: 'KXGE',
  KOGI: 'KXGI',
  KOJA: 'KXJA',
  KOJE: 'KXJE',
  KOJI: 'KXJI',
  KOJO: 'KXJO',
  KOLA: 'KXLA',
  KULO: 'KXLO',
  LILO: 'LXLO',
  LOKA: 'LXKA',
  LOKO: 'LXKO',
  MAME: 'MXME',
  MAMO: 'MXMO',
  MEAR: 'MXAR',
  MEAS: 'MXAS',
  MEON: 'MXON',
  MIAR: 'MXAR',
  MION: 'MXON',
  MOCO: 'MXCO',
  MOKO: 'MXKO',
  MULA: 'MXLA',
  MULO: 'MXLO',
  NACA: 'NXCA',
  NACO: 'NXCO',
  PEDA: 'PXDA',
  PEDO: 'PXDO',
  PENE: 'PXNE',
  PIPI: 'PXPI',
  PITO: 'PXTO',
  POPO: 'PXPO',
  PUTA: 'PXTA',
  PUTO: 'PXTO',
  QULO: 'QXLO',
  RATA: 'RXTA',
  ROBA: 'RXBA',
  ROBE: 'RXBE',
  ROBO: 'RXBO',
  RUIN: 'RXIN',
  SENO: 'SXNO',
  TETA: 'TXTA',
  VACA: 'VXCA',
  VAGA: 'VXGA',
  VAGO: 'VXGO',
  VAKA: 'VXKA',
  VUEI: 'VXEI',
  VUEY: 'VXEY',
  WUEI: 'WXEI',
  WUEY: 'WXEY'
}

/**
 * Return the first consonant starting at the second position of the word
 * @param {string} word
 */
const getFirstInternalConsonant = word =>
  word.length === 0
    ? 'X'
    : getFirstLetterBySearch(word.substring(1), '[BCDFGHJKLMNPQRSTVWXYZ]')

/**
 * Return the first vowel starting at the second position of the word
 * @param {string} word
 */

const getFirstInternalVowel = word =>
  word.length === 0 ? 'X' : getFirstLetterBySearch(word.substring(1), '[AEIOU]')

/**
 * Return the first letter of the word that matches the provided search regex
 * @param {string} word
 * @param {string} searchRegex
 */
const getFirstLetterBySearch = (word, searchRegex) => {
  const searchResult = word.match(new RegExp(searchRegex))
  if (!searchResult) return 'X'
  if (searchResult[0]) return searchResult[0]
  return 'X'
}

const cleanString = word =>
  stripAccents(word)
    .trim()
    .toUpperCase()
    .replace(/\s/g, ' ')

/**
 * Remove all the items from the common names list that match in the provided name
 * @param {string} name
 */
const removeCommonNames = name =>
  notAcceptedNames.reduce(
    (acc, notAcceptedName) => acc.replace(new RegExp(notAcceptedName), ''),
    name
  )

/**
 * Remove all the items from the prefixes list that match in the provided name
 * @param {string} name
 */
const removePrefixes = name =>
  prefixes.reduce((acc, prefix) => acc.replace(new RegExp(prefix), ''), name)

/**
 * Search for a bad word, if a bad word is found return the replacement otherwise return the original word
 * @param {string} word
 */
const removeBadWords = word => (badWordsList[word] ? badWordsList[word] : word)

/**
 * Return the code for the provided state
 * @param {string} stateKey
 */
const getBornState = stateKey =>
  MEX_STATES.find(state => state.value === stateKey).code

/**
 * Return the according letter for the provided gender
 * @param {string} gender
 */
const getGenderLetter = gender => (gender === 'male' ? 'H' : 'M')

/**
 * Return the special char according with the born year
 * @param {string} bornYear
 */
const getSpecialChar = bornYear => (bornYear[0] === '1' ? '0' : 'A')

/**
 * Calculate the verifier code with the first 17 characters from the curp
 * @param {string} curp
 */
const getVerifierCode = curp => {
  // Reference array to extract a numeric value for each char of the curp
  const charArray = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'Ñ',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z'
  ]

  // create an array with the numeric values of each char
  const curpArray = curp.split('').map(char => charArray.indexOf(char))

  // calculate the total sum of the values
  const totalSum = curpArray.reduce(
    (acc, current, index) => acc + current * (18 - index),
    0
  )

  // Get the final digit
  const result = 10 - (totalSum % 10)

  return result === 10 ? '0' : result.toString()
}

/**
 * Return the curp according to the provided values
 * @param {string} name
 * @param {string} surnameFather
 * @param {string} surnameMother
 * @param {string} bornDay
 * @param {string} bornMonth
 * @param {string} bornYear
 * @param {string} bornState
 * @param {string} gender
 */
const generateCurp = (
  name,
  surnameFather,
  surnameMother,
  bornDay,
  bornMonth,
  bornYear,
  bornState,
  gender
) => {
  const nameString = `${surnameFather[0] || 'X'}${getFirstInternalVowel(
    surnameFather
  )}${surnameMother[0] || 'X'}${name[0] || 'X'}`

  let curp = removeBadWords(nameString)
  curp += `${bornYear.substring(2)}${bornMonth}${bornDay}`
  curp += getGenderLetter(gender)
  curp += getBornState(bornState)
  curp += getFirstInternalConsonant(surnameFather)
  curp += getFirstInternalConsonant(surnameMother)
  curp += getFirstInternalConsonant(name)
  curp += getSpecialChar(bornYear)
  return `${curp}${getVerifierCode(curp)}`
}

/**
 * @typedef {object} CurpData
 * @property {string} [firstName]
 * @property {string} [secondName]
 * @property {string} [lastName]
 * @property {string} [secondLastName]
 * @property {import('utils').DateObject} [birthdate]
 * @property {string} [statePlace]
 * @property {string} [gender]
 */

/**
 * Return the curp according to the provided opporunitydata
 * @param {CurpData} data
 */
export const getCurp = (data = {}) => {
  const {
    firstName,
    secondName,
    lastName,
    secondLastName,
    birthdate: { year, month, day } = {
      year: undefined,
      month: undefined,
      day: undefined
    },
    statePlace,
    gender
  } = data

  if (
    !firstName ||
    !lastName ||
    !secondLastName ||
    !year ||
    !month ||
    !statePlace ||
    !gender
  ) {
    return ''
  }

  const name = `${firstName} ${secondName ? secondName.trim() : ''}`
  return generateCurp(
    removeCommonNames(cleanString(name)),
    cleanString(removePrefixes(lastName)),
    cleanString(removePrefixes(secondLastName)),
    day,
    month.length === 1 ? `0${month}` : month,
    year,
    statePlace,
    gender
  )
}
