// Libraries
import React from 'react'
import PropTypes from 'prop-types'

// Components
import styles from './pageHeader.module.scss'

const PageHeader = ({ title, subtitle }) => {
  return (
    <div className={styles.page_title}>
      <div className='container jumbo-content center-xs'>
        <h1 className='color-white margin-bottom-small'>{title}</h1>
        <p className='color-white'>{subtitle}</p>
      </div>
    </div>
  )
}

PageHeader.defaultProps = {
  subtitle: ''
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string
}

export default PageHeader
