// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import animateScrollTo from 'animated-scroll-to'

// Components
import QuantitySteper from './quantitySteper'
import PerformanceSimulatorGraph from './performanceSimulatorGraph'
import { getGraphicData } from './utils'
import OptionSwitch from 'shared/optionSwitch'
import InvestmentDetail from './investmentDetail'
import { GRAPHIC_ELEMENTS, LA_TASA_KEY } from './constants'
import Button from 'shared/button'
import styles from './profitCalculator.module.scss'

// Switch option
const yearsOptions = [...Array(5).keys()].map(index => ({
  value: index + 1,
  label: index + 1
}))

class ProfitCalculator extends Component {
  state = {
    initialInvestment: 10000,
    monthlyInvestment: 0,
    yearsOfInvestment: 1
  }

  resultRef = React.createRef()
  calculatorRef = React.createRef()

  componentDidMount () {
    this.notifyChange()
  }

  componentDidUpdate (prevProps) {
    const { calculationMade } = this.props
    // The graph is now showing, scroll to it
    if (!prevProps.calculationMade && calculationMade) {
      this.scrollToResult()
    }
  }

  notifyChange = () => {
    const { onChange } = this.props
    // Notify the change when mounting
    onChange(this.state)
  }

  scrollTo = element => {
    animateScrollTo(element, {
      element: document.getElementById('public-page')
    })
  }

  scrollToResult = () => {
    const { onCalculationMade } = this.props
    onCalculationMade()
    this.scrollTo(this.resultRef.current)
  }

  scrollToCalculator = () => {
    this.scrollTo(this.calculatorRef.current)
  }

  onInitialInvestmentChange = initialInvestment => {
    this.setState({ initialInvestment }, this.notifyChange)
  }

  onMonthlyInvestmentChange = monthlyInvestment => {
    this.setState({ monthlyInvestment }, this.notifyChange)
  }

  onYearsChange = yearsOfInvestment => {
    this.setState({ yearsOfInvestment }, this.notifyChange)
  }
  render () {
    const {
      initialInvestment,
      monthlyInvestment,
      yearsOfInvestment
    } = this.state

    const { calculationMade } = this.props
    /* Key based on the investment values, this ensures that the graphic
      will be re-rendered after each change */
    const key = initialInvestment + monthlyInvestment + yearsOfInvestment
    const data = getGraphicData(
      initialInvestment,
      monthlyInvestment,
      yearsOfInvestment
    )
    return (
      <React.Fragment>
        <div className={styles.controls_container}>
          <div ref={this.calculatorRef} className='margin-bottom-small'>
            <QuantitySteper
              label='Tu inversión inicial:'
              onChange={this.onInitialInvestmentChange}
              defaultValue={initialInvestment}
              minValue='10000'
              maxValue='1000000'
              step='5000'
              maxLength='6'
            />
          </div>
          <div className='margin-bottom-small'>
            <QuantitySteper
              label='Tu aportación mensual:'
              onChange={this.onMonthlyInvestmentChange}
              defaultValue={monthlyInvestment}
              minValue='0'
              maxValue='100000'
              step='1000'
              maxLength='5'
            />
          </div>
          <OptionSwitch
            className='margin-bottom-small'
            options={yearsOptions}
            onChange={this.onYearsChange}
            defaultValue={5}
            id='investment-years-switch'
            label={
              <p className='color-dark-blue text-bold'>¿A cuántos años?</p>
            }
          />
        </div>
        <Button
          theme='primary'
          onClick={this.scrollToResult}
          className='margin-bottom-small hide-on-sm'
        >
          Calcular rendimiento
        </Button>
        <div className={`${calculationMade ? '' : 'hide-on-xs show-on-sm'}`}>
          <div ref={this.resultRef} className='margin-bottom-small'>
            <p className='color-dark-blue margin-bottom-small text-bold'>
              Así sería tu inversión en La Tasa: *
            </p>
            <InvestmentDetail {...data[0][LA_TASA_KEY]} />
          </div>
          <PerformanceSimulatorGraph key={key} data={data} />
          <p className='text-small margin-bottom'>
            Rendimientos:
            {GRAPHIC_ELEMENTS.reduce(
              (previous, graphicElement) =>
                `${previous} ${graphicElement.label} ${graphicElement.rate}%`,
              ''
            )}
          </p>
        </div>
        {calculationMade && (
          <Button
            theme='link'
            onClick={this.scrollToCalculator}
            className='margin-bottom-small hide-on-sm'
          >
            Volver a calcular
          </Button>
        )}
      </React.Fragment>
    )
  }
}

ProfitCalculator.defaultProps = {
  onChange: () => {}
}

ProfitCalculator.propTypes = {
  onChange: PropTypes.func,
  onCalculationMade: PropTypes.func.isRequired,
  calculationMade: PropTypes.bool.isRequired
}

export default ProfitCalculator
