// Libraries
import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// Components
import { AuthProvider, AuthConsumer } from 'contexts/auth'
import { OpportunityProvider, OpportunityConsumer } from 'contexts/opportunity'
import PublicRoutes from 'routes/publicRoutes'
import PrivateRoutes from 'routes/privateRoutes'
import LoadingView from 'views/loadingView'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

if (process.env.RECORD_SESSION) {
  LogRocket.init('1a1p9l/latasaforms', {
    network: {
      requestSanitizer: request => {
        // Ignore hotjar responses
        if (request.url.toLowerCase().indexOf('hotjar') > -1) {
          return null
        }
        // Ignore the set new password request
        if (request.url.toLowerCase().indexOf('opportunities/investor') > -1) {
          const objectBody = JSON.parse(request.body)

          if (
            objectBody &&
            objectBody.investor_opportunity &&
            objectBody.investor_opportunity.user &&
            objectBody.investor_opportunity.user.password
          ) {
            objectBody.investor_opportunity.user.password = ''
            request.body = JSON.stringify(objectBody)
          }
        }

        // Ignore login request
        if (request.url.toLowerCase().indexOf('auth/login') > -1) {
          return null
        }

        // Ignore the password on create request
        if (request.url.toLowerCase().indexOf('auth/password-reset/') > -1) {
          request.body.password = ''
          request.body.password_confirmation = ''
        }
        return request
      }
    }
  })
  setupLogRocketReact(LogRocket)
}

export const Routes = () => (
  <AuthProvider>
    <AuthConsumer>
      {({ isSignedIn, logout }) => (
        <OpportunityProvider isSignedIn={isSignedIn} logout={logout}>
          <OpportunityConsumer>
            {({ progress, isLoading }) =>
              !isSignedIn ? (
                <PublicRoutes />
              ) : isLoading ? (
                <LoadingView />
              ) : (
                <PrivateRoutes currentStep={progress} />
              )
            }
          </OpportunityConsumer>
        </OpportunityProvider>
      )}
    </AuthConsumer>
  </AuthProvider>
)

class App extends Component {
  render () {
    return (
      <React.Fragment>
        <BrowserRouter basename={process.env.PUBLIC_URL || ''}>
          <Routes />
        </BrowserRouter>
        <ToastContainer position='bottom-right' hideProgressBar pauseOnHover />
      </React.Fragment>
    )
  }
}

export default App
