// Libraries
import React, { Component } from 'react'

// Components
import { OpportunityConsumer } from '.'

export default function withOpportunityProgress (WrappedComponent) {
  return class extends Component {
    render () {
      return (
        <OpportunityConsumer>
          {({ savedData, setOpportunityData }) => (
            <WrappedComponent
              {...this.props}
              setOpportunityData={setOpportunityData}
              savedData={savedData}
            />
          )}
        </OpportunityConsumer>
      )
    }
  }
}
