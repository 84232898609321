// Libraries
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

// Components
import FileUploader from 'shared/fileUploader'

/**
 * @typedef {Object} Props
 * @property {Object} errors
 * @property {Object} touched
 * @property {Object} defaultValues
 * @property {Number} submitCount
 * @property {function} onChange
 */

/**
 * @extends {Component<Props, {}>}
 */
class passportDocuments extends Component {
  getError = field => {
    const { errors, touched, submitCount } = this.props
    // If submitted or field touched, return field error
    return submitCount > 0 || touched[field] ? errors[field] : ''
  }
  render () {
    const { onChange, defaultValues } = this.props
    return (
      <Fragment>
        <p className='color-dark-blue start-xs text-uppercase text-smallest'>
          Subir archivos (Es necesario que se vean las cuatro esquinas del
          documento)
        </p>
        <FileUploader
          id='passport'
          onChange={onChange}
          label='Pasaporte'
          className='margin-bottom-small'
          invalidMessage={this.getError('passport')}
          uploadedFile={defaultValues.passport || ''}
        />
        <p className='text-smallest start-xs color-dark-gray margin-bottom'>
          Tu identificación debe estar vigente. Las imágenes deben ser legibles
          en formato PDF, JPEG ó PNG y no pesar más de 10 MB.
        </p>
        <p className='margin-bottom-small start-xs'>
          Por favor proporciónanos un comprobante de tu domicilio actual.
        </p>

        <FileUploader
          id='proofOfAddress'
          onChange={onChange}
          label='Comprobante de domicilio'
          className='margin-bottom-small'
          invalidMessage={this.getError('proofOfAddress')}
          uploadedFile={defaultValues.proofOfAddress || ''}
        />
        <p className='text-smallest start-xs color-dark-gray margin-bottom-small'>
          Recibos de servicios como luz, teléfono, cable, internet, gas o agua,
          impuesto predial o estados de cuenta bancarios, de ahorro o de
          crédito.
        </p>
      </Fragment>
    )
  }
}

passportDocuments.propTypes = {
  onChange: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  errors: PropTypes.shape({
    passport: PropTypes.string,
    proofOfAddress: PropTypes.string
  }).isRequired,
  touched: PropTypes.shape({
    passport: PropTypes.bool,
    proofOfAddress: PropTypes.bool
  }).isRequired,
  defaultValues: PropTypes.shape({
    passport: PropTypes.string,
    proofOfAddress: PropTypes.string
  }).isRequired
}

export default passportDocuments
