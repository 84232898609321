// Libraries
import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

// Components
import OptionSwitch from 'shared/optionSwitch'
import { YES_NO_OPTIONS } from 'shared/catalogs'
import FileUploader from 'shared/fileUploader'
import { isNull } from 'utils/formValidations'

/**
 * @typedef {Object} Props
 * @property {Object} errors
 * @property {Object} touched
 * @property {Object} defaultValues
 * @property {Number} submitCount
 * @property {function} onChange
 *
 * @typedef {Object} State
 * @property {boolean} isAddressTheSame
 */
/**
 * @extends {PureComponent<Props, State>}
 */
class VoterCardDocuments extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isAddressTheSame: props.defaultValues.isAddressTheSame
    }
  }

  getError = field => {
    const { errors, touched, submitCount } = this.props
    // If submitted or field touched, return field error
    return submitCount > 0 || touched[field] ? errors[field] : ''
  }

  handleIsAddressTheSameChange = (isAddressTheSame, id) => {
    const { onChange } = this.props
    this.setState({ isAddressTheSame })
    onChange(isAddressTheSame, id)
  }

  render () {
    const { onChange, defaultValues } = this.props
    const { isAddressTheSame } = this.state
    return (
      <Fragment>
        <p className='color-dark-blue start-xs text-uppercase text-smallest'>
          Subir archivos (Es necesario que se vean las cuatro esquinas del
          documento)
        </p>
        <FileUploader
          onChange={onChange}
          label='Frente IFE / INE'
          className='margin-bottom-small'
          id='voterCardFront'
          invalidMessage={this.getError('voterCardFront')}
          uploadedFile={defaultValues.voterCardFront || ''}
        />
        <FileUploader
          onChange={onChange}
          label='Vuelta IFE / INE'
          className='margin-bottom-small'
          id='voterCardBack'
          invalidMessage={this.getError('voterCardBack')}
          uploadedFile={defaultValues.voterCardBack || ''}
        />
        <p className='text-smallest start-xs color-dark-gray margin-bottom-small'>
          Tu identificación debe estar vigente. Las imágenes deben ser legibles
          en formato PDF, JPEG ó PNG y no pesar más de 10 MB.
        </p>
        <OptionSwitch
          className='margin-bottom'
          label={
            <p className='color-dark-blue start-xs text-uppercase text-smallest'>
              ¿Tu INE muestra tu dirección actual completa? *
            </p>
          }
          onChange={this.handleIsAddressTheSameChange}
          id='isAddressTheSame'
          options={YES_NO_OPTIONS}
          optionClassName='color-dark-blue full-width'
          wrap={false}
          invalidMessage={this.getError('isAddressTheSame')}
          defaultValue={defaultValues.isAddressTheSame}
        />
        {!isNull(isAddressTheSame) && !isAddressTheSame && (
          <Fragment>
            <p className='margin-bottom-small start-xs'>
              Por favor proporciónanos un comprobante de tu domicilio actual.
            </p>

            <FileUploader
              id='proofOfAddress'
              onChange={onChange}
              label='Comprobante de domicilio'
              className='margin-bottom-small'
              invalidMessage={this.getError('proofOfAddress')}
              uploadedFile={defaultValues.proofOfAddress}
            />
            <p className='text-smallest start-xs color-dark-gray margin-bottom-small'>
              Recibos de servicios como luz, teléfono, cable, internet, gas o
              agua, impuesto predial o estados de cuenta bancarios, de ahorro o
              de crédito.
            </p>
          </Fragment>
        )}
      </Fragment>
    )
  }
}

VoterCardDocuments.propTypes = {
  onChange: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  errors: PropTypes.shape({
    isAddressTheSame: PropTypes.string,
    proofOfAddress: PropTypes.string,
    voterCardBack: PropTypes.string,
    voterCardFront: PropTypes.string
  }).isRequired,
  touched: PropTypes.shape({
    isAddressTheSame: PropTypes.bool,
    proofOfAddress: PropTypes.bool,
    voterCardBack: PropTypes.bool,
    voterCardFront: PropTypes.bool
  }).isRequired,
  defaultValues: PropTypes.shape({
    isAddressTheSame: PropTypes.bool.isRequired,
    proofOfAddress: PropTypes.string,
    voterCardBack: PropTypes.string,
    voterCardFront: PropTypes.string
  }).isRequired
}

export default VoterCardDocuments
