import * as cookies from 'browser-cookies'
/**
 * Transform the signup form data into the signup request
 * @param {object} formData
 * @param {object} investmentData
 */
export const getSignUpRequest = (formData, investmentData) => {
  const { email, password } = formData
  const {
    monthlyInvestment,
    initialInvestment,
    yearsOfInvestment
  } = investmentData
  return {
    investor_opportunity: {
      user: {
        email,
        password,
        role: ['investor']
      },
      monthly_investment: monthlyInvestment.toString(),
      initial_investment: initialInvestment.toString(),
      years_of_investment: Number(yearsOfInvestment.toString()),
      mkt: {
        landing: window.location.href,
        gcid: cookies.get('_ga')
      }
    }
  }
}
