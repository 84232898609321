// Libraries
import React, { Component } from 'react'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import styles from './tooltip.module.scss'

class Tooltip extends Component {
  render () {
    const { tooltip, children } = this.props
    return (
      <React.Fragment>
        <p data-tip={tooltip}>{children}</p>
        <ReactTooltip className={styles.tooltip} multiline />
      </React.Fragment>
    )
  }
}

Tooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default Tooltip
