// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Components
import styles from './wizardStepper.module.scss'

const WizardStep = ({ id, sequence, isActive, isCompleted }) => (
  <div
    className={classNames(styles.wizard_step, {
      [styles.completed]: isCompleted,
      [styles.inactive]: !isActive && !isCompleted,
      [styles.active]: isActive
    })}
    key={id}
  >
    <div className={classNames(styles.step_number, {})}>
      {isActive || isCompleted ? (
        <div
          className={classNames(styles.outer_border, {
            [styles.completed]: isCompleted
          })}
        >
          <div
            className={classNames(styles.inner_border, {
              [styles.completed]: isCompleted,
              [styles.active]: isActive
            })}
          >
            {// Return the id when is active or the check icon when completed
              isActive ? (
                <span>{sequence}</span>
              ) : (
                <span className='mdi mdi-check' />
              )}
          </div>
        </div>
      ) : (
        // Just return the inactive mark when not completed nor active
        <div className={styles.inactive_mark} />
      )}
    </div>
  </div>
)

WizardStep.propTypes = {
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  sequence: PropTypes.number.isRequired
}

export default WizardStep
