// Libraries
import React, { Component } from 'react'

// Components
import DirectionLogo from 'assets/img/footer-direction-logo.svg'
import MailLogo from 'assets/img/footer-mail-logo.svg'
import FacebookLogo from 'assets/img/facebook.svg'
import TwitterLogo from 'assets/img/twitter.svg'
import styles from './footer.module.scss'

const Info = () => (
  <section className={styles.info}>
    <div className={styles.links_container}>
      <p>
        <a
          href='https://latasa.mx/Docs/terminos_condiciones_la_tasa.pdf'
          target='_blank'
          rel='noopener noreferrer'
        >
          Términos y condiciones
        </a>
      </p>
      <p>
        <a
          href='https://latasa.mx/Docs/aviso_privacidad_latasa.pdf'
          target='_blank'
          rel='noopener noreferrer'
        >
          Políticas de privacidad
        </a>
      </p>
      <p>
        <a
          href='https://latasa.mx/Docs/politicas_cookies_latasa.pdf'
          target='_blank'
          rel='noopener noreferrer'
        >
          Uso de cookies
        </a>
      </p>
    </div>
  </section>
)

const Contact = () => (
  <section className={styles.contact}>
    <div className={styles.contact_item}>
      <div>
        <img
          alt='Logo dirección'
          src={DirectionLogo}
          className={styles.contact_logo}
        />
      </div>
      Av. Mariano Escobedo 555, Planta Baja, Col. Bosques de Chapultepec, 1a
      Sección, Miguel Hidalgo, Ciudad de México, C.P. 11580
    </div>
    <div className={styles.contact_item}>
      <div>
        <img alt='Logo correo' src={MailLogo} className={styles.contact_logo} />
      </div>
      <a
        href='mailto:contacto@latasa.mx'
        className={`${styles.contact_item} ${styles.contact_item_mail}`}
      >
        contacto@latasa.mx
      </a>
    </div>
  </section>
)

const Social = () => (
  <section className={styles.social}>
    <span>SÍGUENOS:</span>
    <a
      href='https://www.facebook.com/LaTasaMx/'
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className={`${styles.logo} ${styles.facebook}`}>
        <div className={`${styles.logo_container} ${styles.facebook}`}>
          <img
            alt='Facebook'
            src={FacebookLogo}
            className={`${styles.image}`}
          />
        </div>
        <div className={`${styles.label}`}>Facebook</div>
      </div>
    </a>
    <a
      href='https://twitter.com/LaTasaMx'
      target='_blank'
      rel='noopener noreferrer'
    >
      <div className={`${styles.logo} ${styles.twitter}`}>
        <div className={`${styles.logo_container} ${styles.twitter}`}>
          <img alt='Twitter' src={TwitterLogo} className={`${styles.image}`} />
        </div>
        <div className={`${styles.label}`}>Twitter</div>
      </div>
    </a>
  </section>
)

class Footer extends Component {
  render () {
    return (
      <footer className={styles.public_footer}>
        <div className='container'>
          <div className={`${styles.items}`}>
            <Info />
            <Social />
            <hr />
            <Contact />
          </div>
        </div>
        <div className={`background-dark-blue color-white ${styles.copyright}`}>
          ©
          {`${new Date().getFullYear()}`}
          {' La Tasa'}
        </div>
      </footer>
    )
  }
}

export default Footer
