// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LogRocket from 'logrocket'

// Components
import AuthStore from 'stores/authStore'

// The actual context
const AuthContext = React.createContext({})

const defaultState = {
  isSignedIn: false,
  session: undefined
}

class AuthProvider extends Component {
  state = {
    ...defaultState
  }

  componentDidMount () {
    const session = AuthStore.getSession()
    if (session) {
      this.createSession(session)
    }
  }

  /**
   * Initialize the log rocket session
   */
  initializeLogRocket = () => {
    const {
      session: { email, token }
    } = this.state
    LogRocket.identify(token, {
      name: email,
      email
    })
  }

  logout = () => {
    this.setState({ ...defaultState })
    AuthStore.clear()
  }

  createSession = session => {
    AuthStore.setSession(session)
    this.setState({ isSignedIn: true, session }, this.initializeLogRocket)
  }

  render () {
    const { isSignedIn, session } = this.state
    const { children } = this.props
    return (
      <AuthContext.Provider
        value={{
          isSignedIn,
          logout: this.logout,
          createSession: this.createSession,
          session
        }}
      >
        {children}
      </AuthContext.Provider>
    )
  }
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

const AuthConsumer = AuthContext.Consumer

export { AuthProvider, AuthConsumer }
