import { createDate, DateToString } from 'utils'
import { COMPLEMENT_PROFILE_COMPLETED } from 'routes/privateRoutes'

export const getAddressDataFormRequest = formData => {
  const {
    street,
    externalNumber,
    internalNumber,
    postalCode,
    neighborhood,
    municipality,
    state,
    city
  } = formData
  return {
    investor_opportunity: {
      address: {
        street,
        external_number: externalNumber,
        internal_number: internalNumber,
        postal_code: postalCode,
        neighborhood,
        municipality,
        state,
        city
      }
    }
  }
}

export const getPersonalDataFormRequest = formData => {
  const {
    firstName,
    lastName,
    secondLastName,
    phone,
    birthdate,
    gender,
    birthPlace,
    statePlace,
    maritalStatus,
    secondName
  } = formData
  return {
    investor_opportunity: {
      user: {
        first_name: firstName,
        second_name: secondName,
        last_name: lastName,
        second_last_name: secondLastName,
        phone,
        birth_date: DateToString(
          createDate(birthdate.year, birthdate.month, birthdate.day)
        ),
        nationality: 'mexican',
        birth_place: {
          country: birthPlace,
          state: statePlace
        },
        gender,
        marital_status: maritalStatus
      }
    }
  }
}

export const getWorkDataFormRequest = formData => {
  const { rfc, homoclave, curp, workActivity, educationLevel } = formData
  return {
    investor_opportunity: {
      extras: {
        progress: COMPLEMENT_PROFILE_COMPLETED
      },
      user: {
        rfc: `${rfc}${homoclave || ''}`,
        education_level: educationLevel
      },
      curp,
      work_activity: workActivity,
      'politically_exposed?': false
    }
  }
}
