// Libraries
import React, { Component } from 'react'

// Components
import Logo from 'assets/img/logo.svg'
import styles from './topbar.module.scss'
import ResponsiveMenu from './responsiveMenu'

class PublicTopbar extends Component {
  state = {
    open: false
  }
  toggleOpen = () => {
    this.setState(({ open }) => ({ open: !open }))
  }

  render () {
    const { open } = this.state
    return (
      <header
        data-testid='topbar-component'
        className={`${styles.topbar} ${open ? styles.open : ''}`}
      >
        <div className='container'>
          <div className={`row between-xs ${styles.container}`}>
            <div
              className={`col col-xs-3 start-xs middle-xs ${
                styles.main_logo_container
              }`}
            >
              <a
                href='https://latasa.mx/'
                className={styles.main_logo_container}
                tabIndex='-1'
              >
                <img
                  height='32'
                  width='117'
                  className={styles.logo}
                  src={Logo}
                  alt='Logo La Tasa'
                />
              </a>
            </div>
            <ResponsiveMenu open={open} toggleOpen={this.toggleOpen} />
          </div>
        </div>
      </header>
    )
  }
}

export default PublicTopbar
