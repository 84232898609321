import { createDate, DateToString } from 'utils'
import { DOCUMENT_TYPE_PASSPORT } from 'shared/catalogs'
import { START_TO_INVEST_COMPLETED } from 'routes/privateRoutes'

export const getInvestmentPlanFormRequest = (formData, { defaultValues }) => {
  const {
    monthlyAverageAmount,
    investmentSource,
    initialInvestment,
    monthlyInvestment,
    bankName,
    clabe,
    debitCard
  } = formData
  const shouldSaveClabe = !(
    debitCard ||
    (clabe.includes('*') && clabe === defaultValues.clabe)
  )

  const shouldSaveDebitCard = !(
    clabe ||
    (debitCard.includes('*') && debitCard === defaultValues.debitCard)
  )

  // Generate an object to inject the property in case we should add the bank data
  const bankDataObject =
    shouldSaveClabe || shouldSaveDebitCard
      ? {
        user: {
          bank_accounts: shouldSaveClabe
            ? [{ standardized_bank_code: clabe, bank_name: bankName }]
            : [{ card_number: debitCard, bank_name: bankName }]
        }
      }
      : {}
  return {
    investor_opportunity: {
      monthly_investment: monthlyInvestment.toString(),
      initial_investment: initialInvestment.toString(),
      investment_source: investmentSource,
      monthly_average_amount: monthlyAverageAmount,
      ...bankDataObject
    }
  }
}

export const getBeneficiariesFormRequest = formData => {
  const { beneficiaries } = formData

  const totalBeneficiaries = beneficiaries.length
  return {
    investor_opportunity: {
      beneficiaries: beneficiaries.map(beneficiary => ({
        first_name: beneficiary.firstName,
        second_name: beneficiary.secondName,
        last_name: beneficiary.lastName,
        second_last_name: beneficiary.secondLastName,
        birth_date: DateToString(
          createDate(
            beneficiary.birthdate.year,
            beneficiary.birthdate.month,
            beneficiary.birthdate.day
          )
        ),
        gender: beneficiary.gender,
        relationship: beneficiary.relationship,
        // If only a beneficiary is selected, set the 100% as the percentage
        percentage:
          totalBeneficiaries > 1 ? Number(beneficiary.percentage) : 100
      }))
    }
  }
}

export const getDocumentationArray = ({
  voterCardFront,
  voterCardBack,
  proofOfAddress,
  documentType,
  isAddressTheSame,
  passport
}) => {
  if (documentType === DOCUMENT_TYPE_PASSPORT) {
    // Just return the passport and proof of address
    return [
      getDocumentRequest('passport', passport),
      getDocumentRequest('residency_proof', proofOfAddress)
    ]
  } else {
    // Send both votercard images if the address is the same
    if (isAddressTheSame) {
      return [
        getDocumentRequest('id_front', voterCardFront),
        getDocumentRequest('id_back', voterCardBack)
      ]
    }
  }
  // No passport and no address is the same
  return [
    getDocumentRequest('id_front', voterCardFront),
    getDocumentRequest('id_back', voterCardBack),
    getDocumentRequest('residency_proof', proofOfAddress)
  ]
}

/**
 * Creates the payload to make the document request if it exists
 * @param {string} key Document key to be injected in the response
 * @param {Object} document
 */
const getDocumentRequest = (key, document) =>
  document.size > 0
    ? {
      investor_opportunity: {
        documents: {
          [key]: {
            binary: document.value,
            filename: document.fileName,
            contentType: document.contentType
          }
        }
      }
    }
    : undefined

export const getUpdateProgressRequest = () => ({
  investor_opportunity: {
    extras: {
      progress: START_TO_INVEST_COMPLETED
    }
  }
})
