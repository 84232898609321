// Libraries
import React from 'react'

// Components
import ProfitCalculatorView from 'views/profitCalculatorView'
import SignUpView from 'views/signUpView'
import SignInView from 'views/signInView'
import RecoverPasswordView from 'views/recoverPasswordView'
import RoutesList from './routesList'
import uuid from 'uuid/v4'
import ResetPasswordView from 'views/resetPasswordView'

// Exports
export const BASE_ROUTE = '/'
export const SIGN_UP_ROUTE = '/crear-cuenta'
export const SIGN_IN_ROUTE = '/iniciar-sesion'
export const RECOVER_PASSWORD_ROUTE = '/recuperar-contrasena'
export const RESET_PASSWORD_ROUTE = '/reestablecer-contrasena'

const PUBLIC_ROUTES = [
  {
    id: uuid(),
    path: BASE_ROUTE,
    component: ProfitCalculatorView,
    exact: true
  },
  {
    id: uuid(),
    path: SIGN_UP_ROUTE,
    component: SignUpView
  },
  {
    id: uuid(),
    path: SIGN_IN_ROUTE,
    component: SignInView
  },
  {
    id: uuid(),
    path: RECOVER_PASSWORD_ROUTE,
    component: RecoverPasswordView
  },
  {
    id: uuid(),
    path: RESET_PASSWORD_ROUTE,
    component: ResetPasswordView
  }
]

const PublicRoutes = () => (
  <RoutesList
    routes={PUBLIC_ROUTES}
    defaultRoute={BASE_ROUTE}
    id='public-routes'
  />
)

export default PublicRoutes
