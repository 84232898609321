// Libraries
import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

const DocumentTitle = ({ title }) => {
  const renderTitle = title
    ? `${title} – La Tasa`
    : 'La Tasa: Invertir en La Tasa es la mejor decisión'
  return (
    <Helmet>
      <title>{renderTitle}</title>
    </Helmet>
  )
}

export default DocumentTitle

DocumentTitle.defaultProps = {
  title: ''
}

DocumentTitle.propTypes = {
  title: PropTypes.string
}
