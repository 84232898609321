// Libraries
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { isNull } from 'utils/formValidations'

// Components
import styles from './input.module.scss'
import Icon from 'shared/icon'
import Tooltip from 'shared/tooltip'

const defaultState = {
  value: '',
  forceText: false
}

/**
 * @typedef {typeof defaultState} State
 *
 * @typedef {object} Props
 * @property {React.ReactNode | string} [indicator] An indicator to be placed at the end of the input
 * @property {boolean} [canReset] Enable the reset button when the input has a value
 * @property {string} [className]
 * @property {boolean} [disabled]
 * @property {string} id
 * @property {string} [invalidMessage] forces the invalid state and show a label with the message under the input
 * @property {string} [label]
 * @property {string | number} [max] max numeric value that the input can have, only valid when the input is numeric
 * @property {string | number} [min] min numeric value that the input can have, only valid when the input is numeric
 * @property {string | number} [maxLength]
 * @property {function} [onChange]
 * @property {string} [placeholder]
 * @property {boolean} [showPasswordToggle] Shows the toggle password button, only valid when the input is password type
 * @property {string} [type='text']
 * @property {string} [defaultValue]
 * @property {function} [validate] function that evaluates the value on each change and commit the change only if the function returns true
 * @property {boolean} [isInvalid] forces the invalid style
 * @property {function} [onFocus]
 * @property {function} [onBlur]
 * @property {string} [name]
 * @property {{ blur?: function, focus?: function }} [formatter] executes the formatter function on each handler with this object
 * @property {string} [tooltip]
 */

/**
 * @extends {React.PureComponent<Props,State>}
 */
class Input extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      ...defaultState,
      value: props.defaultValue || ''
    }
  }

  componentDidMount () {
    const { value } = this.state
    const { formatter } = this.props
    // Notify in case of having an initial value
    if (!isNull(value)) {
      // Notify change then apply the formatter if provided
      this.notifyChange()
      if (formatter.blur) {
        this.setState({ value: formatter.blur(value) })
      }
    }
  }

  // Handle changes to input
  handleChange = e => {
    const value = e.nativeEvent ? e.target.value : e
    const { validate } = this.props

    // Do not change anythig if the validate function return false
    if (validate && !validate(value)) {
      return
    }
    this.updateValue(value)
  }

  handleReset = () => {
    this.updateValue('')
  }

  updateValue = value => {
    this.setState({ value }, this.notifyChange)
  }

  notifyChange = () => {
    const { value } = this.state
    const { id, onChange, name } = this.props
    onChange(value.toString(), name || id)
  }

  toggleForceText = () => {
    this.setState(({ forceText }) => ({ forceText: !forceText }))
  }

  handleBlur = e => {
    const { onBlur, formatter } = this.props
    const { value } = this.state
    // Execute formatter if a blut function is provided
    if (!isNull(value) && formatter.blur) {
      // Update the state only, do not call the notify function
      this.setState({ value: formatter.blur(value) })
    }
    onBlur(e)
  }

  handleFocus = e => {
    const { onFocus, formatter } = this.props
    const { value } = this.state
    // Execute formatter if a blur function is provided
    if (!isNull(value) && formatter.focus) {
      this.updateValue(formatter.focus(value))
    }
    onFocus(e)
  }
  // Main render
  render () {
    const {
      type,
      label,
      className,
      id,
      disabled,
      placeholder,
      maxLength,
      max,
      min,
      invalidMessage,
      showPasswordToggle,
      isInvalid,
      indicator,
      name,
      canReset,
      tooltip
    } = this.props
    const { value, forceText } = this.state
    return (
      <div
        className={classNames(styles.input, {
          [className]: !!className,
          [styles.disabled]: disabled
        })}
      >
        <label htmlFor={id}>
          {label && (
            <div className={styles.label_container}>
              <p className={classNames(styles.label, 'text-smallest')}>
                {label}
              </p>

              {tooltip && (
                <Tooltip tooltip={tooltip}>
                  <Icon className='color-sky-blue' icon='information-outline' />
                </Tooltip>
              )}
            </div>
          )}
          <div className={classNames(styles.input_container, {})}>
            <input
              id={id}
              data-testid={id}
              type={forceText ? 'text' : type}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              className={classNames(styles.input_field, {
                [styles.invalid]: invalidMessage || isInvalid
              })}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              maxLength={Number(maxLength)}
              max={max}
              min={min}
              name={name}
            />
            {showPasswordToggle && (
              <div className={styles.input_icon}>
                <Icon
                  id={`${id}-password-toggle`}
                  icon={forceText ? 'eye-off' : 'eye'}
                  className='clickable color-gray'
                  onClick={this.toggleForceText}
                />
              </div>
            )}
            {canReset && value && (
              <div className={styles.input_icon}>
                <Icon
                  id={`${id}-reset-button`}
                  icon='close'
                  className='clickable color-gray'
                  onClick={this.handleReset}
                />
              </div>
            )}
            {indicator && (
              <div className={styles.input_icon}>
                <span className='color-light-gray'>{indicator}</span>
              </div>
            )}
          </div>
          {<p className='color-error text-small'>{invalidMessage}</p>}
        </label>
      </div>
    )
  }
}

Input.defaultProps = {
  max: undefined,
  maxLength: undefined,
  min: undefined,
  label: '',
  validate: undefined,
  onFocus: () => {},
  onBlur: () => {},
  formatter: {},
  className: '',
  disabled: false,
  invalidMessage: '',
  placeholder: '',
  showPasswordToggle: false,
  type: 'text',
  isInvalid: false,
  defaultValue: '',
  indicator: '',
  name: '',
  onChange: () => {},
  canReset: false,
  tooltip: ''
}
Input.propTypes = {
  indicator: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  canReset: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  invalidMessage: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxLength: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showPasswordToggle: PropTypes.bool,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  validate: PropTypes.func,
  isInvalid: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  formatter: PropTypes.shape({
    blur: PropTypes.func,
    focus: PropTypes.func
  }),
  tooltip: PropTypes.string
}

export default Input
