// Libraries
import React from 'react'
import PropTypes from 'prop-types'

// Components
import styles from './quantitySteperButton.module.scss'
import Button from 'shared/button'

const QuantitySteperButton = ({ children, onClick, id }) => {
  return (
    <Button id={id} className={styles.quantity_button} onClick={onClick}>
      <div className={styles.circle_simulator}>
        <div className={`${styles.circle_simulator} ${styles.step_1}`}>
          <div
            className={`${styles.circle_simulator} ${styles.step_2} ${
              styles.label
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    </Button>
  )
}

QuantitySteperButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.string
  ]),
  onClick: PropTypes.func,
  id: PropTypes.string
}

QuantitySteperButton.defaultProps = {
  children: undefined,
  onClick: () => {},
  id: ''
}

export default QuantitySteperButton
