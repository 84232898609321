// Libraries
import React, { Component } from 'react'

// Components
import PublicPage from 'shared/publicPage'
import DocumentTitle from 'shared/documentTitle'
import MailLogo from 'assets/img/footer-mail-logo.svg'
import PhoneLogo from 'assets/img/phone-logo.svg'
import GeneratingContractLogo from 'assets/img/generating-contract.svg'

class GeneratingContractView extends Component {
  render () {
    return (
      <PublicPage>
        <DocumentTitle title='Comienza a invertir' />
        <h1 className='color-dark-blue margin-bottom-small text-bold text-uppercase'>
          Estamos generando tu contrato
        </h1>
        <img
          width='200px'
          height='200px'
          alt='generando contrato'
          src={GeneratingContractLogo}
        />
        <h3 className='margin-bottom text-light'>
          Este proceso nos llevará un máximo de 24 hrs. Te mandaremos un correo
          cuando esté listo para que puedas firmarlo.
        </h3>
        <h3 className='margin-bottom text-light'>
          Si tienes alguna duda o deseas comunicarte con nosotros, puedes
          hacerlo por los siguientes medios:
        </h3>
        <div className='row'>
          <div className='col-xs-6'>
            <div>
              <img
                width='52px'
                height='52px'
                alt='Logo correo'
                src={MailLogo}
              />
            </div>
            <h3 className='color-dark-blue text-light'>hola@latasa.mx</h3>
          </div>
          <div className='col-xs-6'>
            <div>
              <img
                width='52px'
                height='52px'
                alt='Logo teléfono'
                src={PhoneLogo}
              />
            </div>
            <h3 className='color-dark-blue text-light'>55 4428 8606</h3>
          </div>
        </div>
      </PublicPage>
    )
  }
}

export default GeneratingContractView
