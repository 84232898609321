// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _debounce from 'lodash.debounce'
import { withFormik } from 'formik'

// Components
import Button from 'shared/button'
import Input from 'shared/input'
import Select from 'shared/select'
import Icon from 'shared/icon'
import API from 'api'
import { parsePostalCodeResponse } from 'utils/sepomex'
import { hasOnlyNumbers } from 'utils/stringValidations'
import { CP_ERROR } from '../constants'
import {
  validateInput,
  minLength,
  validateNullField,
  numbersOnly,
  validateNullSelect,
  genericValidationMessage
} from 'utils/formValidations'
import { cleanObject } from 'utils'
import { getAddressDataFormRequest } from './utils'
import { notifyError } from 'utils/notifications'
import { cancelEvent } from 'utils/events'

class AddressDataForm extends Component {
  state = {
    sepomexData: undefined,
    isLoading: false,
    postalCode: '',
    postalCodeError: false
  }

  makeSepomexRequest = _debounce(async newPostalCode => {
    try {
      this.setState({ isLoading: true })
      const { data } = await API.Services.PostalCode(newPostalCode)
      const { postalCode } = this.state
      // No changes between the response resolution
      if (postalCode === newPostalCode) {
        // values has been found
        if (data.data.length > 0) {
          this.setState({
            sepomexData: parsePostalCodeResponse(data.data)
          })
        } else {
          // Nothing found, change the state to show the error
          this.setState({
            postalCodeError: true
          })
        }
      }
    } catch (err) {
      // Change the state to show the error
      this.setState({
        postalCodeError: true
      })
      console.log(err)
    } finally {
      this.setState({ isLoading: false })
    }
  }, 600)

  // Sets the value in the formik form
  handleChange = (value, id) => {
    const { setFieldTouched, setFieldValue } = this.props
    setFieldTouched(id)
    setFieldValue(id, value)
  }

  handlePostalCodeChange = (value, id) => {
    this.handleChange(value, id)
    this.setState({ postalCode: value }, () => {
      const { postalCode } = this.state

      if (postalCode.length === 5) {
        this.makeSepomexRequest(postalCode)
      } else {
        // do not show anything when a invalid postalCode is provided
        this.setState({ sepomexData: undefined, postalCodeError: false })
      }
    })
  }

  getError = field => {
    const { errors, touched, submitCount } = this.props
    // If submitted or field touched, return field error
    return submitCount > 0 || touched[field] ? errors[field] : ''
  }

  render () {
    const { handleSubmit, defaultValues, isSubmitting } = this.props
    const { sepomexData, isLoading, postalCode, postalCodeError } = this.state
    const postalCodeErrorLabel =
      postalCode.length === 5 && postalCodeError && CP_ERROR

    const { cities, municipalities, neighborhoods, states } = sepomexData || {
      cities: undefined,
      municipalities: undefined,
      neighborhoods: undefined,
      states: undefined
    }
    return (
      <form onSubmit={isSubmitting ? cancelEvent : handleSubmit}>
        <Input
          className='margin-bottom-small'
          label='Calle *'
          onChange={this.handleChange}
          id='street'
          invalidMessage={this.getError('street')}
          defaultValue={defaultValues.street}
        />
        <div className='inline-direction'>
          <Input
            className='margin-bottom-small'
            label='Núm ext. *'
            onChange={this.handleChange}
            id='externalNumber'
            invalidMessage={this.getError('externalNumber')}
            defaultValue={defaultValues.externalNumber}
          />
          <Input
            className='margin-bottom-small'
            label='Núm int.'
            onChange={this.handleChange}
            id='internalNumber'
            defaultValue={defaultValues.internalNumber}
          />
        </div>
        <Input
          className='margin-bottom-small'
          label='Código postal *'
          onChange={this.handlePostalCodeChange}
          id='postalCode'
          maxLength='5'
          invalidMessage={postalCodeErrorLabel || this.getError('postalCode')}
          validate={hasOnlyNumbers}
          defaultValue={defaultValues.postalCode}
        />
        {isLoading && (
          <Icon className='color-orient' size='large' icon='loading' />
        )}
        {sepomexData && (
          <React.Fragment>
            <p className='start-xs margin-bottom-small'>
              Por favor verifica que la siguiente información sea correcta:
            </p>
            <Select
              className='margin-bottom-small'
              options={neighborhoods}
              onChange={this.handleChange}
              id='neighborhood'
              label='Colonia *'
              invalidMessage={this.getError('neighborhood')}
              defaultValue={defaultValues.neighborhood}
            />
            <Input
              className='margin-bottom-small'
              label='Delegación o municipio *'
              onChange={this.handleChange}
              id='municipality'
              defaultValue={municipalities[0].label}
              disabled
              invalidMessage={this.getError('municipality')}
            />
            <Input
              className='margin-bottom-small'
              label='Estado *'
              onChange={this.handleChange}
              id='state'
              defaultValue={states[0].label}
              disabled
              invalidMessage={this.getError('state')}
            />
            <Input
              className='margin-bottom-small'
              label='Ciudad *'
              onChange={this.handleChange}
              id='city'
              defaultValue={cities[0].label}
              disabled
              invalidMessage={this.getError('city')}
            />
          </React.Fragment>
        )}
        <p className='text-smallest start-xs color-dark-gray margin-bottom'>
          * Campos obligatorios
        </p>
        <Button isLoading={isSubmitting} theme='primary' type='submit'>
          Continuar
        </Button>
      </form>
    )
  }
}

// validate function called before handle submit
const validate = values => {
  // Create an errors object with built with the form form validations
  const errors = {
    street: validateInput(values.street, [
      validateNullField('Este campo es requerido.')
    ]),
    externalNumber: validateInput(values.externalNumber, [
      validateNullField(
        genericValidationMessage('el número exterior de tu domicilio')
      )
    ]),
    postalCode: validateInput(values.postalCode, [
      validateNullField(genericValidationMessage('tu número postal')),
      numbersOnly,
      minLength(
        5,
        'Tu código postal debe contener 5 dígitos. Por favor ingresa un código postal válido.'
      )
    ]),
    neighborhood: validateInput(values.neighborhood, [validateNullSelect]),
    municipality: validateInput(values.municipality, [validateNullSelect]),
    state: validateInput(values.state, [validateNullSelect]),
    city: validateInput(values.city, [validateNullSelect])
  }
  // Remove the empty keys and return the errors object
  return cleanObject(errors)
}

const handleSubmit = async (values, { props, setSubmitting }) => {
  setSubmitting(true)
  try {
    await API.Opportunities.Update(getAddressDataFormRequest(values))
    props.goNext(values)
  } catch (e) {
    console.error(e)
    notifyError('Ocurrió un error, intenta de nuevo más tarde.')
    setSubmitting(false)
  }
}

AddressDataForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValues: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
}

export default withFormik({
  validate,
  handleSubmit,
  // Avoid taking props as values
  mapPropsToValues: () => {},
  validateOnBlur: false
})(AddressDataForm)
