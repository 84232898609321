import PersonalDataForm from './complementProfileForm/personalDataForm'
import AddressDataForm from './complementProfileForm/addressDataForm'
import WorkDataForm from './complementProfileForm/workDataForm'

export const FORM_STEPS = [
  {
    label: 'Datos Personales',
    route: 'datos-personales',
    component: PersonalDataForm,
    id: 'personal-data'
  },
  {
    label: 'Domicilio',
    route: 'domicilio',
    component: AddressDataForm,
    id: 'address-data'
  },
  {
    label: 'Actividad Laboral',
    route: 'actividad-laboral',
    component: WorkDataForm,
    id: 'work-data'
  }
]

export const CP_ERROR =
  'No hemos podido validar tu código postal. Por favor ingrésalo de nuevo.'
