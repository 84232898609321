// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withFormik } from 'formik'

// Components
import Button from 'shared/button'
import Input from 'shared/input'
import Select from 'shared/select'
import {
  MEX_STATES,
  COUNTRIES,
  GENDER_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  LOCAL_COUNTRY
} from 'shared/catalogs'
import OptionSwitch from 'shared/optionSwitch'
import DateSelector from 'shared/dateSelector'
import { hasOnlyNumbers } from 'utils/stringValidations'
import {
  validateInput,
  lettersOnly,
  minLength,
  validateNullField,
  numbersOnly,
  validateNullSelect,
  validateComposedObject,
  genericValidationMessage,
  validateDate,
  validateMinDate,
  validateMaxDate
} from 'utils/formValidations'
import { cleanObject, objectIsEmpty, createDate } from 'utils'
import { getPersonalDataFormRequest } from './utils'
import API from 'api'
import { notifyError } from 'utils/notifications'
import { cancelEvent } from 'utils/events'

class PersonalDataForm extends Component {
  // Sets the value in the formik form
  handleChange = (value, id) => {
    const { setFieldTouched, setFieldValue } = this.props
    setFieldTouched(id)
    setFieldValue(id, value)
  }

  getError = field => {
    const { errors, touched, submitCount } = this.props
    // If submitted or field touched, return field error
    return submitCount > 0 || touched[field] ? errors[field] : ''
  }

  render () {
    const {
      handleSubmit,
      defaultValues,
      isSubmitting,
      values: { birthPlace }
    } = this.props
    return (
      <form onSubmit={isSubmitting ? cancelEvent : handleSubmit}>
        <Input
          className='margin-bottom-small'
          label='Nombre *'
          onChange={this.handleChange}
          id='firstName'
          invalidMessage={this.getError('firstName')}
          defaultValue={defaultValues.firstName}
        />
        <Input
          className='margin-bottom-small'
          label='Segundo nombre'
          onChange={this.handleChange}
          id='secondName'
          invalidMessage={this.getError('secondName')}
          defaultValue={defaultValues.secondName}
        />
        <Input
          className='margin-bottom-small'
          label='Apellido paterno *'
          onChange={this.handleChange}
          id='lastName'
          invalidMessage={this.getError('lastName')}
          defaultValue={defaultValues.lastName}
        />
        <Input
          className='margin-bottom-small'
          label='Apellido materno *'
          onChange={this.handleChange}
          id='secondLastName'
          invalidMessage={this.getError('secondLastName')}
          defaultValue={defaultValues.secondLastName}
        />
        <Input
          className='margin-bottom-small'
          label='Teléfono *'
          onChange={this.handleChange}
          id='phone'
          maxLength='10'
          placeholder='10 dígitos'
          validate={hasOnlyNumbers}
          invalidMessage={this.getError('phone')}
          defaultValue={defaultValues.phone}
        />
        <OptionSwitch
          className='margin-bottom-small'
          label={
            <p className='color-dark-blue start-xs text-uppercase text-smallest'>
              Género *
            </p>
          }
          onChange={this.handleChange}
          id='gender'
          options={GENDER_OPTIONS}
          optionClassName='color-dark-blue full-width'
          wrap={false}
          invalidMessage={this.getError('gender')}
          defaultValue={defaultValues.gender}
        />
        <Select
          className='margin-bottom-small'
          label='Estado civil *'
          onChange={this.handleChange}
          id='maritalStatus'
          invalidMessage={this.getError('maritalStatus')}
          defaultValue={defaultValues.maritalStatus}
          placeholder='Selecciona'
          options={MARITAL_STATUS_OPTIONS}
        />
        <DateSelector
          className='margin-bottom-small'
          label='Fecha de nacimiento *'
          onChange={this.handleChange}
          id='birthdate'
          invalidMessage={this.getError('birthdate')}
          defaultValue={defaultValues.birthdate}
        />
        <Select
          className='margin-bottom-small'
          label='País de nacimiento *'
          onChange={this.handleChange}
          options={COUNTRIES}
          id='birthPlace'
          invalidMessage={this.getError('birthPlace')}
          defaultValue={defaultValues.birthPlace}
        />
        {birthPlace === LOCAL_COUNTRY ? (
          <Select
            className='margin-bottom-small'
            label='Estado de nacimiento *'
            onChange={this.handleChange}
            id='statePlace'
            options={MEX_STATES}
            placeholder='Selecciona'
            invalidMessage={this.getError('statePlace')}
            defaultValue={defaultValues.statePlace}
          />
        ) : (
          <Input
            className='margin-bottom-small'
            label='Localidad de nacimiento *'
            onChange={this.handleChange}
            id='statePlace'
            placeholder='Localidad de nacimiento'
            invalidMessage={this.getError('statePlace')}
            defaultValue={defaultValues.statePlace}
          />
        )}

        <p className='text-smallest start-xs color-dark-gray margin-bottom'>
          * Campos obligatorios
        </p>
        <Button isLoading={isSubmitting} theme='primary' type='submit'>
          Continuar
        </Button>
      </form>
    )
  }
}

// validate function called before handle submit
const validate = values => {
  const currentDate = new Date()
  // Create an errors object with built with the form form validations
  const errors = {
    firstName: validateInput(values.firstName, [
      validateNullField(genericValidationMessage('tu nombre')),
      lettersOnly
    ]),
    secondName: validateInput(values.secondName, [lettersOnly]),
    lastName: validateInput(values.lastName, [
      validateNullField(genericValidationMessage('tu apellido paterno')),
      lettersOnly
    ]),
    secondLastName: validateInput(values.secondLastName, [
      validateNullField(genericValidationMessage('tu apellido materno')),
      lettersOnly
    ]),
    phone: validateInput(values.phone, [
      validateNullField(genericValidationMessage('tu teléfono')),
      numbersOnly,
      minLength(10, 'Ingresa tu número telefónico a 10 dígitos.')
    ]),
    gender: validateNullSelect(values.gender),
    // Validate if something has been entered to the date selector
    birthdate:
      !values.birthdate || objectIsEmpty(values.birthdate)
        ? genericValidationMessage('tu fecha de nacimiento')
        : validateInput(values.birthdate, [
          validateComposedObject({
            day: [
              validateNullField(
                genericValidationMessage('tu día de nacimiento')
              )
            ],
            month: [
              validateNullField(
                genericValidationMessage('tu mes de nacimiento')
              )
            ],
            year: [
              validateNullField(
                genericValidationMessage('tu año de nacimiento')
              ),
              minLength(4, 'Ingresa tu año de nacimiento a 4 dígitos.')
            ]
          }),
          validateDate('Ingresa una fecha de nacimiento válida.'),
          validateMinDate(
            createDate(
              currentDate.getFullYear() - 100,
              currentDate.getMonth() + 1,
              currentDate.getDate()
            ),
            'Ingresa una fecha de nacimiento válida.'
          ),
          validateMaxDate(
            createDate(
              currentDate.getFullYear() - 18,
              currentDate.getMonth() + 1,
              currentDate.getDate()
            ),
            'Para poder invertir en La Tasa necesitas ser mayor de edad.'
          )
        ]),
    /**
     * Validates a select if local country is selected,
     * otherwise validates a single input
     */
    statePlace:
      values.birthPlace === LOCAL_COUNTRY
        ? validateNullSelect(values.statePlace)
        : validateInput(values.statePlace, [
          validateNullField('tu localidad de nacimiento'),
          lettersOnly
        ]),
    maritalStatus: validateNullSelect(values.maritalStatus),
    birthPlace: validateInput(values.birthPlace, [validateNullSelect])
  }

  // Remove the empty keys and return the errors object
  return cleanObject(errors)
}

const handleSubmit = async (values, { props, setSubmitting }) => {
  setSubmitting(true)
  try {
    await API.Opportunities.Update(getPersonalDataFormRequest(values))
    props.goNext(values)
  } catch (e) {
    console.error(e)
    notifyError('Ocurrió un error, intenta de nuevo más tarde.')
    setSubmitting(false)
  }
}

PersonalDataForm.defaultProps = {
  values: {}
}

PersonalDataForm.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  goNext: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    secondName: PropTypes.string,
    lastName: PropTypes.string,
    secondLastName: PropTypes.string,
    phone: PropTypes.string,
    gender: PropTypes.string,
    maritalStatus: PropTypes.string,
    birthDate: PropTypes.string,
    birthPlace: PropTypes.string,
    statePlace: PropTypes.string
  }).isRequired,
  touched: PropTypes.shape({
    firstName: PropTypes.bool,
    secondName: PropTypes.bool,
    lastName: PropTypes.bool,
    secondLastName: PropTypes.bool,
    phone: PropTypes.bool,
    gender: PropTypes.bool,
    maritalStatus: PropTypes.bool,
    birthDate: PropTypes.bool,
    birthPlace: PropTypes.bool,
    statePlace: PropTypes.bool
  }).isRequired,
  defaultValues: PropTypes.shape({
    firstName: PropTypes.string,
    secondName: PropTypes.string,
    lastName: PropTypes.string,
    secondLastName: PropTypes.string,
    phone: PropTypes.string,
    gender: PropTypes.string,
    maritalStatus: PropTypes.string,
    birthDate: PropTypes.string,
    birthPlace: PropTypes.string,
    statePlace: PropTypes.string
  }).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    firstName: PropTypes.string,
    secondName: PropTypes.string,
    lastName: PropTypes.string,
    secondLastName: PropTypes.string,
    phone: PropTypes.string,
    gender: PropTypes.string,
    maritalStatus: PropTypes.string,
    birthDate: PropTypes.string,
    birthPlace: PropTypes.string,
    statePlace: PropTypes.string
  })
}

export default withFormik({
  validate,
  handleSubmit,
  // Avoid taking props as values
  mapPropsToValues: () => {},
  validateOnBlur: false
})(PersonalDataForm)
