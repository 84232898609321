// Libraries
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Components
import Icon from 'shared/icon'
import styles from './button.module.scss'

// Render a button that accepts some options from props
class Button extends React.PureComponent {
  handleKeyDown = e => {
    const { onClick } = this.props
    e.preventDefault()
    // change the value if the enter key has been pressed
    if (e.keyCode === 13) onClick()
  }

  render () {
    const {
      isLoading,
      icon,
      onClick,
      className,
      children,
      theme,
      id,
      ...props
    } = this.props
    // If isLoading force the icon to loading
    const currentIcon = isLoading ? 'loading' : icon
    const themeStyle = styles[theme]

    // Generate and id object if provided
    const idProps = id ? { id, 'data-testid': id } : {}
    return (
      <button
        {...props}
        {...idProps}
        className={classNames('clickable', styles.button, {
          [themeStyle]: !!themeStyle,
          [className]: !!className
        })}
        onClick={isLoading ? null : onClick}
        onKeyDown={this.handleKeyDown}
      >
        {children}
        {currentIcon && <Icon icon={currentIcon} />}
      </button>
    )
  }
}

Button.defaultProps = {
  className: '',
  disabled: false,
  icon: '',
  isLoading: false,
  onClick: () => {},
  style: {},
  theme: '',
  type: 'button',
  id: ''
}

Button.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string
}

export default Button
