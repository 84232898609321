// Libraries
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Components
import styles from './beneficiaryPercentageSelector.module.scss'
import Input from 'shared/input'
import { hasOnlyNumbers } from 'utils/stringValidations'
import Icon from 'shared/icon'

class beneficiaryPercentageSelector extends PureComponent {
  handleOnDelete = () => {
    const { onDelete, id } = this.props
    onDelete(id)
  }

  // Sets the value in the formik form
  handleChange = (value, id) => {
    const { onChange, beneficiaryNumber } = this.props
    onChange(beneficiaryNumber, value, id)
  }

  getError = field => {
    const { errors, touched, submitCount } = this.props
    // If submitted or field touched, return field error
    return submitCount > 0 || touched[field] ? errors[field] : ''
  }

  render () {
    const {
      name,
      lastName,
      beneficiaryNumber,
      isInvalid,
      defaultValues
    } = this.props
    const error = this.getError('percentage')
    const hasError = isInvalid || !!error
    return (
      <React.Fragment>
        <div
          className={classNames(styles.percentage_selector, {
            [styles.invalid]: hasError
          })}
        >
          <div className='row'>
            <div className='col-xs-6 margin-auto start-xs'>
              <p className='color-dark-blue'>
                {`Beneficiario ${beneficiaryNumber}`}
              </p>
              <p className='text-smallest text-break-all'>{`${name} ${lastName}`}</p>
            </div>
            <div className='col-xs-4 margin-auto'>
              <Input
                maxLength='3'
                validate={hasOnlyNumbers}
                id={`percentage-${beneficiaryNumber}`}
                name='percentage'
                onChange={this.handleChange}
                isInvalid={hasError}
                indicator='%'
                defaultValue={defaultValues.percentage}
              />
            </div>
            <div className='col-xs-2 margin-auto'>
              <Icon
                data-testid={`percentage-delete-${beneficiaryNumber}`}
                onClick={this.handleOnDelete}
                icon='delete'
                size='small'
                className='clickable color-sky-blue'
              />
            </div>
          </div>
        </div>
        <p className='color-error text-small start-xs margin-bottom-small'>
          {error}
        </p>
      </React.Fragment>
    )
  }
}

beneficiaryPercentageSelector.defaultProps = {
  name: '',
  lastName: ''
}

beneficiaryPercentageSelector.propTypes = {
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  lastName: PropTypes.string,
  beneficiaryNumber: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  touched: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  errors: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValues: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types
}

export default beneficiaryPercentageSelector
