// Libraries
import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Components
import API from 'api'
import { notifyError } from 'utils/notifications'
import { transforAPIToDefaultData, getAPIProgress } from './utils'
import { INITIAL_STEP } from 'routes/privateRoutes'

// The actual context
const OpportunityContext = React.createContext({})

const defaultState = {
  progress: INITIAL_STEP,
  isLoading: true,
  savedData: {}
}

class OpportunityProvider extends Component {
  state = {
    ...defaultState
  }

  componentDidMount () {
    const { isSignedIn } = this.props
    if (isSignedIn) {
      this.loadOpportunityData()
    }
  }

  componentDidUpdate (prevProps) {
    const { isSignedIn } = this.props
    // If the opportunity has signed in, load the saved data
    if (!prevProps.isSignedIn && isSignedIn) {
      this.loadOpportunityData()
    }
  }

  setOpportunityData = progress => {
    this.setState({ ...progress })
  }

  loadOpportunityData = async () => {
    const { logout } = this.props
    try {
      this.setState({ isLoading: true })
      const {
        data: {
          data: { opportunity }
        }
      } = await API.Opportunities.Show()
      this.setState({
        savedData: transforAPIToDefaultData(opportunity),
        progress: getAPIProgress(opportunity)
      })
    } catch (error) {
      console.error(error)
      notifyError('Ocurrió un error al cargar la información del usuario.')
      logout()
    } finally {
      this.setState({ isLoading: false })
    }
  }

  render () {
    const { savedData, isLoading, progress } = this.state
    const { children } = this.props
    return (
      <OpportunityContext.Provider
        value={{
          savedData,
          isLoading,
          progress,
          setOpportunityData: this.setOpportunityData
        }}
      >
        {children}
      </OpportunityContext.Provider>
    )
  }
}

OpportunityProvider.defaultProps = {
  isSignedIn: false,
  logout: () => {}
}

OpportunityProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isSignedIn: PropTypes.bool,
  logout: PropTypes.func
}

const OpportunityConsumer = OpportunityContext.Consumer

export { OpportunityProvider, OpportunityConsumer }
