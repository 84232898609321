// Libraries
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Footer from 'shared/footer'
import Topbar from 'shared/topbar'
import styles from './publicPage.module.scss'

const PublicPage = ({ children, pageHeader }) => (
  <div id='public-page'>
    <Topbar />
    {pageHeader}
    <div className={styles.public_content}>
      <div className='container page-content margin-bottom center-xs'>
        {children}
      </div>
    </div>
    <Footer />
  </div>
)

PublicPage.defaultProps = {
  pageHeader: undefined
}

PublicPage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  pageHeader: PropTypes.node
}

PublicPage.defaultProps = {
  children: undefined
}

export default PublicPage
