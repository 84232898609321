// Libraries
import accounting from 'accounting-js'
import moment from 'moment'
import { isString } from './stringValidations'

/**
 * @typedef {import('moment').Moment} Moment
 */

export const DATE_FORMAT = 'YYYY-MM-DD'

/**
 * @typedef {object} DateObject
 * @property {string} year
 * @property {string} month
 * @property {string} day
 */

/**
 * Converts a number into a money string
 * @param {number} number
 * @returns {string} the converted number
 */
export const formatMoney = number => {
  if (isNaN(number)) {
    throw new Error('A numeric value must be provided')
  }
  return accounting.formatMoney(number)
}

export const unformatMoney = money => accounting.unformat(money)

/**
 * Returns the provided object without the keys with falsy value
 * @param {object} object object to be cleaned
 */
export const cleanObject = object =>
  Object.keys(object).reduce((acc, key) => {
    return object[key]
      ? {
        ...acc,
        [key]: object[key]
      }
      : acc
  }, {})

/**
 * Returns if all the keys of the provided object are empty
 * @param {object} object object to be validated
 */
export const objectIsEmpty = object =>
  Object.keys(cleanObject(object)).length === 0

/**
 * Returns a date instance (moment)
 * @param {number | string} year
 * @param {number | string} month
 * @param {number | string} day
 */
export const createDate = (year, month, day) =>
  moment(`${year}-${month}-${day}`, DATE_FORMAT)

/**
 * Returns a date object turned into a string with the standard format
 * @param {Moment} date
 */
export const DateToString = date => date.format(DATE_FORMAT)

/**
 * Converts a date string into a object
 * @param {string} dateString
 * @throws {Error} if the provided string is not a valid date
 * @returns {DateObject} the resulting date object
 */
export const DateStringToObject = dateString => {
  const date = moment(dateString, DATE_FORMAT)
  if (!date.isValid()) {
    throw new Error('The provided date string is not a valid date')
  }

  return {
    year: date.year().toString(),
    month: (date.month() + 1).toString(),
    day: date.date().toString()
  }
}

/**
 * Return the error generated by a failed request
 * if no error found, return undefined
 * @param {object} response
 */
export const getResponseError = ({ response } = {}) => {
  if (response && response.data && response.data.errors) {
    return response.data.errors.detail
  }
}
/**
 * @param {number} elements numbers of elements in the array
 */
export const range = elements => {
  if (isNaN(elements)) {
    throw new Error('You must provide a valid numeric value')
  }
  return Array.from(new Array(elements), (_, index) => index)
}

/**
 * Converts a file object to a base 64 string
 * @param {object} file
 */
export const fileToBase64 = file => {
  const convertionPromise = new Promise(resolve => {
    const reader = new window.FileReader()
    reader.onloadend =
      /**
       * @param {{ currentTarget: { result: string; error: any; }; }} event
       */
      event => {
        resolve({
          fileName: file.name,
          base64: event.currentTarget.result.split(',')[1],
          error: event.currentTarget.error,
          size: file.size,
          contentType: file.type
        })
      }
    reader.readAsDataURL(file)
  })

  return convertionPromise
}

/**
 * Remove accents from a provided string
 * @param {string} str
 */
export const stripAccents = str => {
  if (!isString(str)) throw new Error('The provided value is not a string')
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
 * Sort a collection of object by a provided key
 * @param {Object[]} list
 * @param {string} key Object propery to sort
 * @param {string} [order] If desc, the sort will be descendant
 * @returns {Object[]} The sorted collection
 */
export const sortByKey = (list, key, order = '') => {
  const sortedList = list.sort((a, b) => a[key].localeCompare(b[key]))
  return order === 'desc' ? sortedList.reverse() : sortedList
}

/**
 * Replaces whitespaces with underscores, removes uppercases and accents
 * @param {string}  str
 * @returns {string} The converted string
 */
export const toSnakeCase = str =>
  stripAccents(str)
    .toLowerCase()
    .split(' ')
    .join('_')
