import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  LabelList,
  ResponsiveContainer
} from 'recharts'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

const formatTick = value => `$${Number(value / 1000)} K.`

const getGradientey = key => `${key}-gradient-color`

class PerformanceSimulatorGraph extends Component {
  render () {
    const { data } = this.props

    // Data is an array, but we care only the first element
    const investmentData = data[0]

    // List of elements to be rendered, ex cetes, dolar, etc
    const dataKeys = Object.keys(investmentData)

    return (
      <ResponsiveContainer width='100%' height={400}>
        <BarChart
          data={data}
          barGap='10%'
          margin={{ top: 50, right: 0, left: 0, bottom: 10 }}
        >
          <defs>
            {// Generate the gradients according with the configurations
              dataKeys.map(key => (
                <linearGradient
                  key={getGradientey(key)}
                  id={getGradientey(key)}
                  x1='0'
                  y1='0'
                  x2='1'
                  y2='1'
                >
                  <stop
                    offset='0%'
                    stopColor={investmentData[key].gradientColor1}
                  />
                  <stop
                    offset='100%'
                    stopColor={investmentData[key].gradientColor2}
                  />
                </linearGradient>
              ))}
          </defs>
          <CartesianGrid axisLine={false} vertical={false} />
          <XAxis tickLine={false} axisLine={false} width={20} dataKey='name' />
          <YAxis
            tickCount={9}
            tickFormatter={formatTick}
            axisLine={false}
            tick={{ fontSize: '.7rem' }}
          />
          {// render the bar according with the provided settings
            dataKeys.map(key => (
              <Bar
                key={`bar-${key}`}
                dataKey={`${key}.finalAmount`}
                fill={`url(#${getGradientey(key)})`}
              >
                <LabelList
                  dataKey={`${key}.name`}
                  position='bottom'
                  fill={investmentData[key].labelColor}
                />
                <LabelList
                  dataKey={`${key}.moneyValue`}
                  position='top'
                  fontSize={11}
                  fill={investmentData[key].labelColor}
                />
              </Bar>
            ))}
        </BarChart>
      </ResponsiveContainer>
    )
  }
}

const dataElementPropTypes = PropTypes.shape({
  finalAmount: PropTypes.number,
  gradientColor1: PropTypes.string,
  gradientColor2: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string,
  moneyValue: PropTypes.string,
  name: PropTypes.string,
  rate: PropTypes.string,
  totalEarning: PropTypes.number,
  totalInvestment: PropTypes.number
}).isRequired

PerformanceSimulatorGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      cetes: dataElementPropTypes,
      dolar: dataElementPropTypes,
      ipc: dataElementPropTypes,
      laTasa: dataElementPropTypes
    })
  ).isRequired
}

export default PerformanceSimulatorGraph
